<template>
     <div class="container" ref="container">
        <div class="block-header-watchlist" style="margin-bottom: 0px;">
            <div>Watchlist</div>

            <div class="tagManager-container" ref="tagManager_container"></div>

            <div style="clear: both;"></div>
        </div>

        <div class="header">
            <div>
                <table class="header-table">
                    <tr>
                        <td class="check">
                            <input type="checkbox" v-model="checkedAll" @change="checkAll"/>
                        </td>
                        <td class="name">Name</td>
                        <td class="tags">Tags</td>
                        <td class="actions">Actions</td>
                    </tr>
                </table>
            </div>
        </div>

        <div v-if="loggedIn" class="content" ref="content">
        </div>

        <div v-else class="content">
            <div class="wl-msg">Please sign in first to use this tool.</div>
        </div>

        <div class="bottomStatusBar" v-show="footerEnabled">
            <div class="bottomStatusWrapper">
                <div class="bottomStatusContent">
                    <div v-show="checkedStep == 0">
                        <input type="button" value="Open at once" class="button-green" @click="beforeChecked('open')"/>
                        <input type="button" value="Delete at once" class="button-red" @click="beforeChecked('delete')" />
                    </div>
                    <div v-show="checkedStep == 1">
                        <div style="text-align: center">
                            <div style="display: inline-block">
                            Opening so many charts may overwhelm your browser.<br> Do you want to continue?
                            </div>
                            <div style="display: inline-block; vertical-align: top; margin-left: 10px;">
                            <input type="button" value="Yes" class="button-green" @click="openChecked"/>
                            <input type="button" value="No" class="button-red" @click="checkedStep = 0" />
                            </div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <div v-show="checkedStep == 2">
                        Do you really want to delete all those charts? 
                        <input type="button" value="Yes" class="button-green" @click="deleteChecked"/>
                        <input type="button" value="No" class="button-red" @click="checkedStep = 0" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    import Watchlist2Row from './Watchlist2Row.vue'
    var Watchlist2RowClass = Vue.extend(Watchlist2Row)
    
    import Watchlist2Placeholder from './Watchlist2Placeholder.vue'
    var Watchlist2PlaceholderClass = Vue.extend(Watchlist2Placeholder)

    import TagManager from './TagManager.vue'
    var TagManagerClass = Vue.extend(TagManager)

    import {dSource, DEBUG} from "./vars.js";
    import axios from 'axios';

    export default {
        name: 'Watchlist2',
        posts: [],
        components: {},
        props: {
            user: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                dSource: dSource,

                // vsechny radky
                rows: {},

                // vsechny tady
                tags: {},

                placeholder: null,

                // ukazatel prave prohihajiciho pohybus radkou
                moving: false,

                // pohybujici se element radky
                movingElement: null,

                // id pohybujici se radky
                movingId: null,

                // zachovana pozice z predchazejiciho eventu
                // kvuli urceni smeru pohybu --> insertBefore vs. "insertAfrer"
                y_old: null,

                // data 'puvodnich' radek
                dataRows: null,

                checkedAll: false,
                checkedList: [],
                checkedStep: 0,

                // seznam id aktualne vyrendrovanych radek
                renderedList: [],

                // tagManager
                tagManager: null,

                unassigned: true,

                changed: false,
                offset: 0
            }
        },
        computed: {
            footerEnabled() {
                return this.checkedList.length > 0
            },

            addEnabled() {
                console.log(this.user)

                return true
            },

            /**
              * Ukazatel stavu prihlaseni uzivatele
              */
            loggedIn() {
                return this.user.id > 0
            },
        },
        methods: {
            /**
              * Nastaveni dat watchlist z venci
              */
            setData(data) {
                // definice tagu
                this.tags = data.tags

                // instance placeholder
                // blok zobrazeny behem presouvani radky
                this.createPlaceholder()

                // instance tagManager (operace add, remove, edit, atd.)
                this.unassigned = data.unassigned
                this.createTagManager(this.tags)

                // vytvorit komponenty jednotlivych radek
                this.destroyRows()
                this.dataRows = data.rows
                this.createRows(this.dataRows)

                // vykreslit (a filtrovat) radky
                this.renderRows()
            },

            /**
              * Serazeni radek podle poradi v db
              * urcene klicem 'y'
              */
            sortRows(rowsIn) {
                var rows = []

                for (var i in rowsIn) {
                    for (var j in rowsIn) {
                        if (rowsIn[j].y == i) {
                            rows.push(rowsIn[j])
                        }
                    }
                }

                return rows
            },

            sortedRowKeys(rows) {
                var ys = []
                for (var key in rows) {
                    ys.push(rows[key].y)
                }

                ys = ys.sort(function(a, b) {return a-b})

                var sortedKeys = []
                for (var i in ys) {
                    var y = ys[i]
                    for (var key in rows) {
                        if (rows[key].y == y) {
                            sortedKeys.push(key)
                        }
                    }
                }

                return sortedKeys
            },

            /**
              * Vytvoreni jednotlivych radek wathclistu
              */
            createRows(rows){
                // vytvorit radky
                for (var i in rows) {
                    var rowId = rows[i].id

                    // instance radky
                    this.rows[rowId] = new Watchlist2RowClass({
                        propsData: {
                            id: rowId,
                            link: rows[i].link,
                            name: rows[i].name, 
                        }
                    })

                    // eventy pohyhu
                    this.rows[rowId].$on("moveStartMouse", this.moveStartMouse)
                    this.rows[rowId].$on("moveStartTouch", this.moveStartTouch)
                    this.rows[rowId].$on("moveEndMouse", this.moveEndMouse)
                    this.rows[rowId].$on("moveEndTouch", this.moveEndTouch)
                    this.rows[rowId].$on("move", this.move)

                    // eventy operaci
                    this.rows[rowId].$on("chart", this.chart),
                    this.rows[rowId].$on("edited", this.edited),
                    this.rows[rowId].$on("deleted", this.deleted),
                    this.rows[rowId].$on("checked", this.checked),
                    //this.rows[rowId].$on("tagDeleted", this.reload)

                    // pripojit
                    this.rows[rowId].$mount()

                    // aktivni tagy
                    var tagIds = []
                    for (var j in rows[i].tags) {
                        // check existence tagu
                        if (this.tags[rows[i].tags[j]] === undefined) {
                            continue
                        }

                        tagIds.push(rows[i].tags[j])
                    }
                    
                    this.rows[rowId].setTags(this.tags, tagIds)
                    this.rows[rowId].setY(rows[i].y)
                }
                this.offset = 0
            },

            /**
              * Vykresleni radek
              * zaroven podle aktualniho stavu filtrace
              */
            renderRows() {
                this.renderedList = []
                var sortedKeys = this.sortedRowKeys(this.rows)

                for (var i in sortedKeys) {
                    var rowId = sortedKeys[i]

                    // obsahuje alespon jeden povoleny tag
                    var enabled = false
                    for (var i in this.rows[rowId].tagIds) {
                        var tagId = this.rows[rowId].tagIds[i]
                        enabled |= this.tags[tagId].enabled
                    }

                    // unassigned
                    enabled |= (this.rows[rowId].tagIds.length == 0 && this.tagManager.enabledUnassigned)

                    // vlozit / obebrat element stranky
                    try {
                        if (enabled) {
                            this.$refs.content.appendChild(this.rows[rowId].$el)
                            this.renderedList.push(rowId)
                        } else {
                            this.$refs.content.removeChild(this.rows[rowId].$el)
                        }
                    } catch(e) {}
                }
            },

            /**
              * Vytvoreni instance placeholderu
              */
            createPlaceholder() {
                if (this.placeholder !== null) {return}
                // vytvorit placeholder
                // zobrazovani misto radky behem pohybu
                this.placeholder = new Watchlist2PlaceholderClass()
                this.placeholder.$mount()
            },

            createTagManager() {
                if (this.tagManager !== null) {
                    this.tagManager.$el.parentNode.removeChild(this.tagManager.$el)
                    this.tagManager.$destroy()
                    this.tagManager = null
                }

                this.tagManager = new TagManagerClass({
                    propsData: {
                        tags: this.tags
                    }
                })

                this.tagManager.$on("deleted", this.tagDeleted)
                this.tagManager.$on("edited", this.tagEdited)
                this.tagManager.$on("added", this.tagAdded)
                this.tagManager.$on("filtered", this.filtered)

                this.tagManager.$mount()
                this.tagManager.setTags(this.tags)
                this.tagManager.setUnassigned(this.unassigned)

                this.$refs.tagManager_container.appendChild(this.tagManager.$el)

            },
 
            /**
              * Smazani vsech radek
              */
            destroyRows() {
                for (var key in this.rows) {
                    this.destroyRow(key)
                }
                this.rows = {}
            },

            /**
              * Smazani jednotlive radky
              */
            destroyRow(rowId) {
                this.rows[rowId].$destroy()
                if (this.rows[rowId].$el.parentNode !== null) {
                    this.rows[rowId].$el.parentNode.removeChild(this.rows[rowId].$el)
                }
                //this.rows[rowId] = undefined

                var tmp = {}
                for (var key in this.rows) {
                    if (key == rowId) {continue}
                    tmp[key] = this.rows[key]
                }
                this.rows = tmp
            },

            countRows() {
                var n = 0
                for (var key in this.rows) {
                    n += this.rows[key] !== undefined && this.rows[key] !== null ? 1 : 0
                }
                return n
            },

            /**
              * Znovunacteni watchlistu
              */
            reload() {
                this.moving = false
                this.movingElement = null
                this.y_old = null
                this.destroyRows()
                this.createRows(this.dataRows)
            },
 
            /**
              * Zacatek hybani s radkou
              */ 
            moveStart(rowId) {
                if (this.moving) {return}

                // id pohybujici se radky
                this.movingId = rowId

                // element presouvaneho radku
                this.movingElement = this.rows[rowId].$el

                // vlozit placeholder
                this.placeholder.setName(this.rows[rowId].name)
                this.$refs.content.insertBefore(this.placeholder.$el, this.movingElement)

                // odpojit presouvany radek
                this.movingElement.parentNode.removeChild(this.movingElement)

                // v pohybu!
                this.moving = true
            },
            moveStartTouch(rowId) { // spusteni na dotyk
                this.moveStart(rowId)
            },
            moveStartMouse(rowId) { // spusteni na mys
                document.addEventListener("mouseup", this.moveEnd)
                this.moveStart(rowId)
            },

            /**
              * Ukonceni hybani s radkou
              */
            moveEnd() {
                document.removeEventListener("mouseup", this.moveEnd)
                if (this.movingElement !== null) {
                    // y radce podle posledni pozice placeholderu
                    this.rows[this.movingElement.id].setY(this.getY(this.placeholder.$el))

                    // presouvany radek pred placeholder
                    this.$refs.content.insertBefore(this.movingElement, this.placeholder.$el)

                    // zrusit placeholder
                    this.placeholder.$el.parentNode.removeChild(this.placeholder.$el)
                }

                // neni v pohybu!
                this.moving = false
                this.movingElement = null

                this.moved(this.movingId)
            },
            moveEndMouse() {
                this.moveEnd()
            },
            moveEndTouch() {
                this.moveEnd()
            },

            /**
              * Vypocet globalni polohy radku podle lokanich parametru
              * s nalezenim nejblizsi globalni radky pred i za
              */
            getY(element) {
                var next = element.nextSibling
                var prev = element.previousSibling
                var y = 0

                if (prev !== null) {
                    var dy = this.distNext(prev.id)
                    console.log(dy)
                    return this.rows[prev.id].y + ((dy !== null) ? dy / 2 : 1000)
                }

                if (next !== null) {
                    var dy = this.distPrev(next.id)
                    return this.rows[next.id].y + ((dy !== null) ? dy / 2 : -1000)
                }

                return 0
            },
            distPrev(rowId) {
                var y = this.rows[rowId].y
                var dy = null
                for (var rId in this.rows) {
                    var tmp = this.rows[rId].y-y

                    if (rId == rowId) {continue}
                    if (tmp >= 0) {continue}

                    if (dy === null) {
                        dy = tmp
                        continue
                    }

                    dy = (tmp > dy) ? tmp : dy
                }
                return dy
            },
            distNext(rowId) {
                var y = this.rows[rowId].y
                var dy = null
                for (var rId in this.rows) {
                    var tmp = this.rows[rId].y-y

                    if (rId == rowId) {continue}
                    if (tmp <= 0) {continue}

                    if (dy === null) {
                        dy = tmp
                        continue
                    }

                    dy = (tmp < dy) ? tmp : dy
                }
                return dy
            },

            /**
              * Nalezeni nejvyssi hodnoty y (posledni radek)
              */
            getLastY() {
                var y = -1
                for (var rowId in this.rows) {
                    y = (y < this.rows[rowId].y) ? this.rows[rowId].y : y
                }
                return y
            },

            /**
              * Operace behem pohybu radky
              */
            move(e) {
                if (!this.moving) {return}

                var target = this.getTargetElement(e) || e.target;

                if (target === null) {
                    console.log("Target is still null, event:", e);
                    return;
                }

                // smer pohybu
                var up = this.isUp(e)

                // pouze pokud je nad radkou watchlistu
                // nikoliv watchlist_placeholder nebo cokoliv mimo obhlast
                if(!target.classList.value.includes("watchlist_row")) {return}

                // zrusit placeholder
                this.placeholder.$el.parentNode.removeChild(this.placeholder.$el)

                // vlozit placeholder
                // pred nebo za podle smeru pohybu
                this.$refs.content.insertBefore(this.placeholder.$el, (up ? target : target.nextSibling))
            },

            /**
              * Cilovy element
              * kvuli dotykovym operacim moznost podle pozice na obrazovce
              */
            getTargetElement(e) {
                if (e.toElement !== undefined && e.toElement !== null) {
                    return e.toElement;
                }
                if (e.touches) {
                    var x = e.touches[0].pageX;
                    var y = e.touches[0].pageY;
                    var element = document.elementFromPoint(x, y);
                    return element;
                }
                // Handle the case where e.touches is undefined
                return null;
            },

            /**
              * Indikace smeru pohybu podle rozdilu pozic po sobe jsoucich eventu
              */
            isUp(e) {
                // pozice
                var y = (e.clientY !== undefined) ? e.clientY : e.targetTouches[0].clientY

                // nahoru?
                var up = this.y_old >= y

                // pro priste
                this.y_old = y

                return up
            },

            /**
              * Prechod do charts
              */
            chart(ids) {
                var robj = {
                    user: this.user,
                    rkeys: ["ux"],
                    chain: [
                        {action: "watchlist2", i:0, dkey:"ux", request:{method: "linked", user:this.user, ids:ids}}
                    ]
                }

                var self = this
                axios.post(this.dSource, {robj: robj}).then(payload => function(data) {
                    self.$emit("chart", data)
                }(payload.data.data.ux))
            },

            /**
              * Ulozeni stavu watchlistu po editaci radky
              */
            edited(data) {
                var robj = {
                    user: this.user,
                    rkeys: ["watchlist2"],
                    chain: [
                        {action: "watchlist2", i:0, dkey: "watchlist2", request: {method: "edited", user:this.user, data:data}}
                    ]
                }
                axios.post(this.dSource, {robj: robj}).then(payload => function(data) {}(payload.data))
            },

            chartsEdited(data) {
                var robj = {
                    user: this.user,
                    rkeys: ["watchlist2"],
                    chain: [
                        {action: "watchlist2", i:0, dkey: "watchlist2", request: {method: "chartsEdited", user:this.user, data:data}}
                    ]
                }

                axios.post(this.dSource, {robj: robj}).then(payload => function(data) {}(payload.data))
            },

            chartsAdded(data) {
                var robj = {
                    user: this.user,
                    rkeys: ["watchlist2"],
                    chain: [
                        {action: "watchlist2", i:0, dkey: "watchlist2", request: {method: "chartsAdded", user:this.user, data:data}}
                    ]
                }

                axios.post(this.dSource, {robj: robj}).then(payload => function(data) {}(payload.data))
            },

            /**
              * Ulozeni stavu watchlist po presunu radky
              */
            moved(rowId) {
                var robj = {
                    user: this.user,
                    rkeys: ["watchlist2"],
                    chain: [
                        {action: "watchlist2", i:0, dkey: "watchlist2", request: {method: "ordered", user:this.user, data:this.getOrder()}}
                    ]
                }
                axios.post(this.dSource, {robj: robj}).then(payload => function(data) {}(payload.data))
            },

            /**
              * Vraci array reprezentujici poradi radek 
              */
            getOrder() {
                var order = {}
                var sortedKeys = this.sortedRowKeys(this.rows)

                for (var i in sortedKeys) {
                    order[sortedKeys[i]] = this.rows[sortedKeys[i]].y
                }

                return order

                /*    
                // nejdriv ty ktere jsou aktualne vykreslene
                var children = this.$refs.content.childNodes
                for (var i in children) {
                    if (children[i].classList === undefined || !children[i].classList.value.includes("watchlist_row")) {continue}
                    order.push(children[i].id)
                }

                // za vykreslne pridat vykreslene tak jak jsou
                // jelikoz je to jedno
                for (var rowId in this.rows) {
                    if (this.renderedList.includes(rowId)) {continue}
                    order.push(rowId)
                }

                return order */
            },

            /**
              * Smazani radek podle seznamu id z watchlistu
              */
            deleted(rowIds) {
                for (var i in rowIds) {
                    this.destroyRow(rowIds[i])
                }

                var robj = {
                    user: this.user,
                    rkeys: ["watchlist2"],
                    chain: [
                        {action: "watchlist2", i:0, dkey: "watchlist2", request: {method: "deleted", user:this.user, data:{ids: rowIds}}}
                    ]
                }
                axios.post(this.dSource, {robj: robj}).then(payload => function(data) {}(payload.data))
            },

            /**
              * Nastaveni potvrzeni a nasledne hromadne akce
              */
            beforeChecked(action) {
                // otevreni pod limitem
                if (action == "open" && this.checkedList.length < 21) {
                    this.openChecked()
                    return
                }

                // zobrazeni vyzvy
                this.checkedStep = action == "open" ? 1 : 2
            },

            /**
              * Otevrit vsechny zaskrtnute
              */
            openChecked() {
                this.checkedStep = 0
                var ids = []
                for (var i in this.checkedList) {
                    ids.push(this.checkedList[i])
                }
                this.chart(ids)
                this.uncheckAll()
            },

            /**
              * Smazat vsechny zaskrtnute
              */
            deleteChecked() {
                this.checkedStep = 0
                this.deleted(this.checkedList)
                this.uncheckAll()
            },

            /**
              * Zaskrtnout vsechny (mysleno vsechny vyrendrovane)
              */
            checkAll() {
                this.checkedList = []
                for (var rowId in this.rows) {
                    if (!this.renderedList.includes(rowId)) {continue} // zahrnuje pouze vyrendrovane
                    this.rows[rowId].setCheck(this.checkedAll)
                }
                this.checked()
            },

            /**
              * Zrusit zaskrtnuti u vsech
              */
            uncheckAll() {
                this.checkedAll = false
                this.checkAll()
            },

            /**
              * Sestaveni listu monentalne zaskrtnutych
              */
            checked() {
                this.checkedStep = 0
                var tmp = []
                for (var rowId in this.rows) {
                    if (this.rows[rowId].checked) {
                        tmp.push(rowId)
                    }
                }
                this.checkedList = tmp
            },

            /**
              * Smazani tagu podle ID
              */
            tagDeleted(tagId) {
                for (var rowId in this.rows) {
                    this.rows[rowId].removeTag(tagId)
                }

                var robj = {
                    user: this.user,
                    rkeys: ["watchlist2"],
                    chain: [
                        {action: "watchlist2", i:0, dkey: "watchlist2", request: {method: "tagDeleted", user:this.user, data:{id: tagId}}}
                    ]
                }

                var self = this
                axios.post(this.dSource, {robj: robj}).then(payload => function(data) {
                    self.tagManager.filter()
                }(payload.data))
            },

            /**
              * Editace tagu na serveru
              */
            tagEdited(data) {
                this.tags[data.id].name = data.name
                this.tags[data.id].color = data.color

                var robj = {
                    user: this.user,
                    rkeys: ["watchlist2"],
                    chain: [
                        {action: "watchlist2", i:0, dkey: "watchlist2", request: {method: "tagEdited", user:this.user, data:data}}
                    ]
                }
                axios.post(this.dSource, {robj: robj}).then(payload => function(data) {}(payload.data))
            },

            /**
              * Pridani noveho tagu na server
              */
            tagAdded(data) {
                var self = this
                var robj = {
                    user: this.user,
                    rkeys: ["watchlist2"],
                    chain: [
                        {action: "watchlist2", i:0, dkey: "watchlist2", request: {method: "tagAdded", user:this.user, data:data}}
                    ]
                }

                axios.post(this.dSource, {robj: robj}).then(payload => function(tagId) {
                    self.tags[tagId] = {
                        enabled: true,
                        name: data.name,
                        color: data.color
                    }
                    self.tagManager.setTags(self.tags)
                }(payload.data.data.watchlist2.tagId))
            },

            filtered(enabled)  {
                // nastaveni stavu tagum
                for (var tagId in enabled) {
                    if (this.tags[tagId] === undefined) {continue}
                    this.tags[tagId].enabled = enabled[tagId]
                }

                // prekresleni viditelnych radek
                this.renderRows()

                var self = this
                setTimeout(function() {
                // preneseni stavu na server
                var robj = {
                    user: self.user,
                    rkeys: ["watchlist2"],
                    chain: [
                        {action: "watchlist2", i:0, dkey: "watchlist2", request: {method: "filtered", user:self.user, data:enabled}},
                        {action: "watchlist2", i:0, dkey: "watchlist2", request: {method: "ordered", user:self.user, data:self.getOrder()}},
                    ]
                }
                axios.post(self.dSource, {robj: robj}).then(payload => function(data) {}(payload.data))
                }, 25) 
            },

            getTags() {
                return this.tags
            }
        },
    }
</script>

<style scoped>
    div.container {
        width: 100%;
    }
    
    div.content {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    div.header {
        padding: 0 10px 0 10px;
    }

    div.content {
        overscroll-behavior: none;
        margin-bottom: 40px;
    }

    div.block-header-watchlist {
        font-size: 18px;
        background-color: #d9f2f9;
        font-weight: 700;
        color: #192b4f;
    }

    div.block-header-watchlist > div {
        display: inline-block;
        margin: 15px;
    }

    div.tagManager-container {
        float: right;
    }

    div.header > div {
        border-bottom: 2px solid #cdcdcd;
        padding: 5px;
    }


    table.header-table {
        width: 100%;
        border-collapse: collapse;
        font-weight: 700;
    }

    table.header-table > tr > td {
        padding: 5px;
    }

    td.check {
        width: 20px;
    }

    td.name {
        width: 25%;
    }

    td.tags {
        text-align: center;
    }

    td.actions {
        text-align: center;
        width: 100px;
    }

    div.footer > div {
        font-weight: 700;
    }

    div.footer > div > input {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    div.wl-msg {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        vertical-align: middle;
        line-height: 50px;
        color: rgba(0, 0, 0, 0.25);
        margin-top: 260px;
    }

    div.bottomStatusBar {
        display: inline-block;
        max-width: 1000px; 
        margin: auto;
    }
</style>
