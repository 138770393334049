<template>
    <div class="container">

        <div>
            <table class="form-table">
                <tr>
                    <td class="td-error">
                    </td>
                </tr>

                <tr>
                    <td class="td-input" colspan=2 :class="{'error': !email.valid}">
                        <input v-if="lang == 'cs'" placeholder="Váš e-mail" type="text" v-model="email.value" style="width: calc(100% - 34px)"/>
                        <input v-else placeholder="Your e-mail address" type="text" v-model="email.value" style="width: calc(100% - 34px)"/>
                    </td>
                </tr>

                <tr>
                    <td class="td-label">
                    </td>
                </tr>
                <tr>
                    <td class="td-input" colspan=2 :class="{'error': !msg.valid}">
                        <textarea v-if="lang == 'cs'" placeholder="Vaše zpráva" rows="10" cols="50" v-model="msg.value" style="resize: none; width: calc(100% - 34px);"></textarea>
                        <textarea v-else placeholder="Your message" rows="10" cols="50" v-model="msg.value" style="resize: none; width: calc(100% - 34px);"></textarea>
                    </td>
                </tr>


                <tr>
                    <td class="td-submit">
                        <input v-if="lang == 'cs'" type="button" class="button-round-green" value="ODESLAT ZPRÁVU" @click="send" />
                        <input v-else type="button" class="button-round-green" value="SEND MESSAGE" @click="send" />
                    </td>
                </tr>

            </table>

            <div v-show="!msg.valid || !email.valid"  class="error-container">
                <div v-if="lang == 'cs'">Chyba při odeslání e-mailu. Zkuste to prosím znovu.</div>
                <div v-else>There wat an error trying to send your message. Please try again later.</div>
            </div>

            <div v-show="done && msg.valid && email.valid"  class="thank-container">
                <div v-if="lang == 'cs'">Děkujeme! Brzy se ozveme.</div>
                <div v-else>Thank you! We will be in touch soon.</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {dSource, DEBUG} from "./vars.js";
    import loader from './loader.vue';
    import axios from 'axios';

    export default {
        name: 'ContactForm2',
        props: {
            lang: {
                type: String,
                required: true
            },
        },
        components: {loader},
        mixins: [],
        data() {
            return {
                dSource: dSource,
                DEBUG: DEBUG,

                email: {
                    value: null,
                    valid: true,
                    error: null
                },
                msg: {
                    value: null,
                    valid: true,
                    error: null
                },

                done: false,
                
                t: 0,
            }
        },
        mounted() {
        }, 
        methods: {
            init() {
                // email
                this.email.value = this.loggedIn ? this.user.email : null
                this.email.valid = true
                this.email.error = null

                // msg
                this.msg.value = null
                this.msg.valid = true
                this.msg.error = null
            },

            validate() {
                // validace email
                this.email.valid = true
                this.email.valid &= /^.{1,1000}@.{1,1000}\.[a-zA-Z0-9]{1,20}$/.test(this.email.value)
                if (!this.email.valid) {
                    this.email.error = "Incorrect e-mail address"
                }

                // validace msg
                this.msg.valid = true
                this.msg.valid &= this.msg.value !== null
                if (!this.msg.valid) {
                    this.msg.error = "Fill in your message"
                }

                return this.email.valid && this.msg.valid
            },

            send() {
                // validace
                if (!this.validate()) {
                    return
                }

                // 'captcha' timer
                if (new Date().getTime() - this.t < 2000) {
                    this.init()
                    this.t = new Date().getTime()
                    return
                }

                var robj = {
                    targetId: "contact",
                    raw: "None",
                    chain: [
                        {action: "contact", i:0, dkey:"result", request:{user: {}, method:"send", data:JSON.parse(JSON.stringify({email:this.email.value, msg:this.msg.value}))}}
                    ]
                }

                this.init()
                this.done = true
                axios.post(this.dSource, {robj: robj}).then(payload => this.sendCallback(payload.data.data))
            },

            sendCallback(data) {
            }
        },
        mounted() {
            this.t = new Date().getTime()
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
    
    div.container {
        font-family: 'Montserrat', sans-serif !important;
        font-size: 14px;
        background-color: #f6f8f7;
        padding: 10px;
    }

    td > input[type="text"],
    td > textarea {
        font-family: inherit;
        font-size: inherit;
        padding: 11px 16px 12px 16px;
        color: #495057;
        background-color: #ffffff;
        border: 1px solid #ced4da;
    }

    td > input[type="text"]:focus,
    td > textarea:focus {
        outline: none;
        box-shadow: 0 0 3px 2px #80bdff;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    td.td-submit {
        text-align: right;
        padding: 17px 0 0 0;
    }

    div.container table {
        width: 100%;
    }

    div.error-container, 
    div.thank-container {
        font-size: 16px;
        font-family: inherit;
        text-align: center;
        padding: 3px 16px 3px 16px;
        margin: 32px 0 16px 0;
    }

    div.error-container {
        border: 2px solid #dc3232;
    }

    div.thank-container {
        border: 2px solid #46b450;
    }

    div.loader-wrapper {
        position: relative;
        margin: auto;
        top: 70px;
    }

    div.thanks-wrapper {
        position: relative;
        color: #555;
        font-size: 20px;
        text-align: center;
    }

    input[type="button"].button-round-green {
        background-color: #439941;
        color: white;
        box-shadow: none;
        font-size: 11px;
        font-weight: 700;
        padding: 12px 28px 12px 28px;
        border-radius: 30px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
</style>
