   // import AmCharts from 'amcharts3';
// import AmStock from 'amstock3/amcharts/amstock';
// import AmSerial from 'amcharts3/amcharts/serial';

import defaultCategoryAxesSettings from './commonFunctions.js'
import defaultPanelSettings from './commonFunctions.js'
import defaultBalloon from './commonFunctions.js'
import isTouch from './commonFunctions.js'

export default {
    mixins:[defaultPanelSettings, defaultBalloon, isTouch],
    methods: {
        fieldMappingForTS(){
            return [
                    {fromField: "close", toField: "close"},
                    {fromField: "close_5", toField: "close_5"},
                    {fromField: "close_15", toField: "close_15"},
                    {fromField: "close_30", toField: "close_30"},
                    {fromField: "volume", toField: "volume"},
                    {fromField: "date", toField: "date"},
                    {fromField: "oi", toField: "oi"},
                    {fromField: "description", toField: "description"},
                    {fromField: "bullet_size", toField: "bullet_size"},
                    {fromField: "bullet_color", toField: "bullet_color"}
                ]
        },
        termStructure(payload) {
            var backup;
            var dataArray;
            var key;
            var key_in;
            var isHidden = {
                ds2:  true,
                ds3:  true,
                ds4:  true,
                se5:   true,
                se15:   true,
                se30:   true
            }

            var months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]


            backup = payload;
            backup.colors = [ "#0000ff", "#ff3300", "#ff6600", "#ffcc00", "#676767", "#999999", "#c5c5c5", "#bbbbbb", "#cccccc" ];
            // uprava labels
            backup.labels[0] = backup.labels[0];


            // container "setridenych" dat
            dataArray = [];

            // objekt datumu podle timestampu
            var d = new Date(payload.misc.date)

            // 70 dni zpet
            for (var i=0; i<120; i++) {
                // vstupni a vystupni klice
                key = d.toJSON().substring(0, 10)
                key_in = key + "T00:00:00"

                // zahrnuje moznost "kratkeho" i delsiho klice datumu (s casem vs. bez casu)
                var tmp = undefined
                if (payload.data[key_in] != undefined) {
                    var tmp = {date:key, data:payload.data[key_in]}
                } else if (payload.data[key] != undefined) {
                    var tmp = {date:key, data:payload.data[key]}
                }

                if (tmp !== undefined) {
                    // kvuli spravnemu zobrazeni mezery na zacatku
                    tmp.data.splice(0, 0, {date: new Date(d).toISOString()})

                    // k ostatnim datum
                    dataArray.push(tmp) 
                }

                // ubrat o den
                d.setDate(d.getDate()-1)
            }

            // zaokrouhleni na spravny pocet mist
            for (var i in dataArray) {
                for (var j in dataArray[i].data) {
                    //dataArray[i].data[j].close = parseFloat(dataArray[i].data[j].close).toFixed(payload.misc.round_prec)
                    dataArray[i].data[j].close = Math.round(dataArray[i].data[j].close * 10.0**payload.misc.round_prec) / 10.0**payload.misc.round_prec
                }
            }

            var ch =  window.AmCharts.makeChart(payload.targetId, {
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                data: dataArray,
                dataSets: [{
                    title: "today",
                    color: backup.colors[0],
                    fieldMappings: this.fieldMappingForTS(),
                    dataProvider: dataArray[0].data,
                    categoryField: "date"
                },{
                    title: "yesterday",
                    color: backup.colors[4],
                    fieldMappings: this.fieldMappingForTS(),
                    dataProvider: dataArray[1].data,
                    compared: true,
                    categoryField: "date"
                },{
                    title: "1 week ago",
                    color: backup.colors[5],
                    fieldMappings: this.fieldMappingForTS(),
                    dataProvider: dataArray[5].data,
                    compared: true,
                    categoryField: "date"
                },{
                    title: "1 month ago",
                    color: backup.colors[6],
                    fieldMappings: this.fieldMappingForTS(),
                    dataProvider: dataArray[22].data,
                    compared: true,
                    categoryField: "date",
                    hidden: true
                }],
                panels: [{
                    showCategoryAxis: false,
                    percentHeight: 75,
                    stockGraphs: [
                    {
                        id: "g1",
                        title: function(){
                            var tmpDate = new Date(payload.misc.date)
                            return backup.labels[0].split(" ")[0] + " " +
                                   backup.labels[0].split(" ")[1] + " " + 
                                   backup.labels[0].split(" ")[2] + " (" + months[tmpDate.getUTCMonth()] + " " 
                                   + tmpDate.getUTCDate() + ", " + tmpDate.getUTCFullYear() + ")";
                        }(),
                        useDataSetColors: false,
                        bullet : "round",
                        compareGraphBullet: "round",
                        bulletSizeField: "bullet_size",
                        labelColorField: "bullet_color", 
                        compareGraphBulletSize: 10,
                        balloonFunction: function(item) {
                            var tempDate = new Date(item.graph.data[0].category)
                            var days = Math.round((item.category.getTime() - tempDate.getTime())/86400000)
                            return  item.dataContext.description + ": " + item.values.value + ", expires in "+ days +" days "
                        },
                        compareGraphBalloonFunction: function(item) {
                            return  item.graph.legendTextReal + ": " + item.values.value
                        },
                        labelText: "[[close]]",
                        comparable : true,
                        compareField : "close",
                        fontSize: 14,
                        labelOffset: 12,
                        valueField: "close",
                        lineColor: backup.colors[0],
                        lineThickness: 2,
                        dateFormat: "YYYY-MM",
                        compareGraph: {lineThickness: 2},
                        //bulletSizeField: "bullet_size"
                    },{
                        id: "se5",
                        type: "line",
                        title: "5y avg",
                        valueField: "close_5",
                        useDataSetColors: false,
                        balloonFunction: function(item) {
                            return  item.graph.legendTextReal + ": " + item.values.value
                        },
                        bullet : "round",
                        bulletSize: 10,
                        fontSize: 14,
                        lineColor: "#ff0000",
                        lineThickness: 2,
                        hidden: true,
                    },{
                        id: "se15",
                        type: "line",
                        title: "15y avg",
                        valueField: "close_15",
                        useDataSetColors: false,
                        balloonFunction: function(item) {
                            return  item.graph.legendTextReal + ": " + item.values.value
                        },
                        bullet : "round",
                        bulletSize: 10,
                        fontSize: 14,
                        lineColor: "#228b22",
                        lineThickness: 2,
                        hidden: true,
                    },{
                        id: "se30",
                        type: "line",
                        title: "30y avg",
                        valueField: "close_30",
                        useDataSetColors: false,
                        balloonFunction: function(item) {
                            return  item.graph.legendTextReal + ": " + item.values.value
                        },
                        bullet : "round",
                        bulletSize: 10,
                        fontSize: 14,
                        lineColor: "#ffd600",
                        lineThickness: 2,
                        hidden: true
                    }],
                    valueAxes : [{
                        position: "right",
                        fontSize: 13
                    }],
                    legend: {
                        position : "top",
                        fontSize: 14,
                        marginLeft : 10,
                        valueWidth: 85,
                        equalWidths: false
                    },
                    drawingIconsEnabled: false
                },{
                    showCategoryAxis: true,
                    percentHeight: 25,
                    stockGraphs: [{
                        id: "g2",
                        type: "column",
                        title: backup.labels[1],
                        useDataSetColors: false,
                        fontSize: 14,
                        valueField: "oi",
                        lineColor: "LimeGreen",
                        fillAlphas: 0.5,
                        lineAlpha: 0.5,
                        clustered: false,
                        showBalloon: false,
                        fixedColumnWidth: 15,
                    },{
                        id: "g3",
                        type: "column",
                        title: backup.labels[2],
                        useDataSetColors: false,
                        fontSize: 14,
                        valueField: "volume",
                        lineColor: "#db4c3c",
                        fillAlphas: 0.5,
                        lineAlpha: 0.5,
                        clustered: false,
                        showBalloon: false,
                        fixedColumnWidth: 15,
                    }],
                    valueAxes : [{
                        position: "right",
                        // labelFunction: valueAxisFormat, !!!!!!!!!!!!!!!
                        fontSize: 13
                    }],
                    legend: {
                        position : "top",
                        fontSize: 14,
                        marginLeft : 10,
                        valueWidth: 85
                    },
                    drawingIconsEnabled: false
                }],
                panelsSettings : this.defaultPanelSettings(payload.functionId),
                chartCursorSettings: {
                    categoryBalloonEnabled: true,
                    // pan : is_touch_device(), !!!!!!!!
                    pan: this.isTouch(),
                    fontSize: 12,
                    graphBulletSize : 1.5,
                    valueBalloonsEnabled: true,
                    valueLineEnabled: true,
                    valueLineBalloonEnabled: true,
                    zoomable: false,
                },
                chartCursor: {
                    zoomable: false
                },
                valueAxesSettings : {
                    inside : false
                },
                categoryAxis: {
                    parseDates: true
                },
                categoryAxesSettings : {
                    axisAlpha : 0,
                    gridAlpha : 0.2,
                    inside : false,
                    tickLength : 0.5,
                    dashLength : 4,
                    equalSpacing : false,
                    labelRotation : 0,
                    groupToPeriods: [],
                    fontSize: this.defaultCategoryAxesSettings(payload.funtionId).fontSize
                },
                chartScrollbarSettings : {enabled: false},
                periodSelector: {inputFieldsEnabled: false},
                balloon : this.defaultBalloon(payload.functionId),
            })

            ch.panels[0].stockGraphs[0].comparedGraphs.ds2.hidden = isHidden['ds2']
            ch.panels[0].stockGraphs[0].comparedGraphs.ds3.hidden = isHidden['ds3']
            ch.panels[0].stockGraphs[0].comparedGraphs.ds4.hidden = isHidden['ds4']
            ch.panels[0].stockGraphs[1].hidden = isHidden['se5']
            ch.panels[0].stockGraphs[2].hidden = isHidden['se15']
            ch.panels[0].stockGraphs[3].hidden = isHidden['se30']

            return ch;
        },
    }
}
