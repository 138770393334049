<template>
    <div>
        <div class="resize-container" v-bind:style="{height: h + 'px'}">
            <div class="resize-content" v-bind:style="{height: h + 'px'}">
                <slot name="content"></slot>
            </div>
        </div>
        <div class="resize-handle" v-bind:style="{height: handleHeight + 'px'}" @mousedown="start">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'resizor',
        props: {
            height: {default: 400},
            handleHeight: {default: 4},
            minHeight: {default: 0}
        },
        data() {
            return {
                resizing: false,
                h: 400,
            }
        },
        created() {
            this.h = this.height
        },
        methods: {
            start(e) {
                this.initY = e.pageY
                window.addEventListener("mousemove", this.move)
                window.addEventListener("mouseup", this.stop)
            },
            stop() {
                window.removeEventListener("mousemove", this.move)
                window.removeEventListener("mouseup", this.stop)

                this.$emit("resized", this.h)
            },
            move(e) {
                var dy = (e.pageY - this.initY)
                
                if (this.h + dy >= this.minHeight) {
                    this.h += dy 
                    this.initY = e.pageY
                }
            }
        }
    }
</script>

<style scoped>
    div.resize-container {
        display: inline-block;
        width: calc(100%);
    }

    div.resize-handle {
        background-color: #eee;
        cursor: ns-resize;
    }
</style>
