<template>
    <div id="container">
        <div class="content" v-show="!loading && !done">
            <table class="ftable">
                <!--email-->
                <tr>
                    <td class="error">
                        <div v-show="!valid.email">
                            {{msg.email}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="text" v-model="email" :placeholder="lang == 'cs' ? 'E-mail (uživatelské jméno)' : 'E-mail (username)'" :class="{'error': !valid.email}">
                    </td>
                </tr>

                <!-- heslo -->
                <tr>
                    <td class="error">
                        <div v-show="!valid.password">
                            {{msg.password}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td >
                        <input type="password" v-model="password" :placeholder="lang == 'cs' ? 'Heslo' : 'Password'" :class="{'error': !valid.password}">
                    </td>
                </tr>

                <!-- potvrzeni hesla -->
                <tr>
                    <td class="error">
                        <div v-show="!valid.password_test">
                            {{msg.password_test}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="password" v-model="password_test" :placeholder="lang == 'cs' ? 'Heslo znovu' : 'Confirm password'" :class="{'error': !valid.password_test}">
                    </td>
                </tr>

                <tr>
                    <td class="error">
                        <div v-show="!valid.country">
                            {{msg.country}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="select">
                        <select v-model="country" :class="{'error': !valid.country}">
                            <option v-if="lang == 'cs'" value=null selected disabled>Vyberte zemi ...</option>
                            <option v-else value=null selected disabled>Choose your country ...</option>
                            <option v-for="c in countries" :value="c.code">{{c.name}}</option>
                        </select>
                    </td>
                </tr>

                <tr><td class="spacer"></td></tr>

                <tr>
                    <td class="error">
                        <div v-show="!valid.terms">
                            {{msg.terms}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="checkbox">
                        <input type="checkbox" v-model="terms" :class="{'error': !valid.terms}">
                        <label v-if="lang == 'cs'" style="display: inline-block; vertical-align: top">
                            Souhlasím se  <a href="https://spreadcharts.com/cs/smluvni-podminky/" target="_blank">Smluvními podmínkami</a> a <br> <a href="https://spreadcharts.com/cs/ochrana-osobnich-udaju/" target="_blank">Zásadami ochrany osobních údajů</a>
                        </label>
                        <label v-else>
                            I agree with <a href="https://spreadcharts.com/terms-of-service/" target="_blank">Terms of service</a> and <a href="https://spreadcharts.com/privacy-policy/" target="_blank">Privacy policy</a>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="submit-button" @click="submit">
                            <div v-if="lang == 'cs'">
                                REGISTROVAT
                            </div>
                            <div v-else>
                                REGISTER
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="content" v-show="loading && !done">
            <div class="loader-wrapper">
                <loader/>
            </div>
        </div>

        <div class="content" v-show="done">
            <div class="done-wrapper" v-if="lang == 'cs'">
                Registrace byla úspěšně dokončena a váš účet je připraven. <br>
                Otevřete si nyní aplikaci SpreadCharts a začněte využívat její pokročilé funkce.
                <a href="/" target="_blank">
                <br>
                <div class="submit-button">
                    <div>
                        PŘEJÍT DO APLIKACE
                    </div>
                </div>
                </a>
            </div>

            <div class="done-wrapper" v-else>
                The registration has been completed and your account is ready. <br>
                Let's open the SpreadCharts app and start exploring its features.
                <a href="/" target="_blank">
                <br>
                <div class="submit-button">
                    <div>
                        GO TO THE APP
                    </div>
                </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {dSource} from "./vars.js";
    import loader from './loader.vue';
    import axios from 'axios';

    export default {
        name: "Registration",
        components: {loader},
        props: {
            lang: {
            }  
        },
        data() {
            return {
                dSource: dSource,
                loading: false,
                done: false,

                /**
                  * Formularova data
                  */
                email: null,
                password: null,
                password_test: null,
                country: null,
                terms: false,

                /**
                  * Ukazatele validace
                  */
                valid: {
                    email: true,
                    password: true,
                    password_test: true,
                    country: true,
                    terms: true
                },

                /**
                  * Chybove hlasky
                  */
                msg: {
                    email: null,
                    password: null,
                    password_test: null,
                    country: null,
                    terms: null
                },

                /**
                  * Seznam zemi pro select
                  */
                countries: [
                    {name: "United States", code: "US"},
                    {name: "United Kingdom", code: "GB"},
                    {name: "Czech Republic", code: "CZ"},
                    {name: "Slovakia", code: "SK"},
                    {name: "Afghanistan", code: "AF"},
                    {name: "Albania", code: "AL"},
                    {name: "Algeria", code: "DZ"},
                    {name: "American Samoa", code: "AS"},
                    {name: "Andorra", code: "AD"},
                    {name: "Angola", code: "AO"},
                    {name: "Anguilla", code: "AI"},
                    {name: "Antarctica", code: "AQ"},
                    {name: "Antigua and Barbuda", code: "AG"},
                    {name: "Argentina", code: "AR"},
                    {name: "Armenia", code: "AM"},
                    {name: "Aruba", code: "AW"},
                    {name: "Australia", code: "AU"},
                    {name: "Austria", code: "AT"},
                    {name: "Azerbaijan", code: "AZ"},
                    {name: "Bahamas", code: "BS"},
                    {name: "Bahrain", code: "BH"},
                    {name: "Bangladesh", code: "BD"},
                    {name: "Barbados", code: "BB"},
                    {name: "Belarus", code: "BY"},
                    {name: "Belgium", code: "BE"},
                    {name: "Belize", code: "BZ"},
                    {name: "Benin", code: "BJ"},
                    {name: "Bermuda", code: "BM"},
                    {name: "Bhutan", code: "BT"},
                    {name: "Bolivia", code: "BO"},
                    {name: "Bosnia and Herzegovina", code: "BA"},
                    {name: "Botswana", code: "BW"},
                    {name: "Bouvet Island", code: "BV"},
                    {name: "Brazil", code: "BR"},
                    {name: "British Indian Ocean Territory", code: "IO"},
                    {name: "Brunei Darussalam", code: "BN"},
                    {name: "Bulgaria", code: "BG"},
                    {name: "Burkina Faso", code: "BF"},
                    {name: "Burundi", code: "BI"},
                    {name: "Cambodia", code: "KH"},
                    {name: "Cameroon", code: "CM"},
                    {name: "Canada", code: "CA"},
                    {name: "Cape Verde", code: "CV"},
                    {name: "Cayman Islands", code: "KY"},
                    {name: "Central African Republic", code: "CF"},
                    {name: "Chad", code: "TD"},
                    {name: "Chile", code: "CL"},
                    {name: "China", code: "CN"},
                    {name: "Christmas Island", code: "CX"},
                    {name: "Cocos (Keeling) Islands", code: "CC"},
                    {name: "Colombia", code: "CO"},
                    {name: "Comoros", code: "KM"},
                    {name: "Congo", code: "CG"},
                    {name: "Congo, The Democratic Republic of The", code: "CD"},
                    {name: "Cook Islands", code: "CK"},
                    {name: "Costa Rica", code: "CR"},
                    {name: "Croatia", code: "HR"},
                    {name: "Cuba", code: "CU"},
                    {name: "Cyprus", code: "CY"},
                    {name: "Denmark", code: "DK"},
                    {name: "Djibouti", code: "DJ"},
                    {name: "Dominica", code: "DM"},
                    {name: "Dominican Republic", code: "DO"},
                    {name: "Ecuador", code: "EC"},
                    {name: "Egypt", code: "EG"},
                    {name: "El Salvador", code: "SV"},
                    {name: "Equatorial Guinea", code: "GQ"},
                    {name: "Eritrea", code: "ER"},
                    {name: "Estonia", code: "EE"},
                    {name: "Ethiopia", code: "ET"},
                    {name: "Falkland Islands (Malvinas)", code: "FK"},
                    {name: "Faroe Islands", code: "FO"},
                    {name: "Fiji", code: "FJ"},
                    {name: "Finland", code: "FI"},
                    {name: "France", code: "FR"},
                    {name: "French Guiana", code: "GF"},
                    {name: "French Polynesia", code: "PF"},
                    {name: "French Southern Territories", code: "TF"},
                    {name: "Gabon", code: "GA"},
                    {name: "Gambia", code: "GM"},
                    {name: "Georgia", code: "GE"},
                    {name: "Germany", code: "DE"},
                    {name: "Ghana", code: "GH"},
                    {name: "Gibraltar", code: "GI"},
                    {name: "Greece", code: "GR"},
                    {name: "Greenland", code: "GL"},
                    {name: "Grenada", code: "GD"},
                    {name: "Guadeloupe", code: "GP"},
                    {name: "Guam", code: "GU"},
                    {name: "Guatemala", code: "GT"},
                    {name: "Guinea", code: "GN"},
                    {name: "Guinea-bissau", code: "GW"},
                    {name: "Guyana", code: "GY"},
                    {name: "Haiti", code: "HT"},
                    {name: "Heard Island and Mcdonald Islands", code: "HM"},
                    {name: "Holy See (Vatican City State)", code: "VA"},
                    {name: "Honduras", code: "HN"},
                    {name: "Hong Kong", code: "HK"},
                    {name: "Hungary", code: "HU"},
                    {name: "Iceland", code: "IS"},
                    {name: "India", code: "IN"},
                    {name: "Indonesia", code: "ID"},
                    {name: "Iran, Islamic Republic of", code: "IR"},
                    {name: "Iraq", code: "IQ"},
                    {name: "Ireland", code: "IE"},
                    {name: "Israel", code: "IL"},
                    {name: "Italy", code: "IT"},
                    {name: "Jamaica", code: "JM"},
                    {name: "Japan", code: "JP"},
                    {name: "Jordan", code: "JO"},
                    {name: "Kazakhstan", code: "KZ"},
                    {name: "Kenya", code: "KE"},
                    {name: "Kiribati", code: "KI"},
                    {name: "Korea, Republic of", code: "KR"},
                    {name: "Kuwait", code: "KW"},
                    {name: "Kyrgyzstan", code: "KG"},
                    {name: "Latvia", code: "LV"},
                    {name: "Lebanon", code: "LB"},
                    {name: "Lesotho", code: "LS"},
                    {name: "Liberia", code: "LR"},
                    {name: "Libyan Arab Jamahiriya", code: "LY"},
                    {name: "Liechtenstein", code: "LI"},
                    {name: "Lithuania", code: "LT"},
                    {name: "Luxembourg", code: "LU"},
                    {name: "Macao", code: "MO"},
                    {name: "Macedonia, The Former Yugoslav Republic of", code: "MK"},
                    {name: "Madagascar", code: "MG"},
                    {name: "Malawi", code: "MW"},
                    {name: "Malaysia", code: "MY"},
                    {name: "Maldives", code: "MV"},
                    {name: "Mali", code: "ML"},
                    {name: "Malta", code: "MT"},
                    {name: "Marshall Islands", code: "MH"},
                    {name: "Martinique", code: "MQ"},
                    {name: "Mauritania", code: "MR"},
                    {name: "Mauritius", code: "MU"},
                    {name: "Mayotte", code: "YT"},
                    {name: "Mexico", code: "MX"},
                    {name: "Micronesia, Federated States of", code: "FM"},
                    {name: "Moldova, Republic of", code: "MD"},
                    {name: "Monaco", code: "MC"},
                    {name: "Mongolia", code: "MN"},
                    {name: "Montserrat", code: "MS"},
                    {name: "Morocco", code: "MA"},
                    {name: "Mozambique", code: "MZ"},
                    {name: "Myanmar", code: "MM"},
                    {name: "Namibia", code: "NA"},
                    {name: "Nauru", code: "NR"},
                    {name: "Nepal", code: "NP"},
                    {name: "Netherlands", code: "NL"},
                    {name: "Netherlands Antilles", code: "AN"},
                    {name: "New Caledonia", code: "NC"},
                    {name: "New Zealand", code: "NZ"},
                    {name: "Nicaragua", code: "NI"},
                    {name: "Niger", code: "NE"},
                    {name: "Nigeria", code: "NG"},
                    {name: "Niue", code: "NU"},
                    {name: "Norfolk Island", code: "NF"},
                    {name: "Northern Mariana Islands", code: "MP"},
                    {name: "Norway", code: "NO"},
                    {name: "Oman", code: "OM"},
                    {name: "Pakistan", code: "PK"},
                    {name: "Palestinian Territory, Occupied", code: "PS"},
                    {name: "Panama", code: "PA"},
                    {name: "Papua New Guinea", code: "PG"},
                    {name: "Paraguay", code: "PY"},
                    {name: "Peru", code: "PE"},
                    {name: "Philippines", code: "PH"},
                    {name: "Pitcairn", code: "PN"},
                    {name: "Poland", code: "PL"},
                    {name: "Portugal", code: "PT"},
                    {name: "Puerto Rico", code: "PR"},
                    {name: "Qatar", code: "QA"},
                    {name: "Reunion", code: "RE"},
                    {name: "Romania", code: "RO"},
                    {name: "Russian Federation", code: "RU"},
                    {name: "Rwanda", code: "RW"},
                    {name: "Saint Helena", code: "SH"},
                    {name: "Saint Kitts and Nevis", code: "KN"},
                    {name: "Saint Lucia", code: "LC"},
                    {name: "Saint Pierre and Miquelon", code: "PM"},
                    {name: "Saint Vincent and The Grenadines", code: "VC"},
                    {name: "Samoa", code: "WS"},
                    {name: "San Marino", code: "SM"},
                    {name: "Sao Tome and Principe", code: "ST"},
                    {name: "Saudi Arabia", code: "SA"},
                    {name: "Senegal", code: "SN"},
                    {name: "Serbia and Montenegro", code: "ME"},
                    {name: "Seychelles", code: "SC"},
                    {name: "Sierra Leone", code: "SL"},
                    {name: "Singapore", code: "SG"},
                    {name: "Slovenia", code: "SI"},
                    {name: "Solomon Islands", code: "SB"},
                    {name: "Somalia", code: "SO"},
                    {name: "South Africa", code: "ZA"},
                    {name: "South Georgia and The South Sandwich Islands", code: "GA"},
                    {name: "Spain", code: "ES"},
                    {name: "Sri Lanka", code: "LK"},
                    {name: "Sudan", code: "SD"},
                    {name: "Suriname", code: "SR"},
                    {name: "Svalbard and Jan Mayen", code: "SJ"},
                    {name: "Swaziland", code: "SZ"},
                    {name: "Sweden", code: "SE"},
                    {name: "Switzerland", code: "CH"},
                    {name: "Syrian Arab Republic", code: "SY"},
                    {name: "Taiwan, Province of China", code: "TW"},
                    {name: "Tajikistan", code: "TJ"},
                    {name: "Tanzania, United Republic of", code: "TZ"},
                    {name: "Thailand", code: "TH"},
                    {name: "Timor-leste", code: "TL"},
                    {name: "Togo", code: "TG"},
                    {name: "Tokelau", code: "TK"},
                    {name: "Tonga", code: "TO"},
                    {name: "Trinidad and Tobago", code: "TT"},
                    {name: "Tunisia", code: "TN"},
                    {name: "Turkey", code: "TR"},
                    {name: "Turkmenistan", code: "TM"},
                    {name: "Turks and Caicos Islands", code: "TC"},
                    {name: "Tuvalu", code: "TV"},
                    {name: "Uganda", code: "UG"},
                    {name: "Ukraine", code: "UA"},
                    {name: "United Arab Emirates", code: "AE"},
                    {name: "United States Minor Outlying Islands", code: "UM"},
                    {name: "Uruguay", code: "UY"},
                    {name: "Uzbekistan", code: "UZ"},
                    {name: "Vanuatu", code: "VU"},
                    {name: "Venezuela", code: "VE"},
                    {name: "Viet Nam", code: "VN"},
                    {name: "Virgin Islands, British", code: "VG"},
                    {name: "Virgin Islands, U.S.", code: "VI"},
                    {name: "Wallis and Futuna", code: "WF"},
                    {name: "Western Sahara", code: "EH"},
                    {name: "Yemen", code: "YE"},
                    {name: "Zambia", code: "ZM"},
                    {name: "Zimbabwe", code: "ZW"}
                ],

                timer: 0
            }
        },
        methods: {
            // vycisteni vsech inputu do zakladniho stavu
            clear() {
                this.email = null
                this.password = null
                this.password_test = null
                this.country = null
                this.terms = false
            },
                        
            submit() {
                // "captcha" timer
                // pokud je moc rychly smazat vsechny inputy a prenastavit timer
                if (new Date().getTime() - this.timer < 2000) {
                    this.clear()
                    this.timer = new Date().getTime()
                    return
                }

                // validace vseho
                this.validateAll()

                // validni vsechny vstupy?
                if (!this.submitable) {return}

                this.done = false
                this.loading = true

                // odeslat
                var robj = {
                    user: {id: -1},
                    rkeys: ["result"],
                    chain: [
                        {action: "Registration", i:0, dkey: "result", request:{data: {email: this.email, password: this.password, country_code: this.country} }}
                    ]
                }
                axios.post(dSource, {robj: robj}).then(payload => this.submitCallback(payload.data.data.result))
            },

            // zpracovani odpovedi serveru
            submitCallback(data) {
                if (!data.success) {
                    // uzivatel uz existuje
                    if (data.errors.includes("user exists")) {
                        this.valid.email = false
                        this.msg.email = this.lang == 'cs' ? "Zvolený uživatel už existuje." : "This user already exists."
                    }
                    this.loading = false
                    return
                }

                this.loading = false
                this.done = true

                this.setCookie("authToken", data.token)

                //setTimeout(function() {
                //    document.getElementById("regRedirectLink").click()
                //}, 100)
            },

            /**
              * Nastaveni cookie authToken (vlastne stejne jako v Home.vue)
              */
            setCookie(name, value) {
                var expires = "";
                var date = new Date();
                date.setTime(date.getTime() + (10000*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
                var domain = window.location.hostname.replace(/^[a-z0-9]{1,}\./, ".") // na "sdilenou" domenu
                document.cookie = name + "=" + (value || "")  + expires + ";domain="+domain+";path=/";
            },

            /**
              * Validacni funkce
              */
            validateEmail(value) {
                // vyplneno?
                this.valid.email = value != null && value.length > 0
                if (!this.valid.email) {
                    this.msg.email = this.lang == 'cs' ? "Vyplňte email." : "Please fill in your email."
                    return
                }

                // spravny tvar mailu?
                // all lowercase
                this.valid.email =  /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,7})$/.test(value)
                if (!this.valid.email) {
                    this.msg.email = this.lang == 'cs' ? "Nesprávný tvar emailu (povolena jsou pouze malá písmena)" : "Incorect email format (only lowercase letters are allowed)"
                    return
                }
            },
            validatePassword(value) {
                // vyplneno?
                this.valid.password = value != null && value.length > 0
                if (!this.valid.password) {
                    this.msg.password = this.lang == 'cs' ? "Vyplňte heslo" : "Choose your password"
                    return
                }

                // minimalni delka
                this.valid.password = value.length >= 10
                if (!this.valid.password) {
                    this.msg.password = this.lang == 'cs' ? "Nejméně 10 znaků" : "At least 10 characters"
                    return
                }
            },
            validatePasswordTest(value) {
                // vyplneno?
                this.valid.password_test = value != null && value.length > 0
                if (!this.valid.password_test) {
                    this.msg.password_test = this.lang == 'cs' ? "Vyplňte heslo znovu" : "Confirm your password"
                    return
                }

                // minimalni delka
                this.valid.password_test = value.length >= 10
                if (!this.valid.password_test) {
                    this.msg.password_test = this.lang == 'cs' ? "Nejméně 10 znaků" : "At least 10 characters"
                    return
                }

                // stejne jako prvni?
                this.valid.password_test = value == this.password
                if (!this.valid.password_test) {
                    this.msg.password_test = this.lang == 'cs' ? "Hesla nejsou stejná" : "Passwords are different"
                    return
                }
            },
            validateCountry(value) {
                this.valid.country = value != null
                if (!this.valid.country) {
                    this.msg.country = this.lang == 'cs' ? "Vyberte zemi" : "Choose your country"
                    return
                }
            },
            validateTerms(value) {
                this.valid.terms = value
                if (!this.valid.terms) {
                    this.msg.terms = this.lang == 'cs' ? "Přečtěte si prosím naše podmínky a odsouhlaste je" : "Read our terms and agree to them, please"
                }
            },
            validateAll() {
                this.validateEmail(this.email)
                this.validatePassword(this.password)
                this.validatePasswordTest(this.password_test)
                this.validateCountry(this.country)
                this.validateTerms(this.terms)
            } 
        },
        mounted() {
            this.timer = new Date().getTime()
        },
        computed: {
            submitable() {
                for (var key in this.valid) {
                    if (!this.valid[key]) {
                        return false
                    }
                }
                return true
            }  
        },
        watch: {
            email(value) {
                this.validateEmail(value)
            },
            password(value) {
                this.validatePassword(value)
            },
            password_test(value) {
                this.validatePasswordTest(value)
            },
            country(value) {
                this.validateCountry(value)
            },
            terms(value) {
                this.validateTerms(value)
            }
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

    div#container {
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        height: 575px;
        width: 95%;
        margin: auto;
    }

    div.content {
        border: 1px solid #fafafa;
        display: inline-block;
        box-shadow: rgba(35, 31, 32, 0.16) 0px 24px 27px 0px;
        border-radius: 20px;
        margin: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }

    table.ftable {
        margin-top: 30px;
        font-size: 14px;
        font-family: inherit;
        width: 100%;
    }

    td {
        text-align: center;
    }

    td > input,
    td > select {
        font-size: inherit; 
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        margin: 0px 10px 2px 10px;
    }

    input::placeholder {
        color: #888;
    }

    td > input[type="text"],
    td > input[type="password"],
    td > select {
        padding: 8px 12px 8px 12px;
        font-family: inherit;
        width: 80%;
    }

    td.checkbox {
        text-align: left;
    }

    td.checkbox > input[type="checkbox"] {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        margin-left: 40px;
    }

    td.checkbox > label {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        top: -1px;
        font-weight: 700;
    }

    td.checkbox > label > a,
    td.checkbox > label > a:visited {
        text-decoration: none;
        color: #f76c22;
    }

    td.select {
        text-align: left;
        padding-left: 30px;
    }

    td.select > select {
        width: calc(100% - 50px);
        color: #888;
        font-weight: normal;
    }

    td.spacer {
        height: 10px;
    }

    input.error,
    select.error {
        border: 1px solid rgba(255, 0, 0, 0.25);
        -webkit-box-shadow: 2px 2px 5px 0px rgba(250, 0, 0, 0.25);
        -moz-box-shadow: 2px 2px 5px 0px rgba(250, 0, 0, 0.25);
        box-shadow: 2px 2px 5px 0px rgba(250, 0, 0, 0.25);
    }

    input.error[type="checkbox"] {
        box-shadow: none;
    }

    td.error {
        font-size: 11px;    
        height: 15px;
        padding-top: none;
        padding-bottom: none;
        color: red;
        text-align: left;
        padding-left: 40px;
    }

    div.submit-button {
        font-size: 15px;
        font-weight: 700;
        color: white;
        background-color: #3c9d3a;
        margin-top: 30px;
        padding: 20px 50px 20px 50px;
        border-radius: 31px;
        display: inline-block;
        cursor: pointer;
        letter-spacing: 0.75px;
    }

    div.loader-wrapper {
        text-align: center;
        width: 100%;
        margin-top: 150px;
    }

    div.done-wrapper {
        margin-top: 200px;
        font-size: 15px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1.0);
    }
</style>
