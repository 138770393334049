<template>
    <div class="container" :key="build">
        <div :class="{'modal-backdrop': modal, 'hidden': !visible}"> 
            <div :class="{'modal-box': modal}">
                <div :class="{'modal-header': modal, 'hidden': !modal}">
                    <div v-show="!edit" class="modal-name">
                        Add new sub-chart
                    </div>
                    <div v-show="edit" class="modal-name">
                        Edit sub-chart
                    </div>
                    <div class="modal-close-wrapper">
                        <div class="modal-close-btn">
                            <i class="icons8-delete-2" @click="onClose"></i>
                        </div>

                        <div style="clear: both"></div>
                    </div>
                </div>
                
                <div :class="{'modal-body': modal}">
                    <div class="parserBox" :class="{'modal': modal}">

                        <table class="inputTable">
                            <tr>
                                <td>
                                    <div class="tickerSelectBox" v-click-outside="hideTickerTree">
                                        <div class="errorBox">
                                        </div>
                                        <div class="tickerInput">
                                            <input type="text" autocomplete="off" v-model="ticker" @input="run" placeholder="Select ticker" @click="tickerOpened = !tickerOpened" ref="ticker" />
                                        </div>

                                        <div class="tickerTree" v-show="tickerOpened && !tickerDisabled">
                                            <tree v-if="isOperator" v-for="node in nodesOperators" :open="true" :key="node.id" :label="node.label" :nodes="node.nodes" :value="node.value" :depth="0" @select="onOperatorSelect" />
                                            <tree v-if="!isOperator" v-for="node in tickerNodesFiltered" :open="tickerNodesOpened" :key="node.id" :label="node.label" :nodes="node.nodes" :value="node.value" :depth="0" @select="onTickerSelect"/>
                                        </div> 
                                    </div>

                                </td>

                                <td>
                                    <div v-click-outside="hideActionTree">
                                        <div class="errorBox">
                                        </div>
                                        <div class="actionInput" :class="{'opened': actionOpened, 'closed': !actionOpened}" @click="actionOpened = !actionOpened" >
                                            <div v-if="!actionAny" class="actionValue actionPlaceholder">No available action</div>
                                            <div v-if="actionAny && action == null" class="actionValue actionPlaceholder">Select action</div>
                                            <div v-if="actionAny && action != null" class="actionValue">{{action.name}}</div>
                                        </div>
                                        <div v-show="actionOpened && actionAny" class="actionTree" >
                                            <tree v-for="node in actionNodes" :key="node.id" :label="node.label" :nodes="node.nodes" :depth="0" @select="onActionSelect"/> 
                                        </div>
                                    </div>
                                </td>

                                <td :class="{'hidden-td': !hasAlg}">
                                    <transition name="fadeRoll">
                                    <div class="chartInputBox" v-show="hasAlg">
                                        <div class="errorBox">
                                        </div>
                                        <div class="algInput" @click="algOpened = !algOpened">
                                            <div class="algValue">{{algLabels[alg]}}</div>
                                        </div>
                                        <div v-show="algOpened" class="algTree">
                                            <tree v-for="node in algNodes" :key="node.id" :label="node.label" :value="node.value" :nodes="node.nodes" :selected="node.selected" :depth="0" @select="onAlgSelect"/> 
                                        </div>
                                    </div>
                                    </transition>
                                </td>

                                <td>
                                    <div class="errorBox">
                                    </div>
                                    <div class="chartInputBox">
                                        <input type="button" value="Chart" class="chartInput" :class="{'button-green': chartButtonEnabled, 'button-grey': !chartButtonEnabled}" :disabled="!chartButtonEnabled" @click="onChartClicked"/>
                                    </div>
                                </td>
                            </tr>

                            <tr v-if="hasDatepicker && !hasOffset" :class="{'toolsOut': toolsBoxOut, 'toolsIn': !toolsBoxOut}">
                                <td>
                                    <div class="errorBox">
                                    </div>
                                    <div class="chartInputBox">
                                        <date-picker
                                            input-class="dateInput"
                                            popup-class="datePopup"
                                            v-model="time"
                                            :format="dFormat"
                                            :lang="'en'"
                                            :height="35"
                                            :clearable="false"
                                            :disabled="!hasDatepicker"
                                            value-type="timestamp"
                                            ref="datepicker"
                                        >
                                            <i slot="calendar-icon" class="icons8-calendar-11"></i>
                                        </date-picker>
                                    </div>
                                </td>
                                <td>
                                </td>
                            </tr>
                            <tr v-if="!hasDatepicker && hasOffset"  :class="{'toolsOut': toolsBoxOut, 'toolsIn': !toolsBoxOut}">
                                <td>
                                    <div class="errorBox">
                                        <span v-show="!off.valid">{{off.error}}</span>
                                    </div>
                                    <div class="chartInputBox">
                                        <input type="text" autocomplete="off" placeholder="Shift contract window +/- n days" v-model="off.value" :class="{'error': !off.valid}" @input="validateOffset" :disabled="!hasOffset"/>
                                    </div>
                                </td>

                                <td>
                                </td>
                            </tr>
                            <tr v-if="hasDatepicker && hasOffset" :class="{'toolsOut': toolsBoxOut, 'toolsIn': !toolsBoxOut}">
                                <td>
                                    <div class="errorBox">
                                    </div>
                                    <div class="chartInputBox">
                                        <date-picker
                                            input-class="dateInput"
                                            popup-class="datePopup"
                                            v-model="time"
                                            :format="dFormat"
                                            :lang="'en'"
                                            :height="35"
                                            :clearable="false"
                                            :disabled="!hasDatepicker"
                                            value-type="timestamp"
                                            ref="datepicker"
                                        >
                                            <i slot="calendar-icon" class="icons8-calendar-11"></i>
                                        </date-picker>
                                    </div>
                                </td>

                                <td>
                                    <div class="errorBox">
                                        <span v-show="!off.valid">{{off.error}}</span>
                                    </div>
                                    <div class="chartInputBox">
                                        <input type="text" autocomplete="off" placeholder="Shift contract window +/- n days" v-model="off.value" :class="{'error': !off.valid}" @input="validateOffset" :disabled="!hasOffset"/>
                                    </div>
                                </td>
                            </tr>
                        </table>

                        <div style="float: right; margin-right: 8px;">
                            <div class="errorBox">
                            </div>
                            <input type="button" 
                                value="Tools" 
                                :class="{'button-grey': !hasTools, 'button-blue': hasTools && !toolsBoxOut, 'button-red': toolsBoxOut}" 
                                :disabled="!chartButtonEnabled" 
                                @click="toolsBoxOut = !toolsBoxOut"
                            />
                        </div>

                    <div style="clear: both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import tree  from "./tree.vue"; 

    import DatePicker from 'vue2-datepicker';

    import ClickOutside from 'vue-click-outside';

    export default {
        name: 'Parser2',
        components: {"tree":tree, "date-picker":DatePicker},
        props: {
            user: {
                type: Object,
                required: true
            },
            targetId: {
                type: String,
                required: true
            },
            modal: {
                type: Boolean,
                default: false
            },
            visible: {
                type: Boolean,
                default: false
            },
            edit: {
                type: Boolean,
                default: false
            }
        },
        directives: {
            ClickOutside
        },
        data() {
            return {
                build: 0,

                // mozne operatory a jim prislusejici akce
                operators: {"+": "Add", "-":"Subtract", "*":"Multiply", "/":"Divide"},

                // ukazatel ze se prave zadava operator
                isOperator: false,

                // hodnota v ticker inputu
                ticker: null,

                tickerOpened: false,
                tickerDisabled: false,
                tickerNodesOpened: false,
                tickerNodesFiltered: [],
                tickerNodesAll: [],

                off: {
                    valid: true,
                    value: null,
                    error: null
                },

                // operatory v naseptavaci
                nodesOperators: [
                    {label:"- Subtract", value:"-"},
                    {label:"+ Add", value:"+"},
                    {label:"* Multiply", value:"*"},
                    {label:"/ Divide", value:"/"},
                ],

                // akce a zachovana predchozi akce pro mozny navrat 
                // pokud je predchozi akce dostupna a povolena
                action: null,
                actionOld: null,

                // vsechny mozne akce
                // ts a cot vytvarene dynamicky podle obsazenych komodit
                actions: {
                    price: [
                        {id: "price_volume", base: "Price", name: "Price & volume", enabled: false, functionId: "priceCandlestickFutures"},
                        {id: "price_under", base: "Price", name: "Price & underlyings", enabled: false, functionId: "priceLine", trans: []},
                        {id: "price_hist", base: "Price", name: "Price", enabled: false, functionId: "priceCandlestickHistorical", trans: ["Aggregate"]},
                        {id: "price_con", base: "Seasonality", name: "Continuous price", enabled:false, functionId: "PriceContinuous", trans: ["PriceContinuous", "Aggregate"]},
                        {id: "histogram", base: "Seasonality", name: "Continuous histogram", enabled:false, functionId: "histogram2", trans: ["PriceContinuous", "Histogram"]},
                        {id: "seasonality_stacked", base: "Seasonality", name: "Seasonality - stacked", enabled:false, functionId: "seasonalityStacked"},
                        {id: "seasonality_average", base: "Seasonality", name: "Seasonality - average", enabled:false, functionId: "seasonalityAverages", trans: ["Average"]},
                        {id: "seasonality_month", base: "Seasonality", name: "Seasonality - by month", enabled:false, functionId: "seasonalityMonth", trans: ["SeasonalityMonth"]},
                        {id: "volume", base: "Price", name: "Volume & OI comparison", enabled:false, functionId: "volume", trans: ["Volume"]}
                    ],
                    ts: [],
                    cot: [],
                },

                // srozumitelne popisky/nazvy jednotlivych skupin v action selectu
                actionGroupLabels: {price: "Price", ts: "Term structure", cot: "COT"},

                // testovaci "lookup table" pro povoleni akci podle typu dotazu
                actionLookup: {
                    futures: ["price_volume", "price_con", "histogram", "seasonality_stacked", "seasonality_average", "seasonality_month"],
                    historical: ["price_hist", "histogram"],
                    spread: ["price_under", "price_con", "histogram", "seasonality_stacked", "seasonality_average", "seasonality_month", "volume"]
                },

                // ukazatel ze vubec nejaka jakakoliv akce je povolena
                // pokud je false tak zobazeny "No action" placeholder
                actionAny: false,

                // ukazatel otevreneho stromu
                // po kazdem rebuildu (alespon zatim) se resetuje na false
                actionOpened: false,

                // kvuli pristupnosti docasneho stavu robj
                robj: null,

                // hodnota (timespamp) vyberu v datepickeru
                time: new Date().getTime(),

                // seznam zkratek mesicu (kvuli formatovani)
                months : ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],

                // custom formatovani vypisu v datickeru
                dFormat: {
                    stringify: (d) => {
                        return this.months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear()           
                    }
                },

                // hodnota vybraneho alg
                alg: "byTS",

                // popisky alg selectu
                algLabels: {
                    byTS: "Roll method: On Last Trading Day",
                    byOI: "Roll method: By max Open Interest"
                },

                // ukazatel otevreneho alg stromu
                algOpened: false,

                whisperData: [],

                // ukazatel vyjeteho boxu s volitelnymi nastroji
                toolsBoxOut: false, 
            }
        },
        computed: {
            /**
              * Ukazatel stavu prihlaseni uzivatele
              */
            loggedIn() {
                return this.user.id > 0
            },

            /**
              * Zkratky ukazatelu ze dana skupina action selectu je povolena
              * tzn. obsahuje alespon jednu enabled == true akci
              */
            priceActionsEnabled() {return this.groupEnabled("price")},
            tsActionsEnabled() {return this.groupEnabled("ts")},
            cotActionsEnabled() {return this.groupEnabled("cot")},

            /**
              * Obsah action selectu
              * - "on demand" formatuje z "pevenho stromu" vsech akci (this.actions) jen enabled == true
              */
            actionNodes() {
                var nodes = []
                var has_old_action = false // ukazatel moznosti pouziti akce z minula

                // pres vsechny skupiny
                for (var gKey in this.actions) {
                    if (!this.groupEnabled(gKey)) {continue} // prazdna skupina
                    var node = {id: gKey, label: this.actionGroupLabels[gKey], nodes: []} // node pro skupinu

                    // pres vsechny akce ve skupine
                    for (var i in this.actions[gKey]) {
                        if (!this.actions[gKey][i].enabled) {continue} // pouze povolene akce

                        // node akce
                        node.nodes.push({
                            id: this.actions[gKey][i].id, 
                            label: this.actions[gKey][i].name, 
                            value: this.actions[gKey][i], 
                            selected: (this.action !== null && this.action.id == this.actions[gKey][i].id)
                        })
                    }
                    nodes.push(node)
                }

                return nodes
            },

            /**
              * Obsah alg selectu
              */
            algNodes() {
                return [
                    {id: "byTS", label: this.algLabels.byTS, value: "byTS", selected: this.alg == "byTS"},
                    {id: "byOI", label: this.algLabels.byOI, value: "byOI", selected: this.alg == "byOI"}
                ]
            },

            /**
              * Ukazatel zobrazeni datepickeru
              */
            hasDatepicker() {
                return this.action !== null && (this.action.id == "histogram" || this.action.id.substring(0, 2) == "ts")
            },

            /**
              * Ukazatel zobrazeni alg selectu
              */
            hasAlg() {
                if (this.robj === null) {return false}
                return this.action !== null && (this.action.id == "price_hist" || (this.action.id == "histogram" && this.robj.params.dtype == "Historical"))
            },

            /**
              * Ukazatel povoleni off inputu
              */
            hasOffset() {
                if (this.robj === null) {return false}
                return this.action !== null && (this.action.id == "price_con" || (this.action.id == "histogram" && this.robj.params.dtype == "Futures"))
            },

            /**
              * Ukazatel zobrazeni datepickeru nebo alg. volby
              */
            hasOptionalInputs() {
                var has = this.hasDatepicker || this.hasAlg

                if (!has) {
                    this.algOpened = false
                }

                return has
            },

            hasTools() {
                this.toolsBoxOut = this.toolsBoxOut && (this.hasDatepicker || this.hasOffset) // aby nezustavalo otevrene zbytecne
                return this.hasDatepicker || this.hasOffset
            },

            chartButtonEnabled() {
                return this.action !== null && this.actionAny && this.loggedIn && this.inputsValid
            },

            inputsValid() {
                return this.off.valid
            },

            /**
              * Datum z datepickeru jako formatovany string
              * aby nedochazelo k posunum vlivem timezones
              */
            date() {
                try {
                    var d = new Date(this.time)
                    return d.toISOString().substring(0, 10)
                } catch {
                    var d = new Date()
                    return d.toISOString().substring(0, 10)
                }
            },
        },
        watch: {
            visible: {
                deep: true,
                handler: function(value) {
                    this.$forceUpdate()
                }
            }
        },
        methods: {
            /**
              * Validace
              */
            validateOffset() { // offset (priceContinous, histogram)
                var minOffset = -178 
                var maxOffset = 178 
                this.off.value = ((this.off.value + "").length == 0) ? null : this.off.value
                this.off.error = null
                this.off.valid = true

                // prazdne pole znamena 0
                if (this.off.value === null) {
                    return
                }

                // pouze int
                this.off.valid &= /^[\-]?[0-9]{1,}$/.test(this.off.value + "")
                if (!this.off.valid) {
                    this.off.error = "Integers only"
                    return 
                }

                // v rozsahu
                this.off.valid &= this.off.value >= minOffset && this.off.value <= maxOffset
                if (!this.off.valid) {
                    this.off.error = "Only values between " + minOffset + " and " + maxOffset + " are allowed"
                    return 
                }
            },


            /**
              *
              */
            setTickerNodes(data) {
                this.tickerNodesAll = JSON.parse(JSON.stringify(data))
                this.tickerNodesFiltered = JSON.parse(JSON.stringify(data))
            },

            /**
              * Reakce na klik mimo u tickeru
              */
            hideTickerTree() {
                this.tickerOpened = false
            },

            /**
              * Reakce na klik mimo u actions
              */
            hideActionTree() {
                this.actionOpened = false
            },

            /**
              * Split stringu podle operatoru
              * - uvazuje vnoreni v zavorkach
              */
            bSplit(s, operator) {
                var bCount = 0
                var chunk = ""
                var out = []

                // korekce pocatecniho minunu
                // dementni zadani, ale i tak treba sledovat
                var str = JSON.parse(JSON.stringify(s)) // bezpecnejsi mit to pouze lokalne a navic neprepisuje zadani v inputu
                if (str[0] == "-" && str.search(/^\-[0-9\.]{1,}\*/) == -1 && str.match(/^\-[0-9\.]{1,}$/) == null) {
                    str = "-1*" + str.substring(1, str.length)
                }

                for (var i in str) {
                    // pocitadlo vnoreni zavorek
                    if (str[i] == "(") {bCount += 1}
                    if (str[i] == ")") {bCount -= 1}

                    if (i == 0 && str[i] == operator) {
                        chunk += str[i]
                        continue
                    }

                    // akumulace chunks dokud nepotka operator mimo zavorky
                    if (bCount == 0 && str[i] == operator) {
                        out.push(chunk)
                        chunk = ""
                    } else {
                        chunk += str[i]
                    }
                }


                // zbyvajici chunk
                if (chunk.length > 0) {
                    out.push(chunk)
                }

                return bCount == 0 ? out : []
            },

            /**
              * Parsovani obsahu tickeru na strom opraci podle priotit
              */
            parse(str, dkey) {
                // split podle opratoru
                // priorita je obracenem poradi protoze se postupuje "dovnitr"
                for (var op in this.operators) {
                    // split 
                    // bere v potaz i zavorky
                    var tmp = this.bSplit(str, op)

                    // pokud vice jak jeden tak doslo ke splitu
                    // "pamatuje" si posledni pouzity operator
                    if (tmp.length > 1) {break}
                }

                // cele uzavrene v zavorkach?
                // odstranit krajni zavorky a znovu samo na sebe
                if (str[0] == "(" && str[str.length-1] == ")" && tmp.length == 1){
                    return this.parse(str.substring(1, str.length-1), dkey)
                } 

                // obsahuje dale parsovatelne substringy
                if (tmp.length > 1) {
                    // linky pro data
                    var nodes = {}
                    for (var i in tmp) {
                        nodes[dkey+i] = this.parse(tmp[i], dkey+i)
                    }

                    // link operatoru
                    nodes[dkey] = {dkey: dkey, request: {dkeys: Object.keys(nodes), value: op}, type: "operator"}

                    return nodes
                }

                var type = tmp[0].match(/^[\-\+]?[0-9\.]{1,}$/) != null ? "scalar" : "data"
                return {dkey: dkey, request: type == "data" ? tmp[0] : parseFloat(tmp[0]), type: type}
            },

            /**
              * Prevedeni stromu na retezec operaci
              */
            serialize(nodes, baseAction) {
                var sub = []
                for (var dkey in nodes) {
                    if ("request" in nodes[dkey]) { // je node
                        sub.push({
                            action: (nodes[dkey].type == "operator") ? this.operators[nodes[dkey].request.value] : ((nodes[dkey].type == "data") ? baseAction : "Scalar"), 
                            dkey: dkey, 
                            request: (nodes[dkey].type == "operator") ? {dkeys: nodes[dkey].request.dkeys} : nodes[dkey].request // value je uz zbytecna
                        })
                    } else { // obsahuje dalsi nodes
                        sub = sub.concat(this.serialize(nodes[dkey], baseAction))
                    }
                }

                return sub
            },

            /**
              * Je string tvar Futures?
              */
            isFutures(str) {
                return str.match(/^[\-\+]?[A-Z0-9]{1,3}[FGHJKMNQUVXZ]{1}[0-9]{2}$/g)
            },

            /**
              * Je string tvar historical?
              */
            isHistorical(str) {
                return str.match(/^[\-\+]?[A-Z0-9]{1,3}[0-9]{1,2}$/g)
            },

            /**
              * Typ podkladovych dat podle request
              * Historical vs. Futures
              */
            getDataType(request) {
                if (this.isFutures(request)) {return "Futures"} 
                if (this.isHistorical(request)) {return "Historical"}
                return undefined // aby to bylo jasny
            },

            /**
              * Z requestu vytahne komoditu
              */
            getCommodity(request) {
                if (this.isFutures(request)) {return request.substring(0, request.length-3)} 
                if (this.isHistorical(request)) {return request.substring(0, request.length-1)}
                return undefined
            },

            /**
              * Overeni spravnych kombinaci ve stromu operaci
              * a sestaveni seznamu vsech obsazenych komodit
              */
            probe(nodes, out) {
                for (var dkey in nodes) {
                    if ("request" in nodes[dkey]) {
                        if (["operator", "scalar"].includes(nodes[dkey].type)) {continue} // u operatoru a cisel je to fuk

                        // datovy typ aktualniho requestu
                        // Historical vs. Futures
                        var dtype = this.getDataType(nodes[dkey].request)
                        out.dtype = out.dtype === undefined ? dtype : out.dtype
                        out.valid = out.dtype == dtype

                        // doplneni seznamu pritomnych komodit
                        var comm = this.getCommodity(nodes[dkey].request)
                        if (comm !== undefined && !out.comms.includes(comm)) {
                            out.comms.push(comm)
                        }

                        // pocet datovych "promennych"
                        out.dlist.push(nodes[dkey].request)
                    } else {
                        out = this.probe(nodes[dkey], out)
                    }
                }

                return out
            },

            /**
              * Zakazat zobrazeni vsech akci v action selectu
              */
            disableAllActions() {
                for (var gKey in this.actions) {
                    for (var i in this.actions[gKey]) {
                        this.actions[gKey][i].enabled = false
                    }
                }
            },

            /**
              * "Ukazatel" povoleni cele skupiny
              * pokud alespon jeden je enabled == true
              */
            groupEnabled(gKey) {
                /*
                if (["cot", "ts"].includes(gKey)) {
                    return this.actions[gKey].length > 0
                }*/

                for (var i in this.actions[gKey]) {
                    if (this.actions[gKey][i].enabled) {return true}
                }

                return false
            },

            /**
              * Test povoleni konkretni akce podle id
              */
            actionEnabled(id) {
                for (var gKey in this.actions) {
                    for (var i in this.actions[gKey]) {
                        if (this.actions[gKey][i].id != id) {continue}
                        return this.actions[gKey][i].enabled
                    }
                }

                return false
            },

            /**
              * Test jestli seznam komodit obsahuje pouze zrniny
              */
            isGrainsOnly(comms) {
                for (var i in comms) {
                    if (!["ZC", "ZS", "ZW", "ZL", "ZM", "KE", "ZR", "ZO"].includes(comms[i])) {return false}
                }
                return true
            },

            /**
              * Rebuild zobrazeneho obsahu action selectu podle stavovenych podminek
              */
            buildActions(pdata) {
                // nejdriv vzdy vsechno zakazat a vycistit
                this.disableAllActions()
                this.actions.ts = []
                this.actions.cot = []

                // neurcena kombinace napr. jeste nedokonceny vstup
                if (pdata.dtype === undefined || !pdata.valid) {
                    this.actionAny = false
                    this.actionOpened = false
                    return false
                }

                // typ zobrazeni v action selectu
                // historical spread je stejny jako historical
                var atype = (pdata.dtype == "Historical") ? "historical" : ((pdata.dlist.length > 1) ? "spread" : "futures")

                // povolit/zakazat akce
                // skupiny Price
                for (var gKey in this.actions) {
                    // TS a COT jsou buildovane dynamicky podle obsahu
                    if (["cot", "ts"].includes(gKey)) {continue}

                    for (var i in this.actions[gKey]) {
                        this.actions[gKey][i].enabled = this.actionLookup[atype].includes(this.actions[gKey][i].id)
                    }
                }

                // build TS a COT akci
                for (var i in pdata.comms) {
                    // kvuli potenctialni negaci prvniho
                    var request = (pdata.comms[i].includes("-") || pdata.comms[i].includes("+")) ? pdata.comms[i].substring(1, pdata.comms[i].length) : pdata.comms[i]

                    // TS
                    this.actions.ts.push({id: "ts"+request+1 ,base: undefined, enabled: true, functionId: "termStructure", name: request + " - Term structure", trans: ["TermStructureHistorical"], request: request})
                    
                    //COT
                    this.actions.cot.push({id: "cot"+request+1 ,base: "Price", enabled: true, name: request + " - Net positions", functionId: "cotNetPositions", trans: ["Cot"], request: request})
                    this.actions.cot.push({id: "cot"+request+2 ,base: "Price", enabled: true, name: request + " - Net positions index", functionId: "cotNetPositionsIndex", trans: ["Cot"], request: request})
                    this.actions.cot.push({id: "cot"+request+3 ,base: "Price", enabled: true, name: request + " - Net traders", functionId: "cotNetTraders", trans: ["Cot"], request: request})
                    this.actions.cot.push({id: "cot"+request+4 ,base: "Price", enabled: true, name: request + " - Net traders index", functionId: "cotNetTradersIndex", trans: ["Cot"], request: request})
                    this.actions.cot.push({id: "cot"+request+5 ,base: "Price", enabled: true, name: request + " - Net concentration %", functionId: "cotNetConcentration", trans: ["Cot"], request: request})
                    this.actions.cot.push({id: "cot"+request+6 ,base: "Price", enabled: true, name: request + " - Net concentration index", functionId: "cotNetConcentrationIndex", trans: ["Cot"], request: request})
                }

                // obsahuje "alespon neco"
                this.actionAny = true

                // prirazeni dostupne old akce pokud i tech je obsahem selectu
                this.action = (this.actionOld !== null && this.actionEnabled(this.actionOld.id)) ? this.actionOld : null
                
                return true
            },

            /**
              * Doplneni chainu o pripadne "transformace"
              * u nekterych akci v podstate je jen "transformace" (napr. COT)
              */
            addTransformationChain(chain, pdata) {
                // nema transformace
                if (this.action.trans === undefined) {return chain}

                // pridat vsechny transformace
                if (this.action.trans[0] == "Volume") { // u Volume za kazdou price jedno volume
                    var tmp = []
                    for (var i in chain) {
                        var link = chain[i]
                        tmp.push(link)
                        if (link.action == "PriceFutures") {
                            link = JSON.parse(JSON.stringify(link))
                            link.action = "Volume"
                            link.request = {dkeys: [link.dkey]}
                            tmp.push(link)
                        }
                    }
                    chain = tmp
                } else { // vsechny ostatni jednoduse po jednom
                    for (var i in this.action.trans) {
                        var tmp = {action: this.action.trans[i], dkey: "result", request: {dkeys: ["result"]}}

                        // nektere (napr. Average) request nemusi obsahovat protoze ho nepotrebuji
                        if (this.action.request !== undefined) {
                            tmp.request.request = this.action.request // "request" pro transformaci
                        }

                        // Aggregate pouze pro spready
                        if (this.action.trans[i] == "Aggregate" && pdata.dlist.length <= 1 && this.action.id != "price_con" && this.action.id != "price_hist") {
                            continue
                        }

                        // Price continuous pouze spready/pomery konkretnich kontraktu
                        if (this.action.trans[i] == "PriceContinuous" && pdata.dtype == "Historical") {
                            continue
                        }

                        chain.push(tmp)
                    }
                }

                return chain
            },

            /**
              * Filtrovani obsahu naseptavace podle obsahu tickeru
              */
            filter(nodes, val) {
                var out = []
                for (var i in nodes) {
                    // cele nodes vkladat profiltrovane pokud neco vubec obsahuji
                    if (nodes[i]["nodes"] !== undefined) {
                        // rekurzivni volani pro dalsi uroven
                        nodes[i]["nodes"] = this.filter(nodes[i]["nodes"], val)

                        // zbylo neco? >> vlozit
                        if (nodes[i]["nodes"].length > 0) {
                            out.push(nodes[i])
                        }
                    } else {
                        // zde prohiha samotna filtrace nejnizzsi urovne nodes podle value
                        //if (nodes[i].value.includes(val)) { // kdekoliv ve stringu
                        if (nodes[i].value.startsWith(val)) { // pouze zacatek stringu
                            out.push(nodes[i])
                        }
                    }
                }
                return out
            },

            /**
              * Hlavni funkce parsovani
              */
            run(e) {
                // pozice kursoru na zacatku zpracovani
                var cursorPosition = (e === undefined) ? undefined : parseInt(e.target.selectionStart)

                // 
                this.robj = null

                // upravy/opravy zadani
                if (this.ticker === null || this.ticker === undefined) {return}
                var str = this.ticker.replace(/ /g, "") // bez mezer
                str = str.replace(/,/g, ".") // desetinne carky na tecky
                str = str.toUpperCase() // vsechno velkymi pismeny

                // prepsani upraveneho obsahu tickeru
                // se zachovanim pozice cursoru
                if (e !== undefined) {
                    var self = this;
                    (function(e) {
                        self.ticker = str // zpet prepsat na obsah tickeru
                        return new Promise((resolve) => {resolve(e)})
                    })(e).then(function(e) {
                        if (e.target.selectionStart != cursorPosition) {
                            self.$refs.ticker.setSelectionRange(cursorPosition, cursorPosition)
                        }
                    })
                }

                // reakce naseptavace
                // pouze cokoliv za poslednim operatorem
                var test = str.split(/[\s\-/+*()]+/).slice(-1)[0]
                this.tickerOpened = true
                //this.isOperator = this.isFutures(test) || this.isHistorical(test)
                this.isOperator = this.isFutures(test)
                if (!this.isOperator) {
                    this.tickerNodesOpened = test.length > 0
                    this.tickerNodesFiltered = this.filter(JSON.parse(JSON.stringify(this.tickerNodesAll)), test)
                }

                // parsovani na strom operaci
                //var nodes = {"result": this.parse(str, "result")}
                try {
                    var nodes = {"result": this.parse(str, "result")}
                } catch(err) {
                    this.action = null
                    this.buildActions({})
                    console.log("Invalid math expression!")
                    return
                }

                // overeni spravnosti zadane kombinace
                // a zaroven vytazeni vsech obsazenych komodit
                var pdata = this.probe(nodes, {comms: [], dtype: undefined, dlist:[], valid: false})
                pdata.valid &= pdata.comms.length > 0 // kratke neuplne dotazy

                // rebuild obsahu akcniho selectu
                if (!this.buildActions(pdata)) {return}

                // neproveditelny dotaz
                // napr. kvuli nesmyslene kombinaci dat futures vs. historical nebo neuplnym tickerum
                if (!pdata.valid || this.action == null) {return}

                // sestaveni chainu
                // pouze u dotazu "s vypoctem" (tzn. ne COT, TS apod.) serializace nodes
                var chain = (this.action.base !== undefined) ? this.serialize(nodes, this.action.base + pdata.dtype) : []

                // pridat transformacni link(y)
                // pokud nema neprida se nic
                chain = this.addTransformationChain(chain, pdata)

                // sestaveni celeho robj
                this.robj = {
                    raw: this.ticker,
                    params: this.getParams(pdata),
                    functionId: this.action.functionId,
                    targetId: this.targetId,
                    chain: chain
                }

                return this.robj
            },

            /**
              * Sestavi dict vsech potrebnych dalsich obecnych parametru dotazu
              * behem zpracovani je primo pristupny v robj vsem akcim
              */
            getParams(pdata) {
                // urceni potrebneho poctu let v sezonalite
                var n
                switch(this.action.functionId) {
                    case "histogram2":
                        n = 70
                        break
                    case "PriceContinuous":
                        n = 70
                        break
                    case "seasonalityAverages":
                        n = 31
                        break
                    case "seasonalityMonth":
                        n = 31
                        break
                    case "seasonalityStacked":
                        n = 10
                        break
                    default:
                        n = 16
                }

                // sestaveny params dict
                return {
                    dlist: pdata.dlist, // list vsech datovych node v dotazu
                    dtype: pdata.dtype, // typ podkladovych dat futures vs. historical
                    comms: pdata.comms, // list vsech komodit v dotazu
                    alg: this.alg, // zvoleny alg. pro sestaveni historical
                    n: n, // pocet let v sezonalite
                    time: this.time / 1000, // podle datepcikeru
                    date: this.date,
                    hfield: (pdata.dtype == "Historical") ? "close" : "value0", // sloupec urceny pro histogram
                    agg: (this.action.id == "price_con" || pdata.dlist.length > 1) ? "single" : "multi", // volba zpusobu agregace 
                    interpolate: this.action.id == "seasonality_stacked",
                    offset: (this.off.value !== undefined && this.off.value !== null) ? this.off.value : 0
                }
            },

            /**
              * Zahajeni vykresleni
              * signal pro nadrazenou komponentu charts2
              */
            onChartClicked() {
                // neprihlaseny uzivatel
                if (!this.loggedIn) {return}

                var robj = this.run()
                if (robj !== undefined) {
                    this.$emit("start", JSON.parse(JSON.stringify(robj)))
                }
            },

            /**
              * Reakce na volbu akce
              */
            onActionSelect(value) {
                this.action = value
                this.actionOld = JSON.parse(JSON.stringify(value))
                this.actionOpened = false
                this.run()
                
                // zobrazeni / skryti volitelnych inputu
                //this.rollOptional(this.hasOptionalInputs && this.actionAny)
            },

            /**
              * Realce na volbu alg
              */
            onAlgSelect(value) {
                this.alg = value
                this.algOpened = false
            },

            /**
              * Reakce na vyber tickeru z naseptavace
              */
            onTickerSelect(value) {
                this.ticker = (this.ticker === null) ? value : (this.ticker.match(/[a-zA-Z0-9]+$/g) ? this.ticker.replace(/[a-zA-Z0-9]+$/g, value) : this.ticker+value)

                // prepnout na operatory
                this.isOperator = true

                // focus
                this.$refs["ticker"].focus()

                // opozdene rozbaleni nabidky
                var self = this
                setTimeout(function() {
                    self.tickerOpened = true
                }, 25)

                this.run()
            },

            /**
              * Reakce na vyber operatoru z naseptavace
              */
            onOperatorSelect(value) {
                this.isOperator = false
                this.tickerOpened = false
                this.tickerNodesFiltered = JSON.parse(JSON.stringify(this.tickerNodesAll))

                this.ticker += value

                // focus
                this.$refs["ticker"].focus()

                var self = this
                setTimeout(function() {
                    self.tickerOpened = true
                }, 25)
                
                this.run()
            },

            /**
              * Reakce na zavreni modalniho okna
              */
            onClose() {
                this.$emit("close", this.targetId)
            },

            /**
              * "Vyrolovani" volitelnych inputu
              */
            rollOptional(open) {
                this.$refs.optionalBox.style["max-width"] = (open ? 500 : 0) + "px"
            },

            /**
              * Externi nastaveni hodnoty tickeru
              */
            setTicker(value) {
                this.ticker = value
            },

            /**
              * Externi nastaveni hodnoty akce podle jejiho id
              */
            setActionById(actionId) {
                for (var i in this.actions) {
                    for (var j in this.actions[i]) {
                        if (this.actions[i][j].id == actionId) {
                            this.onActionSelect(this.actions[i][j])
                        }
                    }
                }
                //this.rollOptional(this.hasOptionalInputs && this.actionAny)
            },

            /**
              * Externi nastaveni hodnoty datepickeru
              */
            setDate(dstring) {
                // korekce timezone offu
                // protoze js je proste pica
                var d = new Date(dstring.substring(0, 10)+"T00:00:00Z")
                if (d.getTimezoneOffset() > 0) {
                    d = new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000)
                }

                this.time = d.getTime()
                this.$forceUpdate()
            },

            /**
              * Externi nastaveni honodty alg
              */
            setAlg(value) {
                this.onAlgSelect(value)
            }
        },
    }
</script>

<style>
    div.mx-datepicker {
        font-family: Arial;
    }

    @media (min-width: 1080px) {
        div.mx-datepicker {
            width: 252px;
        }
    }

    @media (max-width: 1079px) {
        div.mx-datepicker  {
            width:  calc((100vw - 115px) * 0.17); 
        }
    }

    span.mx-input-append {
        display: table-cell;
        font-size: 22px;
        padding: 3px 0 0 0;
        color: black;
    }

    input.dateInput {
        border: 1px solid #ddd;
        border-radius: 5px;
        height: 37px;
        width: 100%;
        padding: 0 0 0 10px;
    }


    div.mx-datepicker-popup {
        border-radius: 5px;
        z-index: 2000000000000;
    }

    div.mx-datepicker-popup table,
    div.mx-calendar-header {
        color: black;
        font-family: Arial;
    }
</style>

<style scoped>
    div.parserBox {
        background-color: #d9f2f9;
        padding: 5px 0 12px 0;
        font-family: Arial;
    }

    div.parserBox.modal {
        background-color: white;
    }

    table.inputTable {
        border-collapse: collapse;
        padding: 0;
        float: left;
    }

    table.inputTable > tr > td {
        font-size: 14px;
        padding: 0 0 3px 8px;
    }

    div.tickerInput input, div.chartInputBox input[type="text"]{
        height: 35px;
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 0 0 0 10px;
    }

    div.actionInput, div.algInput {
        background-color: white;
        height: 35px;
        padding: 0 0 0 10px;
        border: 1px solid #dddddd;
        border-radius: 5px;
        cursor: pointer;
    }

    @media (min-width: 1080px) {
        .fadeRoll-enter-active,
        .fadeRoll-leave-active,
        .optionalBox{
            opacity: 1.0;
            transition: all 0.2s;
            width: 240px;
        }

        div.tickerInput input,
        div.actionInput,
        div.algInput,
        div.chartInputBox input[type="text"]{
            width: 240px;
        }
    }

    @media (max-width: 1079px) {
        .fadeRoll-enter-active,
        .fadeRoll-leave-active,
        .optionalBox {
            opacity: 1.0;
            transition: all 0.2s;
            width:  calc((100vw - 150px - 20px) * 0.17); 
        }

        div.optionalBox {
            margin-right: 10px;
        }

        div.tickerInput input,
        div.actionInput, 
        div.algInput,
        div.chartInputBox input[type="text"]{
            width:  calc((100vw - 150px - 20px) * 0.17); 
        }
    }

    .fadeRoll-enter,
    .fadeRoll-leave-to{
        opacity: 0.0;
        transition: all 0.2s;
        width: 0px;
    }

    .transparent {
        opacity: 0.0;
    }

    div.actionValue, div.algValue {
        margin-top: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    div.actionPlaceholder {
        color: #777777;
    }

    div.actionTree, div.algTree, div.tickerTree {
        position: absolute;
        padding: 12px 0 12px 0px;
        margin: 1px 0 0 0;
        min-width: 250px;
        border: 1px solid #ddd;
        z-index: 1000;
        border-radius: 5px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px 0px;
    }

    div.algTree {
        margin-left: 2px;
    }

    div.chartInputBox > input, div.tickerInput > input {
        outline: none;
    }

    div.chartInputBox > input.error {
        color: red
    }

    div.modal-backdrop {
        position: fixed;
        z-index: 1000000;
        width: 100%;

        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        background-color: rgba(0, 0, 0, 0.75);
        justify-content: center;
        align-items: center;
        display: flex;
    }

    div.modal-box {
        background-color: white;
        box-shadow: 2px 2px 20px 1px;
    }

    div.modal-header {
        height: 40px;
        line-height: 40px;
        background-color: #d9f2f9;
    }

    div.modal-name {
        font-size: 15px;
        font-weight: 700;
        margin-left: 10px;
        float: left;
    }

    div.modal-close-wrapper {
        float: right;
        width: 30px;
        text-align: center;
        cursor: pointer;
    }

    div.modal-body {
        padding: 10px 10px 10px 10px;
    }

    div.hidden {
        display: none;
    }

    div.optionalBox {
    }

    div.optionalBox div {
        max-width: inherit;
    }

    tr.toolsOut {
        display: visible;
    }

    tr.toolsIn {
        display: none;
    }

    div.toolsBox {
        border: 1px solid red;
        height: 30px;
    }

    td.hidden-td {
        padding-left: 0 !important;
    }

    div.errorBox {
        color: red;
        font-style: italic;
        font-size: 11px;
        height: 13px;
        margin-left: 5px;
    }
</style>

