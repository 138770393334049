// import AmCharts from 'amcharts3';
// import AmStock from 'amstock3/amcharts/amstock';
// import AmSerial from 'amcharts3/amcharts/serial';

import defaultCategoryAxesSettings from './commonFunctions.js'
import myCategoryLabel from './commonFunctions.js'
import defaultPanelSettings from './commonFunctions.js'
import defaultBalloon from './commonFunctions.js'
import isTouch from './commonFunctions.js'

export default {
    mixins:[defaultCategoryAxesSettings, myCategoryLabel, defaultPanelSettings, defaultBalloon, isTouch],
    methods: {
        getGraphs(payload) {

            let gr = []
            let labelsLength;
            let y;

            this.labelsLength = payload.labels.length

            if (payload.labels.length == 7) {
                y = [5, 15, 30];
            } else if (payload.labels.length == 5) {
                y = [5, 15];
            } else if (payload.labels.length == 3) {
                y = [5];
            } else {
                y = [];
            }

            var colors = ["#0000ff", "#ff0000", "#228b22", "#ffd600"] 

            // mad grafy
            for (let i = this.labelsLength; i >= 1; i--) {
                gr.push({
                    id: "mad_"+y[i-1]+"_from",
                    useDataSetColors: false,
                    fontSize: 14,
                    labelOffset: 12,
                    valueField: "mad_"+y[i-1]+"_l",
                    lineColor: colors[i],
                    lineThickness: 2,
                    showBalloon: false,
                    lineAlpha: 0.0,
                    visibleInLegend:  false,
                    hidden: true
                })

                gr.push({
                    id: "mad_"+y[i-1]+"_to",
                    useDataSetColors: false,
                    fontSize: 14,
                    labelOffset: 12,
                    valueField: "mad_"+y[i-1]+"_h",
                    lineColor: colors[i],
                    lineThickness: 2,
                    lineAlpha: 0.0,
                    showBalloon: false,
                    fillAlphas: 0.2,
                    fillToGraph: "mad_"+y[i-1]+"_from",
                    legendValueText: "[[mad_"+y[i-1]+"]]",
                    title: payload.labels[i+this.labelsLength],
                    hidden: true
                })
            }

            // price grafy
            for (let i = this.labelsLength; i >= 0; i--) {
                gr.push({
                    id: (i > 0) ? y[i-1] : 0,
                    useDataSetColors: false,
                    balloonFunction: function(item) {
                        var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                        return  item.graph.title + ": " + value
                    },
                    fontSize: 14,
                    showBalloon: (i > 0) ? false : true,
                    labelOffset: 12,
                    valueField: (i > 0) ? "avg_"+y[i-1] : "value0",
                    lineColor: colors[i],
                    lineThickness: 2,
                    title: payload.labels[i],
                })
            }

            return gr
        },
        seasonalityAverages(payload) {
            var chart = window.AmCharts.makeChart(payload.targetId, {
                // ------------
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                marginLeft: 1800,
                dataSets: [{
                    fieldMappings: [
                        {fromField: "date", toField: "date"},
                        {fromField: "value0", toField: "value0"},
                        {fromField: "avg_5", toField: "avg_5"},
                        {fromField: "avg_15", toField: "avg_15"},
                        {fromField: "avg_30", toField: "avg_30"},
                    ],
                    dataProvider: payload.data,
                    categoryField: "date"
                }],
                panels: [{
                    stockGraphs: this.getGraphs(payload),
                    valueAxes : [{
                        position: "right",
                        fontSize: 13,
                        glc: payload.misc.glc
                    }],
                    categoryAxis: {
                        parseDates: true,
                        equalSpacing: true,
                        labelFunction: this.myCategoryLabel
                    },
                    legend: {
                        position : "top",
                        fontSize: 14,
                        marginLeft : this.defaultPanelSettings(payload.functionId).marginLeft,
                        valueWidth: 85,
                        reversedOrder: true,
                        valueFunction: function(item) {
                            if (item.values === undefined) {return ""}
                            var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                            return (isNaN(value)) ? "" : value
                        },
                        markerBorderAlpha: 0
                    },
                }],
                panelsSettings :  this.defaultPanelSettings(payload.functionId),
                chartCursorSettings: {
                    categoryBalloonEnabled: true,
                    //pan : is_touch_device(), !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                    pan: this.isTouch(),
                    graphBulletSize : 1.5,
                    valueLineEnabled: true,
                    valueBalloonsEnabled: true,
                    valueLineBalloonEnabled: true
                },
                valueAxesSettings : {
                    inside : false,
                    dashLength: 0
                },
                categoryAxesSettings : this.defaultCategoryAxesSettings(payload.functionId),
                chartScrollbarSettings : {enabled: false},
                periodSelector: {inputFieldsEnabled: false},
                balloon : this.defaultBalloon(payload.functionId),
            })

            return chart
        } 
    }
}
