<template>
    <div class="form-container">
        <div class="form-wrapper" v-show="step == 1">
            <div class="form-msg">
                {{form_msg}}
            </div>
            <table class="form-table">
                <tr>
                    <td class="td-label">
                        E-mail
                    </td>
                    <td class="td-error">
                        <span v-show="!email.valid">{{email.error}}</span>
                    </td>
                </tr>

                <tr>
                    <td class="td-input" colspan=2 :class="{'error': !email.valid}">
                        <input type="text" autocomplete="email" v-model="email.value" @change="isValid_1" v-on:keyup.enter="submit_email"/>
                    </td>
                </tr>

                <tr>
                    <td class="td-submit" colspan=2>
                        <input type="button" class="button-blue" value="Submit" @click="submit_email" />
                    </td>
                </tr>
            </table>
        </div>

        <div class="form-wrapper" v-show="step == 3">
            <div class="form-msg" style="margin-top: 50px">
                {{form_msg}}
            </div>
        </div>
    </div>
</template>

<script>
    import {dSource} from './vars.js';
    import loader from './loader.vue';
    import axios from 'axios';

    export default {
        name: 'ForgotenPass',
        components: {loader},
        data() {
            return {
                
                dSource: dSource,

                email: {
                    value: null,
                    valid: true,
                    error: null
                },
                vcode: {
                    value: null,
                    valid: true,
                    error: null
                },
                password: {
                    value: null,
                    valid: true,
                    error: null
                },
                password_again: {
                    value: null,
                    valid: true,
                    error: null
                },


                loading: false,

                step: 1
            }
        },
        methods: {
            init() {
                this.step = 1

                this.email.value = null
                this.email.valid = true
                this.password.value = null
                this.password.valid = true
                this.password_again.value = null
                this.password_again.valid = true
                this.vcode.value = null
                this.vcode.valid = true
            },

            isValid_1() {
                // validace email
                this.email.valid = true
                this.email.valid &= /^.{1,1000}@.{1,1000}\.[a-zA-Z0-9]{1,20}$/.test(this.email.value)
                if (!this.email.valid) {
                    this.email.error = "Incorrect e-mail address"
                }

                return this.email.valid
            },

            isValid_2() {
                this.vcode.valid = this.vcode.value != null && this.vcode.value.length > 0
                if (!this.vcode.valid) {
                    this.vcode.error = "No security code!"
                }

                this.password.valid = this.password.value != null && this.password.value.length > 0
                if (!this.password.valid) {
                    this.password.error = "No password!"
                }

                this.password_again.valid = this.password_again.value != null && this.password_again.value.length > 0
                if (!this.password_again.valid) {
                    this.password_again.error = "No password!"
                }

                if (this.password.valid && this.password_again.valid && this.vcode.valid) {
                    this.password_again.valid = this.password.value == this.password_again.value
                    this.password.valid = this.password_again.valid
                    this.password.error = null
                    this.password_again.error = "Diferent passwords!"
                }

                return this.password.valid && this.password_again.valid && this.vcode.valid
            },

            submit_email() {
                if (!this.isValid_1()) {
                    return
                }

                var robj = {
                    rkeys: ["result"],
                    chain: [
                        {action: "ForgotenPass", i:0, dkey:"result", request:{method:"forgoten", data:{email: this.email.value}}}
                    ]
                }
                this.step = 3
                axios.post(this.dSource, {robj: robj}).then(payload => console.log(payload))
            },

            generalError(udata) {
                this.email.valid = false
                this.password.valid = false

                this.email.error = "Wrong e-mail"
                this.password.error = "or password"

                this.email.value = udata["email"]
                this.password.value = udata["password"]
            },

            forgoten() {
                this.$emit("forgoten")
            },

            toLogin() {
                this.$emit("toLogin")
            }
        },
        computed: {
            form_msg() {
                if (this.step == 1) {
                    return "Please enter the email address associated with your SpreadCharts account. You will receive an email with the password reset link."
                }

                if (this.step == 3) {
                    return "The password reset link has been sent to your email address. Please also check your spam folder."
                }
            }
        }
    }
</script>

<style scoped>
    div.form-container {
    }

    div.form-wrapper {
    }

    div.form-msg {
        font-size: 15px;
        margin-bottom: 10px;
        color: #555555;
        width: 250px;
    }

    td.td-label {
        color: #888;
        font-size: 13px;
        padding: 0px;
    }

    td.td-input {
        padding: 0px 0px 10px 0px;
    }

    span.forgoten-clicker {
        color: #555555;
        text-decoration: none;
        margin-left: 2px;
        cursor: pointer;
    }

    td.td-input > a:hover {
        text-decoration: underline;
    }


    td.td-input > input[type="text"],
    td.td-input > input[type="password"] {
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 5px 0px 5px 3px;
        font-size: 15px;
        width: 250px;
    }

    td.error > input[type="text"],
    td.error > input[type="password"]{
        border: 1px solid red;
    }

    td.td-error {
        color: red;
        font-size: 13px;
        text-align: right;
        padding: 0px;
    }

    td.td-submit {
        text-align: right;
        padding: 0px;
    }

    td.td-submit > input[type="button"] {
        border: 0px;
        font-size: 15px;
        padding: 5px 10px 5px 10px;
        margin: 0px 0px 0px 0px;
    }

    div.loader-wrapper {
        height: 160px;
        width: 250px;
        padding-top: 100px;
    }
</style>
