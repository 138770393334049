<template>
    <div class="container" id="watchlist_placeholder">
        <table class="row-table">
            <tr>
                <td class="check"></td>
                <td>{{name}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'Watchlist2Placeholder',
        data() {
            return {
                name: "Default placeholder"
            }
        },
        methods: {
            setName(name) {
                this.name = name
            }
        }
    }
</script>

<style scoped>
    div.container {
        border-bottom: 1px solid #cdcdcd;
        background-color: #98fb98;
        padding: 12px 5px 12px 5px;
        cursor: grabbing;
        text-align: left;
    }

    table.row-table {
        width: 100%;
        border-collapse: collapse;
    }

    table > tr > td {
        padding: 5px;
    }

    td.check {
        width: 20px;
    }
</style>
