<template>
     <div class="container">
        <div v-show="state == 0">
            <table class="form-table">
                <tr>
                    <td class="name">
                        <input type="text" v-model="name" />
                    </td>
                    <td>
                        <div ref="tagSelectorContainer" class="tagSelectorContainer">
                        </div>
                    </td>
                    <td class="submit">
                        <input type="button" value="Apply" :class="{'button-green': valid, 'button-grey': !valid}" @click="submit"/>
                    </td>
                </tr>
            </table>
        </div>
        <div v-show="state == 1">
            <div class="msg">
                <div style="margin-bottom: 10px">
                    <div>The watchlist is limited to a max of 10 records in the free version.</div>
                    <div>Unlock more with the premium subscription.</div>
                </div>
                <div style="width: 90%; margin: auto">
                    <input type="button" class="button-lightred" value="Get premium" style="font-size: 16px" @click="toSubscriptions()"/>
                </div>
            </div>
        </div>
        <div v-show="state == 2">
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    import TagSelector from './TagSelector.vue'
    var TagSelectorClass = Vue.extend(TagSelector)

    export default {
        name: 'Watchlist2Adder',
        data() {
            return {
                state: 0, 
                done: false, 
                tagSelector: null,
                name: null,
                name_original: null,
                id: null,
                link: null,
                ux: null,
                y: null
            }
        },
        computed: {
            valid() {
                return this.name !== null && this.name !== undefined && this.name.length > 0
            },

            edit() {
                return this.name == this.name_original && this.link !== null && this.id !== null
            }
        },

        mounted() {
            // vytoreni tagSelectoru (vyber tagu)
            this.tagSelector = new TagSelectorClass()
            this.tagSelector.$mount()
            this.tagSelector.setEdit(true) // jakoze edit
            this.$refs.tagSelectorContainer.appendChild(this.tagSelector.$el)
        },

        methods: {
            toSubscriptions() {
                this.$emit("toSubscriptions")
            },

            setTags(tagBase, tagIds) {
                this.tagSelector.setTags(tagBase, tagIds)    
            },

            setName(value) {
                this.name_original = JSON.parse(JSON.stringify(value))
                this.name = value
            },

            setId(value) {
                this.id = value
            },

            setLink(value) {
                this.link = value
            },

            setUX(data) {
                this.ux = data
            },

            setY(value) {
                this.y = value
            },

            submit() {
                if (!this.valid) {return}

                this.ux.lists[0].name = this.name // jmeno listu podle zadaneho
                var data = {
                    tags: this.tagSelector.getSelected(),
                    name: this.name,
                    ux: this.ux,
                    y: this.y
                }

                if (this.edit){
                    data.id = this.id
                    data.link = this.link
                    this.done = true
                    this.$emit("edited", data)
                } else {
                    this.done = true
                    this.$emit("added", data)
                }
            }
        },
    }
</script>

<style scoped>
    div.tagSelectorContainer {
        text-align: center;
    }

    table.form-table td {
        text-align: center;
        padding: 5px;
    }

    td.name > input[type="text"]{
        border-radius: 5px;
        border: 1px solid #cdcdcd;
        padding: 8px 0 8px 3px;
        font-size: 15px;
        font-family: "Open Sans";
    }

    div.msg {
            text-align: center;
    }

</style>
