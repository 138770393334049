// import AmCharts from 'amcharts3';
// import AmStock from 'amstock3/amcharts/amstock';
// import AmSerial from 'amcharts3/amcharts/serial';

import defaultCategoryAxesSettings from './commonFunctions.js'
import myCategoryLabel from './commonFunctions.js'
import defaultPanelSettings from './commonFunctions.js'
import defaultBalloon from './commonFunctions.js'
import isTouch from './commonFunctions.js'

export default {
    mixins:[defaultCategoryAxesSettings, myCategoryLabel, defaultPanelSettings, defaultBalloon, isTouch],
    methods: {
        
        isWeekend(d) {
            d = new Date(d)
            return (d.getUTCDay() == 6 || d.getUTCDay() == 0)
        },

        signalCOT(payload) {
            var inSignals = payload.targetId.search("signal") > -1
            if (inSignals) {
                var targetId = payload.targetId + "_cot"
            } else {
                var targetId = payload.targetId
            }

            var ch = window.AmCharts.makeChart(targetId, {
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                dataSets: [{
                    fieldMappings: [
                        {fromField: "date", toField: "date"},
                        {fromField: "hedg_positions_index", toField: "hedg_positions_index"},
                        {fromField: "spec_positions_index", toField: "spec_positions_index"},
                    ],
                    dataProvider: payload.data,
                    categoryField: "date"
                }],
                categoryAxis: {
                    parseDates: true,
                    gridPosition: "middle",
                    showFirstLabel: false,
                    //labelFunction: this.myCategoryLabel,
                },
                panels: [{
                    title: "COT positions index - "+payload.misc.comms[0],
                    stockGraphs: [{
                        title: "Hedgers",
                        type: "line",
                        fillAlphas: 0.5,
                        useDataSetColors: false,
                        lineColor: "#db4c3c",
                        fillColors: "#db4c3c",
                        lineThickness: 2,
                        valueField: "hedg_positions_index",
                        balloonFunction: function(item) {
                            var value = parseFloat(item.values.value).toFixed(2);                
                            return  value
                        },
                        showBalloon: true
                    },{
                        title: "Speculators",
                        type: "line",
                        fillAlphas: 0.5,
                        useDataSetColors: false,
                        lineColor: "LimeGreen",
                        fillColors: "LimeGreen",
                        lineThickness: 2,
                        valueField: "spec_positions_index",
                        balloonFunction: function(item) {
                            var value = parseFloat(item.values.value).toFixed(2);                
                            return  value
                        },
                        showBalloon: true
                    }],
                    valueAxes : [{
                        position: "right",
                        fontSize: 13
                    }],
                    stockLegend: {
                        fontSize: 14,
                        valueFunction: function(item) {
                            if (item.values === undefined) {return ""}
                            var value = parseFloat(item.values.value).toFixed(2);                
                            return (isNaN(value)) ? "" : value
                        },
                    },
                    drawingIconsEnabled: false,
                    percentHeight: 100,
                    showCategoryAxis: !inSignals
                }],
                chartCursorSettings: {
                    valueBalloonsEnabled: true,
                    fontSize: 11,
                    graphBulletSize : 1.5,
                    valueLineEnabled: true,
                    valueLineBalloonEnabled: true,
                    pan: this.isTouch(),
                    zoomable: !inSignals
                },
                chartScrollbarSettings : {
                    enabled: false
                },
                valueAxesSettings : {
                    inside : false,
                    dashLength: 0
                },
                panelsSettings : this.defaultPanelSettings(payload.functionId),
                categoryAxesSettings : this.defaultCategoryAxesSettings(payload.functionId),
                periodSelector: {
                    position: "bottom",
                    inputFieldsEnabled: false,
                },
                balloon : this.defaultBalloon(payload.functionId),
                listeners: [{
                    event: "init",
                    method: function(e) {
                        e.chart.zoom(new Date(payload.misc.zoom.start), new Date(payload.misc.zoom.end))
                    } 
                }]
            })

            return ch
        },

        signalAverage(payload) {
            var inSignals = payload.targetId.search("signal") > -1
            if (inSignals) {
                var targetId = payload.targetId + "_avg"
            } else {
                var targetId = payload.targetId
            }

            var ch = window.AmCharts.makeChart(targetId, {
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                dataSets: [{
                    fieldMappings: [
                        {fromField: "date", toField: "date"},
                        {fromField: "value0", toField: "value0"},
                        {fromField: "avg_5", toField: "avg_5"},
                        {fromField: "avg_15", toField: "avg_15"},
                    ],
                    dataProvider: payload.data,
                    categoryField: "date"
                }],
                categoryAxis: {
                    parseDates: true,
                    gridPosition: "middle",
                    showFirstLabel: false,
                    //labelFunction: this.myCategoryLabel,
                },
                panels: [{
                    title: "Seasonality - averages",
                    stockGraphs: [{
                        title: "15y Avg.",
                        type: "line",
                        fillAlphas: 0.0,
                        useDataSetColors: false,
                        lineColor: "#228b22",
                        fillColors: "#228b22",

                        lineThickness: 2,
                        valueField: "avg_15",
                        balloonFunction: function(item) {
                            var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                            return  value
                        },
                        showBalloon: true
                    },{
                        title: "5y Avg.",
                        type: "line",
                        fillAlphas: 0.0,
                        useDataSetColors: false,
                        lineColor: "#ff0000",
                        fillColors: "#ff0000",
                        lineThickness: 2,
                        valueField: "avg_5",
                        balloonFunction: function(item) {
                            var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                            return  value
                        },
                        showBalloon: true
                    }, {
                        title: payload.raw,
                        type: "line",
                        fillAlphas: 0.0,
                        useDataSetColors: false,
                        lineColor: "#0000ff",
                        fillColors: "#0000ff",
                        lineThickness: 2,
                        valueField: "value0",
                        balloonFunction: function(item) {
                            var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                            return value
                        },
                        showBalloon: true
                    }],
                    valueAxes : [{
                        position: "right",
                        fontSize: 13
                    }],
                    /*
                    legend: {
                        position : "top",
                        fontSize: 14,
                        marginLeft : this.defaultPanelSettings(payload.functionId).marginLeft,
                        valueWidth: 85,
                        reversedOrder: true,
                        valueFunction: function(item, values){
                            if (values == " "){
                                return 0
                            }
                            return parseFloat(values).toFixed(2)
                        },
                        markerBorderAlpha: 0
                    },*/
                    stockLegend: {
                        fontSize: 14,
                        valueFunction: function(item) {
                            if (item.values === undefined) {return ""}
                            var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                            return (isNaN(value)) ? "" : value
                        },
                        reversedOrder: true
                    },
                    drawingIconsEnabled: false,
                    percentHeight: 100,
                    showCategoryAxis: !inSignals
                }],
                chartCursorSettings: {
                    valueBalloonsEnabled: true,
                    fontSize: 11,
                    graphBulletSize : 1.5,
                    valueLineEnabled: true,
                    valueLineBalloonEnabled: true,
                    pan: this.isTouch(),
                    zoomable: !inSignals
                },
                chartScrollbarSettings : {
                    enabled: false
                },
                valueAxesSettings : {
                    inside : false,
                    dashLength: 0
                },
                panelsSettings : this.defaultPanelSettings(payload.functionId),
                categoryAxesSettings : this.defaultCategoryAxesSettings(payload.functionId),
                periodSelector: {
                    position: "bottom",
                    inputFieldsEnabled: false,
                },
                balloon : this.defaultBalloon(payload.functionId),
                listeners: [{
                    event: "init",
                    method: function(e) {
                        e.chart.zoom(new Date(payload.misc.zoom.start), new Date(payload.misc.zoom.end))
                    } 
                }]
            })

            return ch
        },

        signalStrength(payload) {
            var inSignals = payload.targetId.search("signal") > -1
            if (inSignals) {
                var targetId = payload.targetId + "_strength"
            } else {
                var targetId = payload.targetId
            }
            var ch = window.AmCharts.makeChart(targetId, {
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                dataSets: [{
                    fieldMappings: [
                        {fromField: "date", toField: "date"},
                        {fromField: "prediction", toField: "prediction"}
                    ],
                    dataProvider: payload.data,
                    categoryField: "date"
                }],
                categoryAxis: {
                    parseDates: true,
                    gridPosition: "middle",
                    showFirstLabel: false,
                    //labelFunction: this.myCategoryLabel,
                },
                panels: [{
                    title: "AI trading model: "+ payload.misc.ticker,
                    stockGraphs: [{
                        title: "Signal strength",
                        type: "line",
                        fillAlphas: 0.0,
                        useDataSetColors: false,
                        lineColor: "#9400d3",
                        fillColors: "#9400d3",
                        lineThickness: 2,
                        valueField: "prediction",
                        balloonFunction: function(item) {
                            var value = parseFloat(item.values.value).toFixed(2);                
                            return  value
                        },
                        showBalloon: true
                    }],
                    valueAxes : [{
                        position: "right",
                        fontSize: 13
                    }],
                    stockLegend: {
                        fontSize: 14,
                        valueFunction: function(item) {
                            if (item.values === undefined) {return ""}
                            var value = parseFloat(item.values.value).toFixed(2);                
                            return (isNaN(value)) ? "" : value
                        },
                    },
                    drawingIconsEnabled: false,
                    percentHeight: 100
                
                }],
                chartCursorSettings: {
                    valueBalloonsEnabled: true,
                    fontSize: 11,
                    graphBulletSize : 1.5,
                    valueLineEnabled: true,
                    valueLineBalloonEnabled: true,
                    pan: this.isTouch(),
                    zoomable: !inSignals
                },
                chartScrollbarSettings : {
                    enabled: false
                },
                valueAxesSettings : {
                    inside : false,
                    dashLength: 0
                },
                panelsSettings : this.defaultPanelSettings(payload.functionId),
                categoryAxesSettings : this.defaultCategoryAxesSettings(payload.functionId),
                periodSelector: {
                    position: "bottom",
                    inputFieldsEnabled: false,
                },
                balloon : this.defaultBalloon(payload.functionId),
                listeners: [{
                    event: "init",
                    method: function(e) {
                        e.chart.zoom(new Date(payload.misc.zoom.start), new Date(payload.misc.zoom.end))
                    } 
                }]
            })

            return ch
        }
    }
}
