<template>
    <div class="tree-container">
        <div class="tree-label" :class="{'selected': selected}" :style="indent" @click="onClick">
            <i v-if="!opened && nodes != undefined" class="icons8-chevron-right-filled"></i>
            <i v-if="opened && nodes != undefined" class="icons8-chevron-down-filled"></i>
            <i v-if="nodes == undefined" class="fas fa-lg"></i>
            <div class="label-wrapper" > {{label}} </div>
        </div>
        <tree v-if="opened || open" 
            v-for="node in nodes"
            :nodes="node.nodes"
            :label="node.label"
            :depth="depth+1"
            :value="node.value"
            :key="node.id"
            :open="open && depth < 2"
            :selected="node.selected"
            @select="onSelect"
        ></tree>
    </div>
</template>

<script>
    export default {
        name: 'tree',
        props: ["label", "nodes", "depth", "value", "open", "onselect", "selected"],
        data() {
            return {
                opened: false
            }
        },
        computed: {
            indent() {
                return {"padding-left": `${this.depth * 20}px`}
            }
        }, 
        methods: {
            onClick() {
                this.opened = !this.opened
                if (this.value !== undefined) {
                    this.$emit("select", this.value)
                }
            },
            onSelect(value) {
                this.$emit("select", value)
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
    div.tree-container {
        font-family: 'Arial';
        /*font-family: Consolas,monaco,monospace;*/ 
        /*font-family: Helvetica Neue, Helvetica, Arial;*/
        /*color: #73879c;*/
        color: black;
    }

    div.tree-label {
        cursor: pointer;
        font-size: 15px;
    }

    div.tree-label:hover {
        color: #419dec;
    }

    div.tree-label > i {
        width: 10px;
        margin-left: 10px;
        font-size: 10px;
    }

    div.tree-label:hover > i {
    }

    div.tree-label:hover {
        background-color: #eaf8fe;
    }

    div.tree-label.selected {
        color: white;
        background-color: #1284e7;
    }

    div.label-wrapper {
        display: inline-block;
        vertical-align: middle;
        padding: 0 0 0 4px;
        margin: 2px 10px 3px 0;
    }
</style>
