import Vue from 'vue'
import App from './App.vue'

import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

import VueMoment from 'vue-moment'
Vue.use(VueMoment)

import VueResource from 'vue-resource'
Vue.use(VueResource)

//import Vue2TouchEvents from 'vue2-touch-events'
//Vue.use(Vue2TouchEvents)

import VueRouter from 'vue-router';
Vue.use(VueRouter)

Vue.config.productionTip = false
require('./style/tooltip.css')
require('./style/blockStyle.css')
require('./style/buttonStyle.css')
require('./style/icon_styles.css')
require("./style/flags/flag-icon.css")

import Home from "./components/Home.vue";
import Registration from "./components/Registration.vue";
import PasswordReset from "./components/PasswordReset";
import ContactForm2 from "./components/contactForm2.vue";
import Parser2 from "./components/Parser2.vue"

const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', component: Home},
        {path: '/login/', component: Home}, // pripad rouvnou aktivovaneho loginu
        {path: '/registration/:lang', component: Registration, props: true},
        {path: '/contact2/:lang', component: ContactForm2, props: true},
        {path: '/reset/:code', component: PasswordReset, props: true},
        {path: '/parser/', component: Parser2}, // pripad rouvnou aktivovaneho loginu
    ]
})

new Vue({
    router:router, 
    render: h => h(App)
}).$mount('#app')


