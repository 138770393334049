<template>
    <div class="container">

        <!--
        <div class="list-selection-container">
            <ul class="list-selection-wrapper">
                <li v-for="(list, index) in lists" class="list-selector" :class="{'active': list.active, 'disabled': list.disabled}" @click="activate(index)">
                    <div class="list-clicker">
                        <table class="list-head-table">
                            <tr>
                                <td>
                                    <div class="list-name" 
                                        @dblclick="list.renaming=true" 
                                        @keyup.enter="list.renaming=false; list.watchlist_data=undefined"
                                        @keyup.esc="disableRename(list); list.watchlist_data=undefined"
                                    >
                                        <div v-show="!list.renaming">
                                            {{list.name}}
                                        </div>
                                        <div v-show="list.renaming">
                                            <input type="text" v-model="list.name" @click.stop/>
                                        </div>
                                    </div>
                                </td>
                                <td class="remover">
                                    <div  v-show="lists.length > 1" class="list-remove" @click.stop="remove(index)">
                                        <i class="icons8-delete-2"></i>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </li>
                <li v-show="loggedIn" class="list-selector" @click="add">
                    <div class="list-clicker">
                        <table class="list-head-table">
                            <tr>
                                <td>
                                    <div class="list-add">
                                        <i class="icons8-plus-math-4"></i>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </li>
            </ul>
        </div>-->

    </div>
</template>

<script>
    export default {
        name: 'ListsSelector',
        props: {
        },
        data() {
            return {
                lists: []
            }
        },
        methods: {
            add(name) {
                console.log(name)
            }
        }
    }
</script>

<style scoped>
div.container {
border: 1px solid red;
}
</style>
