
import defaultCategoryAxesSettings from './commonFunctions.js'
import myCategoryLabel from './commonFunctions.js'
import defaultPanelSettings from './commonFunctions.js'
import defaultBalloon from './commonFunctions.js'
import isTouch from './commonFunctions.js'

export default {
    mixins:[defaultCategoryAxesSettings, myCategoryLabel, defaultPanelSettings, defaultBalloon, isTouch],
    methods: {
        cotNetPositions(payload){
            this.round_prec = 0
            let tmpGraphs = []
            tmpGraphs.push(payload.misc.graphs[0])
            tmpGraphs.push(payload.misc.graphs[1])
            tmpGraphs.push(payload.misc.graphs[2])
            return this.cot(payload ,this.createStockChart(tmpGraphs), payload.misc.graphs[0].label)
        },
        cotNetPositionsIndex(payload){
            this.round_prec = 2
            let tmpGraphs = []
            tmpGraphs.push(payload.misc.graphs[3])
            tmpGraphs.push(payload.misc.graphs[4])
            tmpGraphs.push(payload.misc.graphs[5])
            return this.cot(payload ,this.createStockChart(tmpGraphs),  payload.misc.graphs[3].label)
        },
        cotNetTraders(payload){
            this.round_prec = 0
            let tmpGraphs = []
            tmpGraphs.push(payload.misc.graphs[6])
            tmpGraphs.push(payload.misc.graphs[7])
            return this.cot(payload ,this.createStockChart(tmpGraphs),  payload.misc.graphs[6].label)
        },
        cotNetTradersIndex(payload){
            this.round_prec = 2
            let tmpGraphs = []
            tmpGraphs.push(payload.misc.graphs[8])
            tmpGraphs.push(payload.misc.graphs[9])
            return this.cot(payload ,this.createStockChart(tmpGraphs),  payload.misc.graphs[8].label)
        },
        cotNetConcentration(payload){
            this.round_prec = 1
            let tmpGraphs = []
            tmpGraphs.push(payload.misc.graphs[10])
            tmpGraphs.push(payload.misc.graphs[11])
            return this.cot(payload ,this.createStockChart(tmpGraphs),  payload.misc.graphs[10].label)
        },
        cotNetConcentrationIndex(payload){
            this.round_prec = 1 
            let tmpGraphs = []
            tmpGraphs.push(payload.misc.graphs[12])
            return this.cot(payload ,this.createStockChart(tmpGraphs), payload.misc.graphs[12].label)
        },
        createStockChart(graphs){
            var self = this
            var tmp = []
            for(var i=0; i<graphs.length; i++) {
                tmp.push({
                    id: graphs[i].id,
                    title: graphs[i].title,
                    type: "line",
                    fillAlphas: graphs[i].fillAlphas,
                    useDataSetColors: false,
                    lineColor: graphs[i].color,
                    fillColors: graphs[i].color,
                    lineThickness: 2,
                    valueField: graphs[i].valueField,
                    //balloonText: "[["+graphs[i].valueField+"]]",
                    balloonFunction: function(item) {
                        return parseFloat(item.values.value).toFixed(self.round_prec);                
                    },
                })
            }
            return tmp
        },
        cot(payload, stockGraphIn, labelIn) {
            var self = this
            return window.AmCharts.makeChart(payload.targetId, {
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
     //                marginLeft: "100px",
                dataSets: [{
                    fieldMappings: payload.misc.mapping,
                    dataProvider: payload.data,
                    categoryField: "date"
                }],
                categoryAxesSettings: this.defaultCategoryAxesSettings(payload.functionId),
                panels: [{ // panel cot
                    title: labelIn + ":",
                    stockGraphs: stockGraphIn,
                    valueAxes: [{
                        position: "right",
                        fontSize: 13,
                    }],
                    allLabels: [],
                    /*
                    allLabels: [{
                        text: labelIn+" bla",
                        bold: true,
                        size: 16,
                        x: 10, y: 15,
                        alpha: 0.5
                    }],*/
                    stockLegend: {
                        fontSize: 13,
                        valueFunction: function(item) {
                            if (item.values === undefined) {return ""}

                            var val = item.values.value
                            if (isNaN(val)) {
                                return ""
                            }

                            return Math.abs(val) > 999 ? Math.sign(val)*((Math.abs(val)/1000).toFixed(1)) + 'k' : parseFloat(val).toFixed(self.round_prec)
                        }
                    },
                    /*
                    legend: {
                        position: "top",
                        maxColumns: 11,
                        fontSize: 14,
                        autoMargins: false,
                        marginRight: 0,
                        marginBottom: 10,
                        marginLeft: this.defaultPanelSettings(payload.functionId).marginLeft
                    },*/
                    usePrefixes: true,
                    percentHeight: 60,
                    categoryAxis: {
                        parseDates: true,
                        equalSpacing: true,
                        labelFunction: this.myCategoryLabel
                    }
                }],
                panelsSettings: this.defaultPanelSettings(payload.functionId),
                valueAxesSettings: {
                    inside: false
                },
                chartCursorSettings: {
                    valueBalloonsEnabled: true,
                    pan: this.isTouch(),
                    fontSize: 11,
                    valueLineEnabled: true,
                    valueLineBalloonEnabled: true
                },
                balloon: this.defaultBalloon(payload.functionId),
                chartScrollbarSettings: {enabled: false},
            })
        } 
    }
}
