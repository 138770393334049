<template>
    <div v-show="active" :key="build">
        <div class="charts-container">
            <!-- Charty a jejich parsery a souvisejici veci -->
            <div class="charts-column-left">
                <div v-if="chart !== undefined" v-for="(chart, key) in charts" class="chart-wrapper" :id="'chart-wrapper_'+key">
                    <div v-show="!disabled">
                        <!-- Blok pro pripojeni souvisejiciho parseru -->
                        <div :ref="'parser_'+key" v>
                        </div>

                        <div :ref="'slider_'+key">
                        </div>
                    </div>
                    <div v-show="disabled" class="parser-disabled-placeholder">
                    </div>

                    <Resizor 
                        :minHeight="200" 
                        :height="chart.height"
                        @resized="function(h) {chart.height = h;}"
                    >

                        <div slot="content" class="chart-content">

                            <div v-show="chart.status == 0" class="chart-target">
                                <div class="chart-msg">
                                    There is nothing to display yet.
                                </div>
                            </div>

                            <div v-show="chart.status == 1" class="chart-target">
                                <div class="chart-msg">
                                    <Loader />
                                </div>
                            </div>

                            <div class="chart-floater">
                                <div v-show="((chart.status == 2 || chart.status == 3 || chart.status == 5) && (Object.keys(charts).length > 1 || Object.keys(charts).indexOf(key) > 0))">
                                    <div v-show="Object.keys(charts).length > 1" @click="remove(key)" class="icon">
                                        <i class="icons8-delete-2"/>
                                    </div>
                                    <div v-show="Object.keys(charts).indexOf(key) > 0" @click="openParser(key)" class="icon">
                                        <i class="icons8-mechanistic-analysis-filled"/>
                                    </div>
                                </div>

                                <div v-show="(chart.status == 2 || chart.status == 3 || chart.status == 5) && pencilActive && Object.keys(charts).indexOf(key) == 0">
                                    <div class="paletteWrapper">
                                        <div v-for="color in paletteColors" :style="{'background-color': color.pencil_rgba}"  @click="selectPaletteColor(color)" :id="color.id">
                                            <i class="icons8-checkmark" v-show="color.selected"/>
                                        </div>
                                    </div>
                                </div>

                                <div v-show="(chart.status == 2 || chart.status == 3 || chart.status == 5) && rangeActive && Object.keys(charts).indexOf(key) == 0">
                                    <div class="paletteWrapper">
                                        <div v-for="color in paletteColors" :style="{'background-color': color.range_rgba}"  @click="selectPaletteColor(color)" :id="color.id">
                                            <i class="icons8-checkmark" v-show="color.selected"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="chart.status == 2" class="chart-target" :id="key">
                            </div>


                            <div v-show="chart.status == 3" class="chart-target">
                                <div class="chart-msg">
                                    {{chart.msg}}
                                </div>
                            </div>

                            <div v-show="chart.status == 4" class="chart-target">
                                <div class="chart-msg">
                                    Please sign in first to use this tool.
                                </div>
                            </div>

                            <div v-show="chart.status == 5" class="chart-target">
                                <div class="chart-msg">
                                    <div style="width: 90%; margin: auto">
                                        <div>Continuous histogram is an advanced statistical analysis available only to premium users.</div>
                                        <div>The only exception are the corn futures and spreads where you can try it for free, e.g. ZCZ21, ZCN22-ZCZ21, ZC1, etc.</div>
                                    </div>
                                    <div style="margin-bottom: 10px">
                                        <input type="button" class="button-lightred" value="Get this advanced chart" style="font-size: 16px" @click="toSubscription()"/>
                                    </div>
                                </div>
                            </div>

                            <div v-show="chart.status == 6" class="chart-target">
                                <div class="chart-msg">
                                    <div style="width: 90%; margin: auto">
                                        <div>You're  allowed to max 3 active tabs in free version.</div>
                                        <div>Unlock more with premium subscription.</div>
                                    </div>
                                    <div style="margin-bottom: 10px">
                                        <input type="button" class="button-lightred" value="Get premium" style="font-size: 16px" @click="toSubscription()"/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Resizor>
                </div>

                <div class="add-chart-wrapper">
                    <input v-if="Object.keys(charts).length > 0 && Object.keys(charts).length < 5 && !disabled" type="button" class="button-blue" value="Add sub-chart" @click="add"/>
                    <input v-else type="button" class="button-grey" value="Add sub-chart"/>
                </div>
            </div>

            <!-- Nastroje (kresleni atd.) + zoom -->
            <div class="charts-column-right">
                <div>
                    <div class="tools-container">
                        <div v-for="tool in tools" class="tool-button" :class="{'active': tool.active}" @click="flipTool(tool); tool.callback(tool)">
                            <span class="tooltip-wrapper">
                                <span class="tooltiptext tooltiptext-left">{{tool.tooltip}}</span>
                                <i v-bind:class="tool.icon" class="tool-icon"></i>
                            </span>
                        </div>
                    </div>

                    <div class="zoom-container">
                        <div v-if="zoomEnabled[index]" v-for="(item, index) in zooms" class="tool-button" :class="{'active': item.active}" @click="zoom(item)">
                            {{item.name}}
                        </div>
                    </div>
                </div>
            </div>

            <div style="clear: both;"></div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    import Parser2 from "./Parser2.vue"
    var Parser2Class = Vue.extend(Parser2)

    import Slider2 from "./Slider2.vue"
    var Slider2Class = Vue.extend(Slider2)

    import resizor from "./resizor.vue"
    import loader from "./loader.vue"
    import modal3 from './modal3.vue';
    import {dSource} from "./vars.js"
    import axios from 'axios';

    /**
      * Grafy
      */
    import priceCandlestickHistorical from "./charts_js/priceCandlestick.js";
    import priceCandlestickFutures from "./charts_js/priceCandlestick.js";
    import priceLine from "./charts_js/priceLine.js";
    import PriceContinuous from "./charts_js/priceContinuous.js";
    import seasonalityStacked from "./charts_js/seasonalityStacked.js";
    import seasonalityAverages from "./charts_js/seasonalityAverages.js";
    import seasonalityMonth from "./charts_js/seasonalityMonth.js";
    import volume from "./charts_js/volume.js"
    import histogram2 from "./charts_js/histogram2.js"
    import termStructure from "./charts_js/termStructure.js"
    import cotNetPositions from "./charts_js/cot.js";
    import cotNetPositionsIndex from "./charts_js/cot.js";
    import cotNetTraders from "./charts_js/cot.js";
    import cotNetTradersIndex from "./charts_js/cot.js";
    import cotNetConcentration from "./charts_js/cot.js";
    import cotNetConcentrationIndex from "./charts_js/cot.js";
    import signalCOT from './charts_js/signal.js'; 
    import signalAverage from './charts_js/signal.js'; 
    import signalStrength from './charts_js/signal.js'; 

    export default {

  data() {
    return {
      isPremium: false, // This should be set based on actual user data
    };
  },
  mounted() {
    // Check if the user has a premium subscription
    // This logic should be replaced with actual implementation
    this.checkPremiumStatus();
  },
  methods: {
    checkPremiumStatus() {
      // This should be replaced with the actual check for premium status
      // Example: this.isPremium = userHasPremiumSubscription();
      this.isPremium = false; // For demonstration, set to false
    },
    goToSubscriptionManagement() {
      window.location.href = '/subscription-management';
    },
  },
        name: 'Charts2',
        props: {
            user: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            ux: {
                type: Object
            }
        },
        components: {
            "Parser": Parser2,
            "Resizor": resizor,
            "Loader": loader,
            "Modal": modal3,
            "Slider": Slider2,
        },
        mixins: [
            priceCandlestickHistorical, 
            priceCandlestickFutures, 
            priceLine, 
            PriceContinuous,
            seasonalityStacked, 
            seasonalityAverages, 
            seasonalityMonth,
            volume, 
            termStructure, 
            cotNetPositions, 
            cotNetPositionsIndex, 
            cotNetTraders, 
            cotNetTradersIndex, 
            cotNetConcentration, 
            cotNetConcentrationIndex, 
            histogram2,
            signalStrength, 
            signalCOT, 
            signalAverage,
        ],
        data() {
            return {
                months: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],

                build: 0,

                dSource: dSource,

                charts: {},

                amObj: {},

                payloads: {},

                data: {},

                tools: [
                    {name: "pencil",tooltip: "Draw trendline", icon: "icons8-crayon", active: false, callback:this.onPencil},
                    {name: "range",tooltip: "Highlight date range", icon: "icons8-stocks", active: false, callback:this.onRange},
                    {name: "eraser",tooltip: "Erase trendline", icon: "icons8-erase", active: false, callback:this.onEraser},
                    {name: "glc",tooltip: "Profit/Loss calculator", icon: "icons8-duration-finance", active: false, callback: this.onCalculator},
                    {name: "export",tooltip: "Export as image", icon: "icons8-download-graph-report", active: false, callback: this.onExport},
                    {name: "plink",tooltip: "Permalink", icon: "icons8-link-filled", active: false, callback: this.onPermalink},
                    {name: "wlist",tooltip: "Add to watchlist", icon: "icons8-filled-bookmark-ribbon", active: false, callback: this.onWatchlist},
                ],

                // definide zoom tlaciket
                zooms: [
                    {name: "3M", active: false, days: 90},
                    {name: "1Y", active: false, days: 365},
                    {name: "2Y", active: false, days: 730},
                    {name: "5Y", active: false, days: 1825},
                    {name: "20Y", active: false, days: 7300},
                    {name: "ALL", active: false, days: 26000}
                ],

                // tabulka pro vypocet kombinace povoleneho zoomu
                zoomTable: {             
                //  functionId                          3M      1Y      2Y      5Y      20Y     ALL
                    priceCandlestickFutures:            [true,  true,   true,   true,   true,   true], 
                    priceLine:                          [true,  true,   true,   true,   true,   true], 
                    priceCandlestickHistorical:         [true,  true,   true,   true,   true,   true], 
                    PriceContinuous:                    [true,  true,   true,   true,   true,   true], 
                    histogram2:                         [false, true,   true,   true,   true,   true], 
                    seasonalityStacked:                 [true,  true,   true,   true,   true,   true], 
                    seasonalityAverages:                [true,  true,   true,   true,   true,   true], 
                    seasonalityMonth:                   [true,  true,   true,   true,   true,   true], 
                    volume:                             [true,  true,   true,   true,   true,   true],
                    termStructure:                      [false, true,   true,   true,   true,   true],
                    cotNetPositions:                    [true,  true,   true,   true,   true,   true],
                    cotNetPositionsIndex:               [true,  true,   true,   true,   true,   true],
                    cotNetTraders:                      [true,  true,   true,   true,   true,   true],
                    cotNetTradersIndex:                 [true,  true,   true,   true,   true,   true],
                    cotNetConcentration:                [true,  true,   true,   true,   true,   true],
                    cotNetContentrationIndex:           [true,  true,   true,   true,   true,   true]
                },
                zoomEnabled:                            [true,  true,   true,   true,   true,   true], // vysledny ukazatel povoleneho zoomu

                // tabulka pro vypocet defaultniho zoomu
                // indexy odpovídaji pozici t this.zooms
                zoomDefaults: {
                    priceCandlestickFutures:            1, 
                    priceLine:                          1, 
                    priceCandlestickHistorical:         2, 
                    PriceContinuous:                    2, 
                    histogram2:                         4, 
                    seasonalityStacked:                 1, 
                    seasonalityAverages:                1, 
                    seasonalityMonth:                   1, 
                    volume:                             1,
                    termStructure:                      2,
                    cotNetPositions:                    2,
                    cotNetPositionsIndex:               2,
                    cotNetTraders:                      2,
                    cotNetTradersIndex:                 2,
                    cotNetConcentration:                2,
                    cotNetContentrationIndex:           2,
                },

                zoomSelected: null,
                zoomCustom: false,

                modals: {},

                parsers: {},

                sliders: {},

                whisperData: [],

                /**
                  * Barvy kresleni v zavislosti na grafu
                  */
                trendLineColors: {
                    priceCandlestickFutures:            "#0000ff", 
                    priceLine:                          "#00cc00", 
                    priceCandlestickHistorical:         "#0000ff", 
                    PriceContinuous:                    "#9400d3", // nejaka fialova 
                    histogram2:                         "#00cc00", 
                    seasonalityStacked:                 "#00cc00", 
                    seasonalityAverages:                "#00cc00", 
                    seasonalityMonth:                   "#00cc00", 
                    volume:                             "#00cc00",
                    termStructure:                      "#00cc00",
                    cotNetPositions:                    "#0000ff",
                    cotNetPositionsIndex:               "#0000ff",
                    cotNetTraders:                      "#0000ff",
                    cotNetTradersIndex:                 "#0000ff",
                    cotNetConcentration:                "#0000ff",
                    cotNetContentrationIndex:           "#0000ff"
                },

                // ukazatel zakazani pro free uzivatele po prekroceni limitu
                disabled: false,

                // ukazatel otevreni palety barev (zatim pouze pro range)
                paletteActive: false,
                paletteColors: [
                    {id: "b", pencil_rgba: "rgba(0, 0, 255, 0.6)", pencil_rgb: "#0000ff", range_rgba: "rgba(41, 182, 246, 0.7)", range_rgb: "#29b6f6", range_alpha: 0.2, selected: true},
                    {id: "r", pencil_rgba: "rgba(255, 0, 0, 0.6)", pencil_rgb: "#ff0000", range_rgba: "rgba(255, 112, 67, 0.7)", range_rgb: "#ff7043", range_alpha: 0.2, selected: false},
                    {id: "g", pencil_rgba: "rgba(0, 204, 0, 0.6)", pencil_rgb: "#00cc00", range_rgba: "rgba(156, 204, 101, 0.7)", range_rgb: "#9ccc65", range_alpha: 0.2, selected: false},
                    {id: "y", pencil_rgba: "rgba(255, 214, 0, 0.6)", pencil_rgb: "#ffd600", range_rgba: "rgba(255, 238, 88, 0.7)", range_rgb: "#ffee58", range_alpha: 0.2,  selected: false}
                ],
            }
        },
        computed: {
            /**
              * Prave vybrany zoom
              */ 
            zoomActive() {
                for (var i in this.zooms) {
                    if (this.zooms[i].active && this.zoomEnabled[i]) {return this.zooms[i]}
                }
                return undefined
            },

            /**
              * Defaultni zoom
              * v zavislosti na kombinaci zobrazenych grafu
              */
            zoomDefault() {
                var index = 1;
                var keys = Object.keys(this.charts)
                if (keys.length > 0 && this.charts[keys[0]].robj !== undefined && this.charts[keys[0]].robj !== null) {
                    index = this.zoomDefaults[this.charts[keys[0]].robj.functionId]
                }
                return this.zooms[index]
            },

            /**
              * Ukazatel stavu prihlaseni uzivatele
              */
            loggedIn() {
                return this.user.id > 0
            },

            // ukazatel ze jsou vykresleni grafu hotove
            // kvuli moznosti opozdit vykresleni pred UX
            done() {
                return this.isDone()
            },

            loading() {
                return this.isLoading()
            },

            eraserActive() {
                return this.isToolActive("eraser")
            },

            rangeActive() {
                return this.isToolActive("range")
            },

            pencilActive() {
                return this.isToolActive("pencil")
            },

            paletteSelected() {
                for (var i in this.paletteColors) {
                    if (this.paletteColors[i].selected) {
                        return this.paletteColors[i]
                    }
                }
                return this.paletteColors[0]
            }
        }, 
        watch: {
            active: {
                deep: true,
                handler() {}
            }
        },
        methods: {
            selectPaletteColor(color) {
                for (var i in this.paletteColors) {
                    this.paletteColors[i].selected = this.paletteColors[i].id == color.id
                }
                this.handleCursorShow()

                for (var key in this.amObj) {
                    this.setTrendLineColor(this.amObj[key])
                }

            },
            
            isToolActive(toolName) {
                for (var i in this.tools) {
                    if (this.tools[i].name == toolName && this.tools[i].active) {
                        return true
                    }
                }
                return false
            },

            isDone() {
                for (var key in this.charts) {
                    if (!this.charts[key].done) {
                        return false
                    }
                }
                return true
            },

            isLoading() {
                for (var key in this.charts) {
                    if (this.charts[key].loading) {
                        return true
                    }
                }
                
                return false
            },

            /**
              * Nastaveni dat pro naseptavac v parserech
              */
            setWhisperData(data) {
                this.whisperData = data
                for (var key in this.parsers) {
                    this.parsers[key].setTickerNodes(data)
                }
            },

            /**
              * Pridani noveho chartu
              * s vyuzitim defaultnim parametru
              */
            add() {
                return this.addChart(400)
            },

            /**
              * Pridani noveho chartu
              * zadane parametry (napr. externe pres ux)
              */
            addChart(height) {
                var key = "chart_" + new Date().getTime() + "_" + parseInt(Math.random() * 10000)

                // objekt chartu
                this.charts[key] = {
                    robj: null,
                    height: height,
                    loading: false,
                    status: (this.loggedIn) ? 0 : 4,
                    msg: "Something went wrong.",
                    done: false
                }

                // instance Parser2
                this.parsers[key] = new Parser2Class({
                    propsData: {
                        targetId: key,
                        modal: Object.keys(this.charts).indexOf(key) > 0,
                        visible: true,
                        edit: false,
                        user: this.user,
                    }        
                })
                
                this.parsers[key].setTickerNodes(this.whisperData)

                // calbacky
                this.parsers[key].$on("start", this.graphStart)
                this.parsers[key].$on("close", this.closeParser)

                // mount
                this.parsers[key].$mount()

                // prenastavit cas podle uz drive existujiciho parseru
                var dateOther = (Object.keys(this.parsers).length > 1) ? this.parsers[Object.keys(this.parsers)[0]].date : undefined 
                if (dateOther !== undefined) {
                    this.parsers[key].setDate(dateOther)
                }

                // prekreslit zobrazeni
                this.$forceUpdate()
                
                // opozdene pridano do DOM
                var self = this
                setTimeout(function() {
                    self.$refs["parser_"+key][0].appendChild(self.parsers[key].$el)
                }, 25)

                return key
            },

            disable() {
                this.disabled = true
                for (var key in this.charts) {
                    this.charts[key].status = this.loggedIn ? 6 : 4
                }
            },

            enable() {
                this.disabled = false
                for (var key in this.charts) {
                    this.charts[key].status = this.loggedIn ? 0 : 4
                }
            },

            /**
              * Odebrani jednoho chartu (grafu) podle zvoleneho key
              * odebira i souvisejici parser data atd.
              */
            remove(key) {
                // odebrat chart
                document.getElementById("chart-wrapper_"+key).remove()
                var tmp = {}
                for (var k in this.charts) {
                    if (k != key) {
                        tmp[k] = this.charts[k]
                    }
                }
                this.charts = tmp

                // odebrat parser
                this.parsers[key].$el.remove()
                this.parsers[key].$destroy()
                var tmp = {}
                for (var k in this.parsers) {
                    if (k != key) {
                        tmp[k] = this.parsers[k]
                    }
                }
                this.parsers = tmp

                // prenastavit mode zobrazeni ostatnich parseru
                for (var k in this.parsers) {
                    this.parsers[k].modal = Object.keys(this.parsers).indexOf(k) > 0 
                    this.parsers[k].visible = !this.parsers[k].modal
                }

                // odebrat a vycistit amObj
                // objekt nemusi existovat pokud se zavira modalni okno aniz by se v nem neco udelalo
                if (this.amObj[key] !== undefined) {
                    this.amObj[key].clear()
                    this.amObj[key] = undefined
                }

                // odebrat slider pokud ho mel
                if (this.sliders[key] !== undefined) {
                    this.removeSlider(key)
                }

                // komplet prekreslit
                try {
                    this.update()
                } catch (e) {}
            },

            /**
              * Vycisteni objektu bezem smazani cele komoponenty
              * aby zbytecne nezustalo viset v pameti
              */
            clear() {
                for (var key in this.charts) {
                    this.remove(key)
                }
            },

            /**
              * Vlastni update zobrazeni
              * aby se zajistilo korektni vykresleni parseru a grafu na spravnych mistech
              */
            update() {
                // prekresli DOM
                this.build += 1

                // synchronizace
                this.syncCharts()
                this.syncZoom()

                var self = this
                setTimeout(function() {
                    for (var key in self.charts) {
                        // vsechny parsery na sve misto
                        self.$refs["parser_"+key][0].appendChild(self.parsers[key].$el)

                        // vsechny slidery na sve misto
                        if (self.sliders[key] !== undefined) {
                            self.$refs["slider_"+key][0].appendChild(self.sliders[key].$el)
                        }

                        if (self.amObj[key] !== undefined) {
                            // aby se nezobrazilo po prekresleni tlacitko export
                            // mozna to jde i lepe
                            if (self.amObj[key].type == "stock" && self.amObj[key].AmExport !== undefined) {
                                self.amObj[key].AmExport.exportPNG = false
                            }

                            // vykreslit
                            self.amObj[key].write(key)
                        }
                    }
                }, 10)
            },


            /**
              * Spusti vykresleni grafu
              * nejprve dotaz na backend
              */
            graphStart(robj) {
                // pridat usera do robj
                robj.user = this.user

                // "id" chartu
                var key = robj.targetId

                // stary objekt?
                if (this.amObj[key] !== undefined) {
                    this.amObj[key].clear()
                } 

                // souvisejici chart
                var chart = this.charts[key]

                // chart zakazany
                // prekroceni limitu u free uzivatele
                if (this.disabled) {
                    chart.status = 6
                    this.$forceUpdate()
                    return
                }

                // zapnout loader
                chart.status = 1
                this.$forceUpdate()
                chart.loading = true

                // zavrit pripadny modal
                this.closeParser(key)

                // dotaz na backend
                var self = this
                axios.post(this.dSource, {robj: robj}).then(payload => function(payload) {
                    if (payload.check) { // vsechno problehlo v pohode --> render
                        chart.robj = robj // zapamatuje si vlastni robj
                        payload.data = payload.data.result // grafy to zatim ocekavaji primo jako data
                        chart.status = 2
                        self.graphRender(payload)
                    } else { // error --> chybovy vypis (status == 3), histogram premium (status == 5)
                        if (payload.msg == "premium") {
                            chart.status = 5
                        } else {
                            chart.msg = payload.msg
                            chart.status = 3 
                        }

                        self.amObj[payload.targetId] = undefined
                        self.$forceUpdate()

                        chart.done = false
                        chart.loading = false
                    }

                    self.parsers[key].tickerDisabled = false
                }(payload.data))
            },

            /**
              * Vykresleni grafu a operace s tim souvisejici (sync apod.)
              */
            graphRender(payload) {
                // pri zavreni pred dokoncenim
                if (this.parsers[payload.targetId] === undefined || this.parsers[payload.targetId] === null) {return}

                // na backendu doslo ke zmene dostupneho datumu
                // sync do prislusneho parseru
                if (payload.misc.date !== undefined) {
                    this.parsers[payload.targetId].setDate(payload.misc.date)

                    for (var key in this.parsers) {
                        // sam sebe ne
                        if (key == payload.targetId) {continue}

                        // neexistuje graf
                        if (this.amObj[key] === undefined) {continue}

                        // cas uz tak je stejny
                        // zalezi zaroven na poloze slideru 
                        // po prekresleni nejakeho TS musi byt vse v poloze 0 (uplne vpravo)
                        if (this.parsers[payload.targetId].time == this.parsers[key].time) {
                            if (this.sliders[key] !== undefined) {
                                if (this.sliders[key].value == 0) {
                                    continue
                                }
                            } else {
                                continue
                            }
                        }

                        // prenest cas
                        this.parsers[key].setDate(this.parsers[payload.targetId].date)

                        // prekresleni nema smysl pro jine nez histogram a TS 
                        if (!["histogram2", "termStructure"].includes(this.charts[key].robj.functionId)) {continue}

                        // spustit prekresleni
                        this.parsers[key].onChartClicked()

                        // aby kazdy mohl spustit jen jeden jiny
                        // vyretezi se to a nakonec se vykresly vse co je treba
                        break
                    }
                }

                // kopie puvodniho payloadu
                this.payloads[payload.targetId] = JSON.parse(JSON.stringify(payload))

                // vytvoreni objektu 
                // s overenim existence --> error pokud se list zavre driv nez je zpracovani kompletni
                if (document.getElementById(payload.targetId)) {
                    this.amObj[payload.targetId] = this[payload.functionId](payload)
                } else {
                    this.charts[payload.targetId].done = false
                    this.charts[payload.targetId].loading = false
                    return
                }

                // unikatni id kazdemu panelu
                for (var i in this.amObj[payload.targetId].panels) {
                    this.amObj[payload.targetId].panels[i].id += "_" + new Date().getTime() + "_" + parseInt(Math.random() * 10000)
                }

                // copyright
                this.addCopyright(payload.targetId)

                // trendlines
                if (this.charts[payload.targetId].trendLines !== undefined) {
                    this.setTrendLines(this.amObj[payload.targetId], this.charts[payload.targetId].trendLines)
                    this.charts[payload.targetId].trendLines = undefined
                }

                // trendlines
                if (this.charts[payload.targetId].guides !== undefined) {
                    this.setGuides(this.amObj[payload.targetId], this.charts[payload.targetId].guides)
                    this.charts[payload.targetId].guides = undefined
                }

                // hidden
                if (this.charts[payload.targetId].hidden !== undefined) {
                    this.setHidden(this.amObj[payload.targetId], this.charts[payload.targetId].hidden)
                    this.charts[payload.targetId].hidden = undefined
                }

                // slider pokud se jedna o TS
                this.removeSlider(payload.targetId)
                if (this.charts[payload.targetId].robj.functionId == "termStructure") {
                    this.addSlider(payload.targetId, new Date(this.parsers[payload.targetId].time))
                }

                

                // k pokusum o sync muze dojit i behem dozatu na zaklade zmeny datumu
                // nevadi to protoze ten krery zrovna bezi request to pak syncne
                try {
                    // synchronizace vsech chartu
                    this.syncCharts()

                    // synchronizace zoomu
                    this.syncZoom()

                } catch (e) {
                }

                this.charts[payload.targetId].done = true
                this.charts[payload.targetId].loading = false
            },

            /**
              * Pridani slideru prislusnemu TS
              */
            addSlider(key, dateStart) {
                // instance Slider2
                this.sliders[key] = new Slider2Class({
                    propsData: {
                        targetId: key,
                        dateStart: dateStart
                    }        
                })

                // callbacky
                this.sliders[key].$on("changed", this.syncSliders)

                this.sliders[key].$mount()

                // opozdene pridano do DOM
                var self = this
                setTimeout(function() {
                    self.$refs["slider_"+key][0].appendChild(self.sliders[key].$el)
                    self.sliders[key].refresh()
                }, 25)
            },

            /**
              * Odebrani slideru TS
              */
            removeSlider(key) {
                if (this.sliders[key] === undefined) {return}

                // odebrat parser
                this.sliders[key].$el.remove()
                this.sliders[key].$destroy()
                var tmp = {}
                for (var k in this.sliders) {
                    if (k != key) {
                        tmp[k] = this.sliders[k]
                    }
                }
                this.sliders = tmp
            },

            /**
              * Synchronizace udaju mezi TS slidery a jejich grafy
              */
            syncSliders(v, key) {
                for (var k in this.sliders) {
                    if (k != key) {
                        this.sliders[k].setValue(v)
                    }

                    this.parsers[k].setDate(this.amObj[k].data[v].data[0].date.substring(0, 10))
                    var d = new Date(this.parsers[k].time)

                    this.amObj[k].dataSets[0].dataProvider = this.amObj[k].data[v].data
                    this.amObj[k].dataSets[1].dataProvider = this.amObj[k].data[v+1].data
                    this.amObj[k].dataSets[2].dataProvider = this.amObj[k].data[v+5].data
                    this.amObj[k].dataSets[3].dataProvider = this.amObj[k].data[v+22].data

                    var oldTitle = this.amObj[k].panels[0].legend.legendData[0].title;
                    this.amObj[k].panels[0].legend.legendData[0].title = oldTitle.split(" ")[0] + " " +
                                   oldTitle.split(" ")[1] + " " + 
                                   oldTitle.split(" ")[2] + " (" + 
                                   this.months[d.getUTCMonth()] + " " + 
                                   d.getUTCDate() + ", " + 
                                   d.getUTCFullYear() + ")";

                    this.amObj[k].zoom(d, this.amObj[k].endDate)
                    this.amObj[k].validateNow(true, true)
                }
            },

            /**
              * Overeni synchronizovatelnosti chartu
              * kvuli preskakovani histogramu a term structure
              */
            isSyncable(key) {
                return this.amObj[key] !== undefined 
                    && this.amObj[key] !== null 
                    && this.amObj[key].type == "stock" 
                    && this.charts[key].robj.functionId != "termStructure" 
                    && this.charts[key].robj.fucntionId != "histogram2"
            },

            /**
              * 'Obal' opraci potrebnych k synchronizaci os a chovani vsech chartu
              */
            syncCharts() {
                // synchonizace os x
                this.syncCategoryAxes()

                // synchronizace chovani kursoru
                // plati pro grafy pro ktere to vzajemne data smysl
                this.syncCursors()

                // synchronizace grafu se stavem nastoju
                this.syncTools()

                // prekreslit
                this.$forceUpdate()
            },

            /**
              * Synchronizace x-ovych os vsech grafu
              * vklada 'prazdna' data ke vsem stejne
              * objekty typu 'serial' presklakuje
              */
            syncCategoryAxes() {
                // resetovat rozsah
                this.time_first = null
                this.time_last = null

                // vybrat nejvetsi kombinovany rozsah ze vsechn chart obj.
                var d_first
                var d_last
                for (var key in this.charts) {
                    // preskocit nesynchronizovatelne grafy
                    if (!this.isSyncable(key)) {continue} 

                    d_first = new Date(this.payloads[key].data[0]["date"])
                    d_last = new Date(this.payloads[key].data[this.payloads[key].data.length-1]["date"])
                    
                    // nejmensi prvni datum
                    if (this.time_first !== null) {
                        this.time_first = ((this.time_first <= d_first.getTime()) ? this.time_first : d_first.getTime())
                    } else {
                        this.time_first = d_first.getTime()
                    }

                    // nejvetsi posledni datum
                    if (this.time_last !== null) {
                        this.time_last = ((this.time_last > d_last.getTime()) ? this.time_last : d_last.getTime())
                    } else {
                        this.time_last = d_last.getTime()
                    }
                }

                // inicializace dat
                this.data = {}
                for (var key in this.charts) {
                    if (!this.isSyncable(key)) {continue} 
                    this.data[key] = []
                }

                // kopie vsech originalnich dat
                var tmp = JSON.parse(JSON.stringify(this.payloads))

                var d = new Date(this.time_first)
                var other
                var test = {"all":false, "tested": {}}
                while (d <= this.time_last) {
                    // test existence datoveho bodu alespon v jednom z datasetu
                    test["all"] = false
                    test["tested"] = {}

                    // testovani
                    for (var key in this.charts) {
                        if (!this.isSyncable(key)) {continue} 

                        if (tmp[key].data.length > 0) {
                            other = new Date(tmp[key].data[0]["date"])
                            test["tested"][key] = d.getTime() == other.getTime()
                        } else {
                            test["tested"][key] = false
                        }

                        test["all"] |= test["tested"][key]
                    }

                    // pridavani data s preskakovanim vikendu
                    if (test["all"]) {
                        for (var key in this.charts) {
                            if (!this.isSyncable(key)) {continue} 

                            if (test["tested"][key]) {
                                if (!this.isWeekend(d)) {
                                    this.data[key].push(tmp[key].data[0])
                                }

                                tmp[key].data.splice(0, 1)
                            } else {
                                if (!this.isWeekend(d)) {
                                    this.data[key].push({"date":d.toISOString()})
                                }
                            }
                        }
                    }

                    // inkrementace
                    d.setTime(d.getTime()+86400000)
                }

                // nastavit vsem synchronizovana data a zobrazit plny rozsah
                for (var key in this.charts) {
                    if (!this.isSyncable(key)) {continue} 

                    this.amObj[key].dataSets[0].dataProvider = this.data[key]
                    this.amObj[key].validateNow(true, true)
                }
            },

            /**
              *
              */
            zoom(item) {
                for (var i in this.zooms) {
                    this.zooms[i].active = this.zooms[i].name == item.name
                }

                for (var key in this.charts) {
                    // v chartu neni vykresleny objekt
                    if (this.amObj[key] === undefined || this.amObj[key] === null) {continue}

                    // objekt amCharts
                    var obj = this.amObj[key]

                    if (this.isSyncable(key)) { // stock chart (cenove) krome TS
                        // rozsah datumu pro zoom
                        var date_end = (item.date_end !== undefined) ? item.date_end : obj.lastDate
                        var date_start = (item.date_start !== undefined) ? item.date_start : new Date(date_end.getTime() - item.days * 86400 * 1000)
                        
                        // zoom konkretniho objektu
                        obj.zoom(date_start, date_end)
                    } else { // serial chart (histogram a TS

                        if (this.charts[key].robj.functionId == "termStructure") {
                            // rozsah datumu pro zoom
                            var date_start = (item.date_start !== undefined) ? item.date_start : obj.firstDate
                            var date_end = (item.date_end !== undefined) ? item.date_end : new Date(date_start.getTime() + item.days * 86400 * 1000)
                        
                            // zoom konkretniho objektu
                            obj.zoom(date_start, date_end)
                        } else {
                            //try {
                                this.amObj[key].setZoom(this.amObj[key], item)
                            //} catch (err) {
                                //console.log("non-existent object.")
                            //}
                        }
                    }
                } 
            },

            /**
              * Synchronizace zoomu mezi charty podle vybraneho
              * nebo defaultni zoom v zavislosti na zobrazenchy kombinacich grafu
              */
            syncZoom() {
                // prepocet podle tabulky povolenych kombinaci zoomu
                var syncable = true
                var z = [true, true, true, true, true, true]
                for (var key in this.charts) {
                    var functionId = this.charts[key].robj.functionId

                    syncable &= this.isSyncable(key) 

                    for (var i in this.zoomTable[functionId]) {
                        z[i] &= this.zoomTable[functionId][i]
                    }
                }
                this.zoomEnabled = z

                // povoleni / zakazani cutom zoomu podle pritomnosti histogramu
                /*
                for (var key in this.charts) {
                    if (this.amObj[key] === undefined) {continue}

                    if (this.amObj[key].chartCursorSettings != undefined) {
                        this.amObj[key].chartCursorSettings.zoomable = syncable
                    }

                    if (this.amObj[key].chartCursor != undefined) {
                        this.amObj[key].chartCursor.zoomable = syncable
                    }
                }*/

                // custom zoom
                if (this.zoomActive === undefined && this.zoomCustom && this.zoomSelected !== null && syncable) {
                    this.zoom({name: "custom", days: 0, date_start: this.zoomSelected[0], date_end: this.zoomSelected[1]})
                    return 
                }

                // defaultni nebo podle talcitek
                this.zoom(this.zoomActive !== undefined ? this.zoomActive : this.zoomDefault)

                // pokud je na karte histogram je treba to jeste prekreslit
                if (!syncable) {
                    this.syncCharts()
                }
            },

            /**
              * Synchronizace pohybu kurzoru pres ruzne charty
              */
            syncCursors() {
                for (var key in this.charts) { 
                    if (!this.isSyncable(key)) {continue} 

                    for (var j = 0; j < this.amObj[key].panels.length; j++) {
                        this.amObj[key].panels[j].chartCursor.addListener("changed", this.handleCursorChange)
                        this.amObj[key].panels[j].chartCursor.addListener("onHideCursor", this.handleCursorHide)
                        this.amObj[key].panels[j].chartCursor.addListener("onShowCursor", this.handleCursorShow)
                        this.amObj[key].panels[j].chartCursor.addListener("zoomed", this.handleCursorZoom)
                        this.amObj[key].panels[j].chartCursor.addListener("panning", this.handlePanning)
                    }
                } 
            },
            handleCursorChange(event) {
                this.lastChart = event.chart
                for (var key in this.charts) {
                    if (this.amObj[key] === undefined || this.amObj[key] === null) {continue}
                    if (!this.isSyncable(key)) {continue}
 
                    for (var j = 0; j < this.amObj[key].panels.length; j++) {
                        try {
                            this.amObj[key].panels[j].chartCursor.syncWithCursor(event.chart.chartCursor)
                        } catch (err) {}
                    }
                }
            },
            handleCursorHide(event) {
                //this.zoomSelected = [event.chart.startTime, event.chart.endTime]

                for (var key in this.charts) {
                    if (this.amObj[key] === undefined || this.amObj[key] === null) {continue}
                    if (!this.isSyncable(key)) {continue}

                    for (var j = 0; j < this.amObj[key].panels.length; j++) {
                        this.amObj[key].panels[j].chartCursor.forceShow = false
                        this.amObj[key].panels[j].chartCursor.hideCursor(false)
                    }
                }
            
            },
            handleCursorShow(event) {
                if (this.lastChart === undefined) {
                    this.lastChart = this.amObj[Object.keys(this.charts)[0]]
                }

                for (var key in this.charts) { 
                    if (!this.isSyncable(key)) {continue} 

                    for (var j = 0; j < this.amObj[key].panels.length; j++) {
                        var c = this.amObj[key].panels[j].chartCursor
                        c.selectionAlpha = this.rangeActive && this.lastChart.id != c.chart.id ? 0.0 : this.paletteSelected.range_alpha
                        //c.cursorColor = this.rangeActive && this.lastChart.id != c.chart.id ? "#cc0000" : this.paletteSelected.rgb
                    }
                }
                
            },
            handleCursorZoom(event) {
                // deaktivovat tlacitka
                for (var i in this.zooms) {
                    this.zooms[i].active = false
                }

                if (event.chart.startTime === undefined || event.chart.endTime === undefined) {
                    return
                }

                this.zoomCustom = true
                this.zoomSelected = [new Date(event.chart.startTime), new Date(event.chart.endTime)]
            },
            handlePanning(event) {
                // stejny jakoby zoom
                this.handleCursorZoom(event)

                // skryt potencitalni cursory
                this.handleCursorHide(event)

                //this.zoom({name: "custom", days: 0, date_start: this.zoomSelected[0], date_end: this.zoomSelected[1]})
                this.syncZoom()
            },

            /**
              * Zavreni parseru (zmena viditelnosti)
              * ma efekt v modu modalniho okna
              */
            closeParser(key) {
                this.parsers[key].visible = !this.parsers[key].modal

                // nebylo nic vykresleno tak zase odebrat
                if (this.charts[key].status == 0) {
                    this.remove(key)
                    return
                }

                this.parsers[key].edit = true
            },

            /**
              * Otevreni parseru (zmena viditelnosti)
              * ma efekt v modu modalniho okna
              */
            openParser(key) {
                this.parsers[key].visible = true
            },

            /**
              * Barva trendLines podle konkretniho robj
              * na zaklade functionId a obsahu tickeru
              */
            getTrendLineColor(robj) {
                if (robj.functionId !== undefined) {
                    // historical spread stejny jako PriceContinous
                    if (robj.params.dlist.length > 1 && robj.params.dtype == "Historical") {
                        return this.trendLineColors["PriceContinuous"]
                    }

                    // podle definovaneho setu 
                    return this.trendLineColors[robj.functionId]
                } 

                // defaultne modra
                return "#0000ff"
            },

            setTrendLineColor(chart) {
                // JS chart
                if (chart.panels === undefined) {
                    chart.trendLineColor = this.paletteSelected.pencil_rgb
                    return
                }

                // Stock chart
                var self = this
                chart.panels.forEach(function(panel) {
                    panel.trendLineColor = self.paletteSelected.pencil_rgb
                })
            },

            /**
              * Reakce tlacitka kresleni
              */
            onPencil(tool) {
                for (var key in this.charts) {
                    if (this.amObj[key] === undefined) {continue}
                    if (this.amObj[key].type != "stock") {continue}

                    // nastavit chart objektu aktualne vykranou barvu
                    this.setTrendLineColor(this.amObj[key])

                    for (var i in this.amObj[key].panels) {
                        //this.amObj[key].panels[i].trendLineColor = this.getTrendLineColor(this.charts[key].robj)
                        var n = 0
                        while (this.amObj[key].panels[i].drawingEnabled != tool.active || n == 0) {
                            this.amObj[key].panels[i].handlePencilClick()
                            n++
                        }
                    }
                }
            },

            /**
              * Reakce tlacitka mazani 
              */
            onEraser(tool) {
                for (var key in this.charts) {
                    if (this.amObj[key] === undefined) {continue}
                    if (this.amObj[key].type != "stock") {continue}

                    for (var i in this.amObj[key].panels) {
                        while (this.amObj[key].panels[i].erasingEnabled != tool.active) {
                            this.amObj[key].panels[i].handleEraserClick()
                        }
                    }
                }
            },

            onRange(tool) {
                var color = "#ff0000"

                for (var key in this.charts) {
                    if (this.amObj[key] === undefined) {continue}
                    if (this.amObj[key].type != "stock") {continue}

                    for (var i in this.amObj[key].chartCursors) {
                        if (tool.active) {
                            this.amObj[key].chartCursors[i].selectWithoutZooming = true
                            this.amObj[key].chartCursors[i].addListener("selected", this.addGuide)
                        } else {
                            this.amObj[key].chartCursors[i].removeListener(this.amObj[key].chartCursors[i], "selected", this.addGuide)
                            this.amObj[key].chartCursors[i].selectWithoutZooming = false
                        }
                        this.amObj[key].panels[i].validateData()
                    }
                }
            },

            addGuide(e) {
                var date = e.chart.dataProvider[e.startIndex].date
                var toDate = e.chart.dataProvider[e.endIndex].date

                var guide = new window.AmCharts.Guide()
                guide.date = date
                guide.toDate = toDate
                guide.fillAlpha = this.paletteSelected.range_alpha
                guide.lineThickness = 0.0
                guide.fillColor = this.paletteSelected.range_rgb
                guide.above = true
                guide.dashLength = 0

                this.lastChart.categoryAxis.addGuide(guide)
                this.lastChart.categoryAxis.addListener("clickGuide", this.removeGuide)
                this.lastChart.validateNow()

                for (var key in this.charts) {
                    for (var i in this.amObj[key].panels) {
                        this.amObj[key].chartCursors[i].clearSelection()
                    }
                }

            },

            removeGuide(e) {
                if (!this.eraserActive) {
                    return
                }
                
                e.chart.categoryAxis.removeGuide(e.guide)
                e.chart.validateNow()
            },

            /**
              * Reakce tlacitka PL calculatoru
              */
            onCalculator(tool) {
                for (var key in this.charts) {
                    if (this.amObj[key] === undefined) {continue}
                    if (this.amObj[key].type != "stock") {continue}
                    
                    if (tool.active) {
                        this.amObj[key].chartCursors[0].addListener("draw", this.glc)
                        this.amObj[key].panels[0].trendLineColor = this.getTrendLineColor(this.charts[key].robj)
                        this.amObj[key].panels[0].trendLineDashLength = 5
                        this.amObj[key].panels[0].validateData()

                    } else {
                        this.amObj[key].chartCursors[0].removeListener(this.amObj[key].chartCursors[0], "draw", this.glc)
                        this.amObj[key].panels[0].trendLineDashLength = 0
                        this.glcClear(this.amObj[key].panels[0])    
                    }

                    for (var i in this.amObj[key].panels) {
                        while (this.amObj[key].panels[i].drawingEnabled != tool.active) {
                            this.amObj[key].panels[i].handlePencilClick()
                        }
                    }
                    
                }
            },

            /**
              * Vypocet a pridani trendlines PL calculatoru
              */
            glc(e) {
                /*  vyhodit posledni kalkulaci */
                this.glcClear(e.chart.stockChart.panels[0])

                /* transfomace ze souradnic grafu do ceny */
                var glf = e.chart.stockChart.panels[0].valueAxes[0].glc[0]
                var glt = e.chart.stockChart.panels[0].valueAxes[0].glc[1]
                var currency = e.chart.stockChart.panels[0].valueAxes[0].glc[2]
                var k = (e.chart.valueAxes[0].max-e.chart.valueAxes[0].min)/e.chart.valueAxes[0].height
                var y0 = e.chart.valueAxes[0].max - e.initialY * k
                var y1 = e.chart.valueAxes[0].max - e.finalY * k

                var g0 = y0
                var g1 = y1
                var dg = Math.abs(g1-g0)

                /* gain vs. loss */
                var gain = true
                if((e.initialX < e.finalX && e.initialY < e.finalY) || (e.initialX > e.finalX && e.initialY > e.finalY)) {
                    gain = false
                }

                /* pridat nove zobrazeni kalkulace */
                var guide = new window.AmCharts.Guide()
                guide.value = y0
                guide.toValue = y1
                guide.lineColor = e.chart.stockChart.panels[0].trendLineColor
                guide.lineAlpha = 1.0
                guide.lineThickness = 1.5
                guide.above = true
                guide.dashLength = 5
                e.chart.valueAxes[0].addGuide(guide)
                
                /* Gain/Loss label + entry/exit labels */
                e.chart.stockChart.panels[0].allLabels.push({
                    text: (gain ? "Profit: " : "Loss: ") + currency + " " + Math.round(dg*glf),
                    x: 13,
                    y: ((Math.abs(e.finalY-e.initialY) > 60) ? (e.initialY < e.finalY ? e.initialY : e.finalY)+(Math.abs(e.initialY-e.finalY)/2.0-20) : ((e.initialY > e.finalY) ? e.initialY+3 : e.finalY+3)),
                    size: 20,
                    bold: true
                })

                e.chart.stockChart.panels[0].allLabels.push({
                    text: (gain ? "Entry" : "Exit") + ": " + Math.round((y0 < y1 ) ? g0*1e4 : g1*1e4)/1e4,
                    x: 15,
                    y: (y0 < y1) ? e.initialY-20 : e.finalY-20,
                    bold: true
                })

                e.chart.stockChart.panels[0].allLabels.push({
                    text: (!gain ? "Entry" : "Exit") + ": " + Math.round((y0 < y1 ) ? g1*1e4 : g0*1e4)/1e4,
                    x: 15,
                    y: (y0 < y1) ? e.finalY-20 : e.initialY-20,
                    bold: true
                })

                e.chart.stockChart.panels[0].allLabels.push({
                    text: (glt != "intermarket") ? "" : "Warning: you need to have each leg of the spread multiplied by its point value for correct profit/loss calculation!",
                    x: 13,
                    y: e.chart.valueAxes[0].height - 20,
                    bold: true,
                    color: "#ff0000"
                })

                /* prekreslit */
                e.chart.validateNow()
            },

            /**
              * Vycisteni grafu(chartu) od PL calculatoru
              */
            glcClear(p) {
                p.valueAxes[0].guides.pop()
                p.valueAxes[0].guides.pop()

                for(var i in p.trendLines) {
                    if (p.trendLines[i] != undefined) {
                        p.trendLines[i] = (p.trendLines[i].dashLength > 0) ? undefined : p.trendLines[i]
                    }
                }

                var labels = []
                for(var i in p.allLabels) {
                    if(p.allLabels[i].id == 'copyright') {
                        labels.push(p.allLabels[i])
                    }
                }
                p.allLabels = labels

                p.validateData()
            },

            /**
              * Reakce talcitka exportu do PNG
              */
            onExport(tool) {
                if (!this.loggedIn) {return}
                this.exportPNG()
            },
            /**
              * Export vsech objeku amCharts do png
              */
            exportPNG() {
                var self = this
                var keys = Object.keys(this.charts)

                // prazdny list
                if (keys.length == 0) {return}

                // 'platno' podle poctu a velikosti chartu na listu
                var canvas = document.createElement("canvas")
                canvas.width = this.amObj[keys[0]].divRealWidth
                canvas.height = 0
                for (var key in this.charts) {
                    if (this.amObj[key] === undefined) {continue}
                    canvas.height += this.amObj[key].divRealHeight
                }

                var y = 0
                var j = 0

                // ceka dokud neni hotovo a pak nabidne stazeni
                var interval = setInterval(function() {
                    if (j < keys.length) {return}

                    clearInterval(interval)

                    var a = document.createElement("a")
                    a.setAttribute("href", canvas.toDataURL())
                    a.setAttribute("download", self.name+".png")
                    a.click()

                    y = 0
                    j = 0
                    canvas = null
                }, 100)

                for (var key in this.charts) {
                    if (this.amObj[key] === undefined) {continue}

                    var tmp = new AmCharts.AmExport(this.amObj[key])
                    tmp.init()
                    tmp.output({
                        output: 'datastring',
                        format: 'png'
                    }, function(dataURI) {
                        var img = new Image()

                        img.onload = function() {
                            var ctx = canvas.getContext("2d")
                            ctx.drawImage(img, 0, y)
                            y += this.height
                            j += 1
                        }

                        img.src = null
                        img.src = dataURI
                    })
                }
            },

            /**
              * Reakce tlacitka permalink
              */
            onPermalink(e) {
                if (!this.loggedIn) {return}
                this.$emit("onPermalink", this.getUX())

            },

            /**
              * Reakce tlacitka watchlist
              */
            onWatchlist(e) {
                if (!this.loggedIn) {return}
                this.$emit("onWatchlist", this.getUX())
            },

            /**
              * Deaktivace vsech nastroju
              */
            deactivateAllTools() {
                for (var i in this.tools) {
                    this.tools[i].active = false

                    // pouze kresleni, mazani a glc na sebe musi vzajemne reagovat
                    if (["pencil", "eraser", "range", "glc"].includes(this.tools[i].name)) {
                        this.tools[i].callback(this.tools[i])
                    }
                }
            },

            /**
              * Reakce na klik tlacitka nastroju
              * nastavuje zobrazeny stav nastroje
              */
            flipTool(tool) {
                // export nic nemeni
                if (tool.name == "export") {return}

                // pokud neni uz aktivni tak nejdriv vsechno deaktivovat
                if (!tool.active) {
                    this.deactivateAllTools()
                }

                // pouze kresleni, mazani a glc zustavaji v aktivovanem stavu
                if (["pencil", "eraser", "range", "glc"].includes(tool.name)) {
                    tool.active = !tool.active
                }

                // pro neprihlasene vzdy ronout deaktivovat
                // jde pouze o zobrazeni
                if (!this.loggedIn) {
                    tool.active = false
                }
            },

            /**
              * Synchronizace grafu se stavem nastroju
              */
            syncTools() {
                for (var i in this.tools) {
                    if (!this.tools[i].active) {continue}
                    this.tools[i].callback(this.tools[i])
                }  
            },

            /**
             * Pridani copyright info do zadaneho chartu
             */
            addCopyright(key) {
                if (this.amObj[key] == undefined) {return}
                var ch = this.amObj[key]

                if (ch.type == "serial") {
                    ch.allLabels = (ch.allLabels == undefined ? [] : ch.allLabels)
                    ch.allLabels.push({
                        x: "30", y: "!72",
                        text: "© SpreadCharts.com",
                        align: "left",
                        size: 15,
                        color: "#888",
                        alpha: 0.5,
                        bold: true,
                        id: "copyright"
                    })
                } else {
                    ch.panels[0].allLabels = (ch.panels[0].allLabels == undefined ? [] : ch.panels[0].allLabels)
                    ch.panels[0].allLabels.push({
                        x: "30", 
                        y: (ch.panels.length > 1) ? "!40" : "!62",
                        text: "© SpreadCharts.com",
                        align: "left",
                        size: 15,
                        color: "#888",
                        alpha: 0.5,
                        bold: true,
                        id: "copyright"
                    })
                }

                ch.validateData()
            },

            /**
              * Vytazeni definic trendlines z objektu chartu
              */
            getTrendLines(obj) {
                // pouze pro stockCharts
                if (obj.type != "stock") {return undefined}

                // trendlines ze vsech panelu
                var trendLines = []
                for (var i in obj.panels) {
                    var tmp = []
                    for (var j in obj.panels[i].trendLines) {
                        if (obj.panels[i].trendLines[j].dashLength != 0) {continue}
                        try {
                            tmp.push({
                                initialDate: obj.panels[i].trendLines[j].initialDate.toISOString(),        
                                finalDate: obj.panels[i].trendLines[j].finalDate.toISOString(),
                                initialValue: obj.panels[i].trendLines[j].initialValue,        
                                finalValue: obj.panels[i].trendLines[j].finalValue,
                                lineColor: obj.panels[i].trendLines[j].lineColor,
                                lineThickness: obj.panels[i].trendLines[j].lineThickness
                            })
                        } catch (e) {
                            console.log(e)
                        }
                    }
                    trendLines.push(tmp)
                }

                return trendLines
            },

            /**
              * Pridani trendlines do existujiciho objektu chartu
              */
            setTrendLines(obj, trendLines) {
                if (obj.panels !== undefined && obj.panels !== null) {
                    for (var i in trendLines) {
                        if (obj.panels[i] !== undefined) {
                            obj.panels[i].trendLines = trendLines[i]
                        }
                    }
                }
                obj.validateNow()
            },

            /**
              * Vytazeni stavu zobrazeni / skryti jednolitvych grafu na panelech
              */
            getHidden(obj) {
                // pouze pro stockCharts
                if (obj.type != "stock") {return undefined}

                try {
                    var hidden = []
                    for (var i in obj.panels) {
                        var tmp = []
                        for (var j in obj.panels[i].graphs) {
                            tmp.push(obj.panels[i].graphs[j].hidden)
                        }
                        hidden.push(tmp)
                    }
                    return hidden
                } catch(e) {
                    console.log(e)
                    return undefined
                }
            },

            /**
              * Nastaveni stavu zobrazeni / skryti jednotlivych grafu na panelech
              */
            setHidden(obj, hidden) {
                if (obj.type == "stock") {
                    for (var i in hidden) {
                        for (var j in hidden[i]) {
                            try {
                                obj.panels[i].graphs[j].hidden = hidden[i][j]
                            } catch(e) {
                                console.log(e)
                            }
                        }
                    }
                }
                obj.validateNow()
            },

            getGuides(obj) {
                if (obj.type != "stock") {return undefined}

                // guides ze vsech panelu
                var guides = []
                for (var i in obj.panels) {
                    var tmp = []
                    for (var j in obj.panels[i].categoryAxis.guides) {
                        var g = obj.panels[i].categoryAxis.guides[j]
                        try {
                            tmp.push({
                                date: g.date,
                                toDate: g.toDate,
                                fillAlpha: g.fillAlpha,
                                lineThickness: g.lineThickness,
                                fillColor: g.fillColor,
                                above: g.above,
                                dashLength: g.dashLength,
                            })
                        } catch (e) {
                            console.log(e)
                        }
                    }
                    guides.push(tmp)
                }

                return guides
            },

            setGuides(obj, guides) {
                if (obj.panels !== undefined && obj.panels !== null) {
                    for (var i in guides) {
                        if (obj.panels[i] !== undefined) {
                            obj.panels[i].guides = guides[i]
                        }
                    }
                }
                obj.validateNow()
            },

            /**
              * Sestaveni objektu UX
              */
            getUX() {
                // ulozeni pred vykreslenim charts
                // neaktivni charts => neexituji objekty => zachovava se puvodni ux
                var oc = 0 // "object count" ... pocet vykreslenych objektu na karte
                for (var key in this.charts) {
                    oc += (this.amObj[key] !== undefined) ? 1 : 0
                }
                if (oc == 0) {
                    return this.ux
                }

                // probehlo vykresleni
                // ulozi se zmenene ux
                var charts = {}
                for (var key in this.charts) {
                    charts[key] = {
                        request: this.parsers[key].ticker,
                        action: (this.parsers[key].action !== null) ? this.parsers[key].action.id : null,
                        date: this.parsers[key].date,
                        alg: this.parsers[key].alg,
                        height: this.charts[key].height,
                        trendLines: (this.amObj[key] !== undefined) ? this.getTrendLines(this.amObj[key]): undefined,
                        guides: (this.amObj[key] !== undefined) ? this.getGuides(this.amObj[key]): undefined,
                        hidden: (this.amObj[key] !== undefined) ? this.getHidden(this.amObj[key]): undefined,
                        offset: this.parsers[key].off.value === null ? 0 : parseInt(this.parsers[key].off.value)
                    }
                }

                var ux =  {
                    charts: charts,
                    zoom: this.zoomActive !== undefined ? this.zoomActive : (this.zoomCustom ? this.zoomSelected : this.zoomDefault)
                }

                return ux
            },

            /**
              * Nastaveni ux podle externich dat
              */
            setUX(ux) {
                // pridat charty
                var charts = (ux.charts !== undefined) ? ux.charts : ux // kvuli kompatibilite se starym
                for (var k in charts) {
                    var ch = charts[k]
                    
                    // pridat prazdny chart
                    // generuje si vlastni unikatni key
                    var key = this.addChart(charts[k].height)

                    // kvuli mozne neexistejci 'request' ve stare verzi
                    try {
                        if (ch.request === undefined && ch.robj !== undefined) {
                            ch.request = (Array.isArray(ch.robj)) ? ch.robj[1].raw : ch.robj.raw
                        }
                    } catch (e) {}

                    // nastaveni hodnot parseru
                    this.parsers[key].setTicker(ch.request)
                    this.parsers[key].run()
                    this.parsers[key].tickerOpened = false
                    this.parsers[key].tickerDisabled = true
                    this.parsers[key].setActionById(this.translateAction(ch)) // s prekladem akce z mozne stare verze
                    this.parsers[key].setDate(new Date().toISOString())
                    this.parsers[key].setAlg((ch.alg !== undefined) ? ch.alg : "byTS")
                    this.parsers[key].visible = Object.keys(charts).indexOf(k) == 0
                    this.parsers[key].off.value = ch.offset == 0 ? null : ch.offset

                    // trendlines k chartu k pozdejsimu pridani
                    // po graphRender
                    if (ch.trendLines !== undefined) {
                        this.charts[key].trendLines = ch.trendLines
                    }

                    // guides k chartu k pozdejsimu pridani
                    // po graphRender
                    if (ch.guides !== undefined) {
                        this.charts[key].guides = ch.guides
                    }

                    // trendlines k chartu k pozdejsimu pridani
                    // po graphRender
                    if (ch.hidden !== undefined) {
                        this.charts[key].hidden = ch.hidden
                    }
                }

                if (ux.zoom !== undefined) {
                    if (Array.isArray(ux.zoom)) {
                        this.zoomCustom = true
                        this.zoomSelected = [new Date(ux.zoom[0]), new Date(ux.zoom[1])] 
                    } else {
                        this.zoomCustom = false
                        this.zoom(ux.zoom)
                    }
                }
            },

            /**
              * Preklad akce ze stare verze ux
              */
            translateAction(chart) {
                if (chart.action === null || chart.action === undefined) {
                    return chart.action
                }

                if (chart.action.includes("term_structure")) {
                    return "ts"+chart.comodity+"1"
                }

                var cots = ["net_positions", "net_positions_index", "net_traders", "net_traders_index", "net_concentration", "net_concentration_index"]
                if (cots.includes(chart.action) || chart.action.includes(";net_")) {
                    if (chart.action.includes(";")) {
                        chart.action = chart.action.split(";")[1]
                    }
                    chart.action = "cot"+chart.comodity+(cots.indexOf(chart.action)+1)
                }

                if (chart.action == "price_volume" && chart.functionId !== undefined && chart.functionId == "priceCandlestickHistorical") {
                    chart.action = "price_hist"
                }

                if (chart.action == "price" && chart.functionId !== undefined && chart.functionId == "priceLine") {
                    chart.action = "price_under"
                }

                if (chart.action == "price" && chart.functionId !== undefined && chart.functionId == "priceCandlestickHistorical") {
                    chart.action = "price_hist"
                }

                if (chart.action == "continuous_price") {
                    chart.action = "price_con"
                }

                return chart.action
            },

            /**
              * Spusti vykresleni vsech chartu na karte
              * po prenosu UX
              */ 
            startAllCharts() {
                var i = 0
                var self = this
                var keys = Object.keys(this.parsers)
                var interval = setInterval(function() {
                    if (self.parsers[keys[i]] !== undefined && self.parsers[keys[i]] !== null) {
                        self.parsers[keys[i]].onChartClicked()
                        self.parsers[keys[i]].tickerOpened = false
                        i += 1

                        if (i == keys.length) {
                            clearInterval(interval)
                            //self.done = true
                        }

                        return
                    }

                    clearInterval(interval)
                }, 500)
            },

            toSubscription() {
                this.$emit("toSubscription")
            },

            fromSignals(signal) {
                var parser = undefined
                for (var key in this.parsers) {
                    if (!this.parsers[key].modal) {
                        parser = this.parsers[key]
                        break
                    }
                }
                if (parser === undefined) {return}

                parser.tickerDisabled = true
                parser.setTicker(signal.ticker)
                setTimeout(function() {
                    parser.setActionById(signal.type == "f" ? "price_volume" : "price_under")
                }, 25)
            }
        },
        created() {
            /* prazdno (alespon zatim) */
            if (Object.keys(this.charts).length == 0) {
                if (this.ux === undefined) {
                    this.add()
                } else {
                    this.setUX(this.ux)
                } 
            }
        },
        mounted() {
            window.AmCharts.useUTC = true;
        }
    }
</script>

<style scoped>
    div.charts-container {
    }

    div.charts-column-left,
    div.charts-column-right {
        min-height: 100px;
    }

    div.charts-column-left {
        float: left;
        width: calc(100% - 60px);
    }

    div.charts-column-right {
        float: right;
    }

    div.charts-column-right > div {
        position: fixed;
        top: 108px;
        right: 20px;
    }

    div.tools-container,
    div.zoom-container {
        background-color: white;
        text-align: right;
        box-shadow: 3px 3px 26px -3px rgba(0,0,0,0.25);
        border-radius: 5px;
        padding: 5px 0 5px 0;
    }

    div.tools-container {
        margin-bottom: 30px;
    }


    div.tool-button {
        background-color: #e7e7e7;
        height: 32px;
        width: 32px;
        line-height: 32px;
        text-align: center;
        margin: 5px 5px 0 5px;
        cursor: pointer;
        border-radius: 3px;
        font-weight: 700;
    }

    div.tool-button:first-child {
        margin-top: 0px;
    }

    div.tool-button:hover,
    div.tool-button.active{
        background-color: #bbbbbb;
    }

    div.tool-button > span > i {
        font-size: 22px;
        line-height: 32px;
        color: #555555;
    }

    div.tool-button > span {
        line-height: 22px;
    }

    div.chart-wrapper {
        background-color: white;
        min-height: 100px;
    }

    div.add-chart-wrapper {
        text-align: center;
        padding: 10px 0 10px 0;
        margin-bottom: 120px;
    }

    div.chart-content {
        height: 100%;
    }

    div.chart-target {
        height: 100%;
    }

    div.chart-msg {
        position: relative;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.25);
        vertical-align: middle;
        top: calc(50% - 25px);
        height: 50px;
        line-height: 50px;
    }

    div.chart-floater {
        position: absolute;
        right: 85px;
        z-index: 1;
        margin-top: 5px; 
        font-size: 22px;
        font-weight: 700;
    }

    div.chart-floater > div {
        border: 1px solid rgba(200, 200, 200, 0.75);
        padding-bottom: 5px;
        color: #666666;
        border-radius: 5px;
        margin-bottom: 5px;
    }

    div.chart-floater > div > div.icon,
    div.paletteWrapper > div {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 3px;
        margin: 5px 5px 0 5px;
        cursor: pointer;
    }

    div.parser-disabled-placeholder {
        background-color: #cccccc;
        height: 59px;
    }

    div.paletteWrapper > div {
        height: 32px;
        width: 32px;
    }

.premium-message {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
}

.subscription-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
}

.subscription-button:hover {
  background-color: #0056b3;
}
</style>

