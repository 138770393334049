
import defaultCategoryAxesSettings from './commonFunctions.js'
import defaultPanelSettings from './commonFunctions.js'
import defaultBalloon from './commonFunctions.js'
import isTouch from './commonFunctions.js'

export default {
    mixins:[defaultCategoryAxesSettings, defaultPanelSettings, defaultBalloon, isTouch],
    methods: { 
        PriceContinuous(payload) {
            return window.AmCharts.makeChart(payload.targetId, {
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                dataSets: [{
                    fieldMappings: [
                        {fromField:"date", toField:"date"},
                        {fromField: "value0", toField: "value"},
                        {fromField: "i", toField: "i"},
                        {fromField: "gap", toField: "gap"},
                        {fromField: "color", toField: "color"},
                        {fromField:"month_open", toField:"month_open"},
                        {fromField:"month_high", toField:"month_high"},
                        {fromField:"month_low", toField:"month_low"},
                        {fromField:"month_close", toField:"month_close"},
                        {fromField:"week_open", toField:"week_open"},
                        {fromField:"week_high", toField:"week_high"},
                        {fromField:"week_low", toField:"week_low"},
                        {fromField:"week_close", toField:"week_close"}
                    ],
                    dataProvider: payload.data,
                    categoryField: "date"
                }],
                categoryAxesSettings: this.defaultCategoryAxesSettings(payload.functionId),
                categoryAxis: {
                    gridPosition: "start",
                    showFirstLabel: false,
                },
                panels: [{ // price
                    title: "Continuous price:",
                    showCategoryAxis: true,
                    percentHeight: 75,
                    valueAxes: [{
                        position: "right",
                        glc: payload.misc.glc, // vlastni parametr pro kalkulator
                        fontSize: 13,
                        logarithmic: false
                    }],
                    stockGraphs: [{
                        id: "g_price",
                        valueField: "value",
                        gapField: "gap",
                        lineColorField: "color",
                        lineColor: "#32cd32",
                        fillColors: "#32cd32",
                        negativeLineColor: "#db4c3c",
                        negativeFillColors: "#db4c3c",
                        type: "line",
                        lineThickness: 2,
                        fillAlphas: false,
                        useDataSetColors: false,
                        legendColor: "#0000ff",
                        balloonFunction: function(item) {
                            var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                            return  payload.labels[(item.dataContext.i !== undefined) ? item.dataContext.i : item.dataContext.dataContext.i] + ": " + value
                        },
                        /*
                        balloonFunction: function(item) {
                            if (item.dataContext.i === undefined) {
                                return payload.labels[item.dataContext.dataContext.i] + ": " + item.values.value
                            }
                            return payload.labels[item.dataContext.i] + ": " + item.values.value
                        }*/
                    }],
                    allLabels: function(payload) {
                        var y = 0
                        var labels = []

                        if (payload.misc.offset < 0) {
                            labels.push({
                                text: "Contract window shifted "+parseInt(-1*payload.misc.offset)+" calendar days backward",
                                bold: true,
                                size: 15,
                                x: 40, y: y,
                                alpha: 0.7
                            })
                            y += 20
                        } else if (payload.misc.offset > 0) {
                            labels.push({
                                text: "Contract window shifted "+parseInt(payload.misc.offset)+" calendar days forward",
                                bold: true,
                                size: 15,
                                x: 40, y: y,
                                alpha: 0.7
                            })
                            y += 20
                        }

                        return labels
                    } (payload),
                    stockLegend: {
                        labelText: payload.labels[0],
                        fontSize: 13,
                        valueFunction: function(item) {
                            if (item.values === undefined) {return ""}
                            var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                            return (isNaN(value)) ? "" : value
                        }
                    },
                }],
                chartCursorSettings: {
                    categoryBalloonEnabled: true,
                    valueBalloonsEnabled: true,
                    //pan : is_touch_device(), !!!!!!!!!!!!!!!
                    pan: this.isTouch(),
                    valueLineEnabled : true,
                    valueLineBalloonEnabled : true,
                    zoomable: true
                },
                chartScrollbarSettings: {
                    enabled: false
                },
                panelsSettings: this.defaultPanelSettings(payload.functionId),
                valueAxesSettings: {
                    inside: false,
                    dashLength: 0,
                    showFirstLabel: false
                },
                balloon: this.defaultBalloon(payload.functionId),
                listeners: [{
                    event: "zoomed",
                    method: function(event) {
                        var g = event.chart.panels[0].stockGraphs[0]

                        if (event.period == "DD") {
                            g.type = "line"
                            g.lineThickness = 2
                            g.lineColorField = "color"
                            g.lineColor = undefined
                            g.fillColors = undefined
                            g.negativeLineColor = undefined
                            g.negativeFillColors = undefined
                        } else {
                            g.type = "candlestick"
                            g.lineThickness = 1
                            g.lineColor = "#32cd32"
                            g.lineColorField = "color"
                            g.lineColor = "#32cd32"
                            g.fillColors = "#32cd32"
                            g.negativeLineColor = "#db4c3c"
                            g.negativeFillColors = "#db4c3c"
                        }

                        if (event.period == "DD") {
                            g.valueField = "value"
                            g.openField = undefined
                            g.highField = undefined
                            g.lowField = undefined
                            g.closeField = undefined
                        } else if (event.period == "WW") {
                            g.valueField = "week_close"
                            g.openField = "week_open"
                            g.highField = "week_high"
                            g.lowField = "week_low"
                            g.closeField = "week_close"
                        } else if (event.period == "MM") {
                            g.valueField = "month_close"
                            g.openField = "month_open"
                            g.highField = "month_high"
                            g.lowField = "month_low"
                            g.closeField = "month_close"
                        }

                        event.chart.validateData()
                    }
                }]
            })
        },
    }
}
