<template>
    <div class="container" :class="{'opened': edit}">
        <div class="tag-list" :class="{'opened': edit}">
            <div v-if="tagIds.length == 0 && edit">
                <div style="padding-top: 5px">
                    No tags assigned. Select them below.
                </div>
            </div>
            <div v-else>
                <div
                    v-on:mousedown.stop 
                    class="tag-box" :class="{'opened': edit}" 
                    v-for="tagId in tagIds" 
                    v-if="tagBase[tagId] !== undefined"
                    v-bind:style="{'background-color': tagBase[tagId].color}" 
                    @click="unselectTag(tagId)"
                >
                    {{tagBase[tagId].name}}
                </div>
            </div>
        </div>
        <div class="tag-select bottom" v-show="edit">
            <div v-if="tagIds.length == Object.keys(tagBase).length && edit">
                <div style="padding-top: 5px">
                    All tags assigned.
                </div>
            </div>
            <div v-else>
                <div class="tag-box" :class="{'opened': edit}" v-if="!tagIds.includes(tagId)" v-for="(tag, tagId) in tagBase" v-bind:style="{'background-color': tag.color}" @click="selectTag(tagId)">
                    {{tag.name}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TagEditor',
        props: {
        },
        data() {
            return {
                edit: false,
                opened: false,
                tagBase: {},
                tagIds: []
            }
        },
        methods: {
            setTags(tagBase, tagIds) {
                this.tagBase = tagBase
                this.tagIds = tagIds
            },

            setEdit(value) {
                this.edit = value
            },

            open(e) {
                this.opened = true
            },

            /**
              * Pridani tagu mezi vybrane
              */
            selectTag(tagId) {
                this.tagIds.push(tagId)
            },

            /**
              * Obebrani tagu z vybranych
              */
            unselectTag(tagId) {
                if (!this.edit) {return} // pouze ve stavu editace

                var tmp = []
                for (var i in this.tagIds) {
                    if (tagId == this.tagIds[i]) {continue}
                    tmp.push(this.tagIds[i])
                }
                this.tagIds = tmp
            },

            getSelected() {
                return this.tagIds
            }
        },
        mounted() {
        },
        watch: {
        }
    }
</script>

<style scoped>
    div.container {
        border: 1px solid transparent;
        border-radius: 5px;
        background-color: none;
    }

    div.container.opened {
        background-color: white;
        border: 1px solid #cdcdcd;
        width: 400px;
        margin: auto;
    }

    div.tag-box {
        display: inline-block;
        padding: 5px 8px 5px 8px;
        margin: 0px 5px 5px 0;
        border-radius: 5px;
    }

    div.tag-box:first-child {
        margin-left: 5px;
    } 

    div.tag-box.opened {
        cursor: pointer;
    }

    div.add-box {
        background-color: black;
        color: white;
    }

    div.tag-list {
        margin-top: 5px;
        min-height: 35px;
    }

    div.tag-list.opened {
        border-bottom: 2px solid #cdcdcd;
    }

    div.tag-select {
        margin-top: 5px;
        border-radius: 5px;
        background-color: inherit;
        width: 100%;
        min-height: 35px;
    }
</style>
