<template>
    <div class="signals-container" id="signal-container">
        
        <div v-if="loggedIn">

            <div v-if="loaded">
                <div style="margin-bottom: 30px;">
                    <div v-show="signal.visible" v-for="signal in signals" class="signal-box" :class="{'signal-box-out': signal.out, 'signal-box-in': !signal.out, 'signal-marked': signal.marked}" :id="'signal-'+signal.id">
                        <div class="signal-content">
                            <div v-if="signal.enabled" class="signal-rollbtn" @click="graph(signal)">
                                <i v-if="signal.out" class="icons8-collapse"></i>
                                <i v-else class="icons8-expand-filled"></i>
                            </div>
                            <div v-if="signal.enabled" class="signal-img" @click="graph(signal)">
                                <img :src="getPNG(signal)" />
                            </div>
                            <div v-else class="signal-img"></div>

                            <div class="signal-info" >
                                <div v-if="signal.enabled" 
                                    class="signal-ticker" 
                                    v-bind:class="{'signal-ticker-blue': signal.type == 'id', 'signal-ticker-red': signal.type ==  'f', 'signal-ticker-green': signal.type == 'im'}"
                                    @click="toCharts(signal)"
                                >
                                    {{getTicker(signal)}}
                                </div>

                                <div class="signal-stats">

                                    <div class="signal-stats-label">
                                        Description:
                                    </div>
                                    <div class="signal-desc" :id="'signal-desc-'+signal.id">
                                        {{signal.desc_0}}
                                    </div>
                                    <div class="signal-desc" :id="'signal-desc-'+signal.id">
                                        {{signal.desc_1}}
                                    </div>

                                    <div class="signal-stats-label">
                                        Category:
                                    </div>
                                    <div class="signal-desc" :id="'signal-desc-'+signal.id">
                                        {{getCategory(signal)}}
                                    </div>

                                </div>

                                <div class="sub-button-wrapper" v-if="!signal.enabled">
                                    <input type="button" class="button-lightred" value="Get this trading signal" @click="toSubscription"/>
                                </div>

                            </div>

                            <div class="signal-separator"></div>

                            <div class="signal-graph" v-show="signal.out">
                                <div class="signal-target" v-if="signal.loading">
                                    <div class="loader">
                                        <!--
                                        <loader />
                                        -->
                                    </div>
                                </div>
                                <div class="signal-target" v-if="signal.loading">
                                    <div class="loader">
                                        <div v-show=signal.error>
                                            <div class="signal-msg">{{signal.msg}}</div>
                                        </div>
                                        <div v-show="!signal.error">
                                            <loader />
                                        </div>
                                    </div>
                                </div>
                                <div class="signal-target" v-if="signal.loading">
                                    <div class="loader">
                                        <!--
                                        <loader />
                                        -->
                                    </div>
                                </div>

                                <div v-show="!signal.loading && signal.showTarget" :id="'signal-target-'+signal.id+'_cot'" class="signal-target"></div>
                                <div v-show="!signal.loading && signal.showTarget" :id="'signal-target-'+signal.id+'_avg'" class="signal-target"></div>
                                <div v-show="!signal.loading && signal.showTarget" :id="'signal-target-'+signal.id+'_strength'" class="signal-target"></div>
                            </div>
                        </div>

                    </div>

                    <div class="signals-disclaimer">
                        <div>
                            Trading signals are generated by a complex machine learning model and are intended for educational purposes only. They are NOT an indication to make actual trades. We DO NOT provide investment advice. SpreadCharts s.r.o. (the company) and its representatives bear no responsibility for actions taken under the influence of trading signals or any other information published anywhere on this website, its parent domain, or sub-domains.
                        </div>
                        <div>
                            You can find a detailed explanation of the signals in the <a href="https://spreadcharts.com/tutorial/#signals" target="_blank">dedicated tutorial video</a>. Some frequently asked questions are answered <a href="https://spreadcharts.com/trading-signals-questions-and-answers-part-1/" target="_blank">here</a>.
                        </div>
                        <div>
                            <b>CFTC Rule 4.41:</b> Hypothetical or simulated performance results have certain limitations. Unlike an actual performance record, simulated results do not represent actual trading. Also, since the trades have not been executed, the results may have under-or-over compensated for the impact, if any, of certain market factors, such as lack of liquidity. Simulated trading programs, in general, are also subject to the fact that they are designed with the benefit of hindsight. No representation is being made that any account will or is likely to achieve profit or losses similar to those shown. All information on this website is for educational purposes only and is not intended to provide financial advice. Any statements about profits or income expressed or implied, do not represent a guarantee. Your actual trading may result in losses as no trading system is guaranteed. You accept full responsibilities for your actions, trades, profit or loss, and agree to hold SpreadCharts s.r.o. (the company) and any authorized distributors of this information harmless in any and all ways.
                        </div>
                    </div>

                    <div class="signals-filters">
                        <input type="text" autocomplete="off" placeholder="Filter by ticker" v-model="filters.ticker" @input="filter"/>
                        <select v-model="filters.type" @change="filter">
                            <option value="all">All</option>
                            <option value="f">Futures only</option>
                            <option value="id">Interdelivery only</option>
                            <!--
                            <option value="im">Intermarket only</option>
                            -->
                        </select>
                    </div>
                </div>

            </div>
            <div v-else>
            </div>

        </div>

        <div v-else>
            <div class="report-box msg-nouser">
                Please sign in first to use this tool
            </div>
        </div>

    </div> 
</template>

<script>
    import {dSource} from './vars.js';

    import loader from './loader.vue';

    import signalCOT from './charts_js/signal.js';
    import signalAverage from './charts_js/signal.js';
    import signalStrength from './charts_js/signal.js'

    import addCopyright from './charts_js/commonFunctions.js';

    import axios from 'axios';

    export default {
        name: 'Signals',
        posts: [],
        components: {loader},
        mixins: [signalCOT, signalAverage, signalStrength, addCopyright],
        props: {
            user: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                dSource: dSource,
                signals: [],
                loaded: false,
                amObj: {},
                filters: {
                    ticker: "",
                    type: "all"
                },

                scroll: 0
            }
        },
        methods: {
            setSignals(data) {
                var tmp = JSON.parse(JSON.stringify(data))

                for (var i in tmp) {
                    tmp[i].out = false
                    tmp[i].loading = false
                    tmp[i].done = false
                    tmp[i].showTarget = false
                    tmp[i].visible = true
                    tmp[i].marked = false
                    //tmp[i].desc_0 = tmp[i].desc_0.replace(/ /g, "&nbsp;")
                }

                this.signals = tmp
                this.loaded = true

                this.$forceUpdate()
            },

            filter(e) {
                for (var i in this.signals) {
                    var signal = this.signals[i]
                    var ticker = this.getTicker(signal)
                    var tval = this.filters.ticker.toUpperCase()

                    // filter podle tickeru
                    // testuje zacatky vsech obsazenych kontraktu proti kodnote v inputu
                    signal.visible = true
                    if (tval.length != 0 && !tval.includes("@")) {
                        var tcount = 0
                        ticker.split(/[\-\+\/\*]+/).forEach(function(tic) {
                            tcount += tic.startsWith(tval) ? 1 : 0
                        })
                        signal.visible &= tcount > 0
                    }

                    // filter podle categorie (futures, interdelivery, intermarket)
                    if (this.filters.type == "all") {continue}
                    signal.visible &= (this.filters.type == signal.type)
                }
            },

            getTicker(s) {
                if (s.type == "f") {
                    return s.tckr_0
                }

                if (s.type == "id") {
                    return s.tckr_0 + "-" + s.tckr_1
                }
            },

            getCategory(s) {
                return {"f": "Futures", "id": "Interdelivery", "im": "Intermarket"}[s.type]
            },

            getPNG(signal) {
                return "data:image/png;base64,"+signal.png
            },

            graph(s) {
                if (s.done) {
                    if (s.out) {
                        s.out = false
                        s.showTarget = false
                        return
                    }

                    s.out = true
                    setTimeout(function() {s.showTarget = true}, 550)

                    return
                }

                s.out = !s.out

                if (s.out) {
                    var self = this
                    setTimeout(function() {
                        self.loadGraph(s)        
                    }, 1000)
                }
            },

            getRobj(s) {
                var robj = {
                    targetId: "signal-target-"+s.id,
                    raw: this.getTicker(s),
                    functionId: "signal",
                    rkeys: ["result"],
                    user: this.user,
                    chain: [
                        {action: "signal", i:0, dkey:"result", request: {method: "getGraph", signal:s, ticker:this.getTicker(s), commodity:s.cmdty_0}}
                    ]
                }

                return robj
            },

            loadGraph(signal) {
                signal.loading = true
                signal.error = false
                var robj = this.getRobj(signal)
                axios.post(this.dSource, {robj: robj}).then(payload => this.loadGraphCallback(signal, payload.data))
            },

            loadGraphCallback(signal, payload) {
                if (!payload.check) {
                    signal.msg = payload.msg
                    signal.error = true
                    this.$forceUpdate()
                    return
                }

                // test ze je oddil signals aktivni
                var el = document.getElementById(payload.targetId+"_cot")
                if (el === null || el === undefined) {
                    return
                }
                    
                payload.data = payload.data.result
                signal.loading = false
                this.render(payload)
                signal.done = true
                signal.showTarget = true
            },

            isWeekend(d) {
                d = new Date(d)
                return (d.getUTCDay() == 6 || d.getUTCDay() == 0)
            },

            render(payload) {
                // odstraneni vikendu
                // data average jsou prirozene interpolovana (v charts primo soucasti sync ale tady ne!!)
                var tmp = []
                for (var i in payload.data) {
                    if (this.isWeekend(payload.data[i].date)) {continue}
                    tmp.push(payload.data[i])
                }
                payload.data = tmp

                var self = this
                setTimeout(function() {
                    var amObj = {}

                    amObj["cot"] = self.signalCOT(payload)
                    amObj["cot"].targetId = payload.targetId

                    amObj["avg"] = self.signalAverage(payload)
                    amObj["avg"].targetId = payload.targetId

                    amObj["strength"] = self.signalStrength(payload)
                    amObj["strength"].targetId = payload.targetId
                    self.addCopyright(amObj["strength"])

                    self.amObj[payload.targetId] = amObj

                    // a jeste trochu vic uchylnosti
                    setTimeout(function() {
                        self.syncCursors(amObj)        
                    }, 500)
                }, 200)
            },

            syncCursors(amObj) {
                for (var key in {"cot":0, "avg":0, "strength":0}) {
                    amObj[key].panels[0].chartCursor.addListener("changed", this.handleCursorChange)
                    amObj[key].panels[0].chartCursor.addListener("onHideCursor", this.handleCursorHide)
                }
            },

            handleCursorChange(event) {
                var amObj = this.amObj[event.chart.stockChart.targetId]
                for (var key in amObj) {
                    if (amObj[key] === undefined || amObj[key] === null) {continue}
                    if (amObj[key].panels === undefined) {continue}
 
                    try {
                        amObj[key].panels[0].chartCursor.syncWithCursor(event.chart.chartCursor)
                    } catch (err) {}
                }
            },

            handleCursorHide(event) {
                var amObj = this.amObj[event.chart.stockChart.targetId]

                for (var key in amObj) {

                    if (amObj[key] === undefined || amObj[key] === null) {continue}
                    if (amObj[key].panels === undefined) {continue}

                    amObj[key].panels[0].chartCursor.forceShow = false
                    amObj[key].panels[0].chartCursor.hideCursor(false)
                }
            },

            toCharts(signal) {
                this.$emit("toCharts", {ticker: this.getTicker(signal), type: signal.type})
            },

            toSubscription() {
                this.$emit("toSubscription")
            },

            openSignal(signal_id) {
                // oznaceni vybraneho
                for (var i in this.signals) {
                    this.signals[i].marked = this.signals[i].id == signal_id
                }

                // v pripade 'odznaceni' vsech
                if (signal_id < 0) {return}

                // blikani
                setTimeout(function() {
                    try {
                        var e1 = document.querySelector("div.signal-marked").getBoundingClientRect()
                        var e = document.getElementById("category-container")
                        e.scrollBy({top: e1.top - 300, behavior: "smooth"})
                    } catch(err) {
                        console.log(err)
                    }
                }, 200)
            },

            reload() {
                var self = this
                this.signals.forEach(function(signal) {
                    if (!signal.done && signal.loading) {
                        self.loadGraph(signal)
                    }
                })
            },

            getScroll() {
                return this.scroll
            },

            saveScroll(value) {
                this.scroll = value
            }
        },
        computed: {
            loggedIn() {
                return this.user.id > 0
            }
        }
    }
</script>

<style scoped>
    div.signals-container {
        margin-bottom: 10px;
        min-height: 800px;
        border-radius: 5px;
    }

    div.signals-header {
        background-color: #d9f2f9;
        height: 30px;
        border-radius: inherit;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    div.signal-box {
        display: inline-block;
        box-shadow: 3px 0px 26px -3px rgba(0,0,0,0.25);
        border: 1px solid #bbbbbb;
        border-radius: 5px;
        margin-bottom: 10px;
        background-color: #ffffff;
    }

    @media screen and (max-width: 1299px) {
        div.signal-box {
            width: calc(100%);
        }

        div.signal-box-out, div.signals-disclaimer {
            width: calc(100%);
        }

        div.signals-filters {
            right: 18px;
        }
    }

    @media screen and (min-width: 1300px) and (max-width: 1799px) {
        div.signal-box {
            width: calc(50% - 12px);
            margin-right: 10px;
        }

        div.signal-box-out, div.signals-disclaimer {
            width: calc(100% - 12px);
        }

        div.signals-filters {
            right: 30px;
        }
    }

    @media screen and (min-width: 1800px) and (max-width: 2499px) {
        div.signal-box {
            width: calc(33% - 10px);
            margin-right: 10px;
        }

        div.signal-box-out, div.signals-disclaimer {
            width: calc(100% - 25px);
        }

        div.signals-filters {
            right: 40px;
        }
    }

    @media screen and (min-width: 2500px) {
        div.signal-box {
            width: calc(25% - 12px);
            margin-right: 10px;
        }

        div.signal-box-out, div.signals-disclaimer {
            width: calc(100% - 25px);
        }

        div.signals-filters {
            right: 40px;
        }
    }

    div.signal-box-in {
        height: 217px;
        -webkit-transition: height 0.5s;
        -webkit-transition: width 0.5s;
    }

    div.signal-box-out {
        height: 1010px;
        -webkit-transition: width 0.5s;
        -webkit-transition: height 0.5s;
    }

    div.signal-content {
        padding: 5px;
    }

    div.signals-filters {
        border: 1px solid #dddddd;
        border-bottom: none;
        position: fixed;
        bottom: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: #d9f2f9;
        padding: 5px;
        box-shadow: 3px 0px 26px -3px rgba(0,0,0,0.25);
    }

    div.signals-filters > input[type="text"] {
        height: 30px;
        border-radius: 3px;
        border: 1px solid #dddddd;
    }

    div.signals-filters > input[type="text"], div.signals-filters > select {
        width: 150px;
        margin: 3px;
        padding: none;
        padding-left: 5px;
        border: 1px solid #dddddd;
    }

    div.signals-disclaimer {
        margin-top: 20px;
        box-shadow: 3px 0px 26px -3px rgba(0,0,0,0.25);
        border: 1px solid #bbbbbb;
        border-radius: 5px;
        color: #555555;
        background-color: #ffffff;
        font-size: 13px;
    }

    div.signals-disclaimer > div {
        margin: 10px 10px 10px 10px;

    }

    div.signals-disclaimer > div > a, div.signals-disclaimer > div > a:visited {
        color: blue;
        text-decoration: none;
        font-weight: bold;
    }

    div.signal-img{
        height: 206px;
        cursor: pointer;
        display: inline-block;

    }

    div.signal-img > img {
        height: calc(100%);
    }


    div.signal-target {
        height: 250px;
        margin-top: 10px;
    }

    div.signal-target > div.loader {
        position: relative;
        top: 130px;
    }

    div.signal-separator {
        height: 2px;
        background-color: #eeeeee;
        margin-top: 10px;
        margin-left: 2px;
        margin-right: 2px;
    }

    div.signal-info {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 335px);
        padding-left: 5px;
    }

    div.signal-stats-label {
        color: #777777;
        margin-bottom: 0px;
        font-size: 13px;
        margin-top: 10px;
    }

    div.signal-rollbtn {
        width: 50px; 
        height: 201px; 
        display: inline-block; 
        border: 1px solid #bbbbbb; 
        margin-right: 2px; 
        cursor: pointer; 
        background-color: #ffffff; 
        border-radius: 3px;
        position: relative;
        vertical-align: top;
        top: 2px;
    }

    div.signal-rollbtn > i {
        font-size: 35px; 
        display: inline-block; 
        margin-left: 7px; 
        margin-top: 75px; 
        color: #666
    }
    div.signal-rollbtn:hover {
        background-color: #eeeeee;
    }

    div.signal-ticker {
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
    }

    div.signal-ticker-blue {
        color: blue;
    }

    div.signal-ticker-red {
        color: red;
    }

    div.signal-ticker-green {
        color: green;
    }

    div.signal-stats {
        font-size: 16px;
    }

    div.signal-stats > div.signal-desc {
        white-space: nowrap;    
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
        font-weight: bold;
        color: #555555;
    }

    div.sub-button-wrapper {
        margin-top: 35px;
    }

    div.report-box { 
        border: 1px solid #eee;
        border-radius: 10px;
        background-color: #ffffff;
        margin: auto;
        margin-bottom: 10px;
        box-shadow: 3px 0px 26px -3px rgba(0,0,0,0.25);
        position: relative;
        width: calc(100%);
        font-size: 15px;
    }

    div.msg-nouser {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #aaaaaa;
        padding: 20px 0px 20px 0px;
        width: 500px;
        margin-top: calc(100vh / 2 - 90px);
    }

    div.signal-marked {
        animation: colorchange 1s;
        -webkit-animation: colorchange 1s;
        animation-iteration-count: 5;
    }

    @keyframes colorchange {
        0%   {background: white;}
        50%   {background: rgba(255, 238, 88, 0.3);}
        100%   {background: white;}
    }

    @-webkit-keyframes colorchange { 
        0%   {background: white;}
        50%   {background: rgba(255, 238, 88, 0.3);}
        100%   {background: white;}
    }

    div.signal-msg {
        text-align: center;
        color: #eeeeee;
       font-size: 20px;
       font-weight: 700;
    }

</style>
