<template>
    <div class="container" v-click-outside="clickedOutside">
        <div class="clickRow" @click="flipContent">
            Select by tag
            <div class="arrow-box">
                <i v-if="contentEnabled" class="icons8-chevron-up-filled" />
                <i v-else class="icons8-chevron-down-filled" />
            </div>
        </div>

        <div v-show="contentEnabled" class="content">
            <div class="tagList" v-show="state == 0">
                <table class="tagTable">
                    <thead @click="enabledAll = !enabledAll; enableAll(); filter()">
                        <tr>
                            <th class="check"><input type="checkbox" v-model="enabledAll" @change="enableAll"/></th>
                            <th class="name">Name</th>
                            <th class="color">Color</th>
                            <th class="actions">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr @click="enabledUnassigned = !enabledUnassigned; filter()">
                            <td class="check"><input type="checkbox" v-model="enabledUnassigned" @change="filter"/></td>
                            <td class="name">unassinged</td>
                            <td class="color">
                            </td>
                            <td class="actions"></td>
                        </tr>
                        <tr v-for="(tag, tagId) in tags" @click="tag.enabled = !tag.enabled; filter()" v-if="!deletedIds.includes(tagId)" >
                            <td class="check"><input type="checkbox" v-model="tag.enabled" @change="filter"/></td>
                            <td class="name">{{tag.name}}</td>
                            <td class="color">
                                <div :style="{'background-color': tag.color}"></div>
                            </td>
                            <td class="actions" @click.stop>
                                <i class="icons8-edit" @click="editTag(tagId)"/>
                                <i class="icons8-trash" @click="deleteBefore(tagId)"/>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="add">
                    <div @click="addTag">
                        <i class="icons8-plus-math-4"/> Add tag
                    </div>
                </div>
            </div>
            <div v-show="state == 1">

                <div class="tagForm">
                    <div class="label">
                        Name:
                    </div>

                    <div class="formElement">
                        <input type="text" placeholder="New tag" v-model="form.name"/>
                    </div>

                    <div class="label">
                        Color:
                    </div>
                    <div class="formElement">
                        <div class="colorList">
                            <table class="colorTable">
                                <tr v-for="i in [0, 1, 2]">
                                    <td v-for="j in [0, 1, 2, 3, 4, 5, 6]">
                                        <div v-if="colors[i][j] !== undefined" 
                                            :style="{'background-color': colors[i][j]}" 
                                            class="colorSquare" 
                                            :class="{'selected': colors[i][j] == form.color}"
                                            @click="form.color = colors[i][j]"
                                        >
                                            <i class="icons8-checkmark"/>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="formElement">
                        <div class="formButtons">
                            <input type="button" class="button-red" value="Cancel" @click="showList"/>
                            <input v-if="editId == null" type="button" :class="{'button-green': formValid, 'button-grey': !formValid}" value="Add" @click="added" />
                            <input v-else type="button" :class="{'button-green': formValid, 'button-grey': !formValid}" value="Apply" @click="edited" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="state == 2">
                <div style="margin: 110px 0 20px 0">Do you really want to delete this tag?</div>
                <div>
                    <input type="button" class="button-green" value="Yes" @click="deleted"/>
                    <input type="button" class="button-red" value="No" @click="state = 0"/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    
    export default {
        name: 'TagManager',
        data() {
            return {
                contentEnabled: false,

                tags: {},

                state: 0,

                colors: [
                    [
                        "rgba(239, 83, 80, 0.75)", "rgba(236, 64, 122, 0.75)",
                        "rgba(171, 71, 188, 0.75)", "rgba(126, 87, 194, 0.75)",
                        "rgba(92, 107, 192, 0.75)", "rgba(66, 165, 245, 0.75)", "rgba(41, 182, 246, 0.75)"],
                    [
                        "rgba(38, 198, 218, 0.75)", "rgba(38, 166, 154, 0.75)",
                        "rgba(102, 187, 106, 0.75)", "rgba(156, 204, 101, 0.75)",
                        "rgba(212, 225, 87, 0.75)", "rgba(255, 238, 88, 0.75)", "rgba(255, 202, 40, 0.75)"],
                    [
                        "rgba(255, 167, 38, 0.75)", "rgba(255, 112, 67, 0.75)",
                        "rgba(141, 110, 99, 0.75)", "rgba(189, 189, 189, 0.75)", "rgba(120, 144, 156, 0.75)"]
                ],

                form: {
                    name: null,
                    color: null
                },

                editId: null,
                deleteId: null,
                deletedIds: [],

                enabledAll: true,
                enabledUnassigned: true
            }
        },
        directives: {
            ClickOutside
        },
        computed: {
            formValid() {
                return this.form.name !== null && this.form.name.length > 0 && this.form.color !== null
            }  
        },
        methods: {
            flipContent() {
                this.contentEnabled = !this.contentEnabled
                
                if (!this.contentEnabled) {
                    this.state = 0
                    this.clearForm()
                }
            },

            clickedOutside() {
                this.contentEnabled = false
                this.state = 0
                this.clearForm()
            },

            setUnassigned(value) {
                this.enabledUnassigned = value
            },

            setTags(tags) {
                this.tags = JSON.parse(JSON.stringify(tags))
                this.reloadEnabledAll()
            },

            addTag() {
                this.state = 1
                this.clearForm()
            },

            editTag(tagId) {
                this.state = 1
                this.form.name = this.tags[tagId].name
                this.form.color = this.tags[tagId].color
                this.editId = tagId
            },

            edited() {
                if (!this.formValid) {return}
                this.tags[this.editId].name = this.form.name
                this.tags[this.editId].color = this.form.color

                var data = JSON.parse(JSON.stringify({
                    id: this.editId,
                    name: this.form.name,
                    color: this.form.color
                }))

                this.state = 0
                this.clearForm()

                this.$emit("edited", data)
            },

            deleteBefore(tagId) {
                this.state = 2
                this.deleteId = tagId
            },

            deleted() {
                if (this.deleteId === null) {return}

                this.deletedIds.push(this.deleteId)

                var tagId = this.deleteId
                var tmp = {}
                for (var key in this.tags) {
                    if (key == tagId) {continue}

                    tmp[key] = this.tags[key]
                }

                this.tags = tmp

                this.state = 0
                this.deleteId = null
                this.$emit("deleted", tagId)
            },

            added() {
                var data = JSON.parse(JSON.stringify({
                    enabled: true,
                    name: this.form.name,
                    color: this.form.color
                }))

                this.clearForm()
                this.state = 0

                this.$emit("added", data)
            },

            showList() {
                this.state = 0
            },

            clearForm() {
                this.form.name = null
                this.form.color = null
                this.editId = null
            },

            enableAll() {
                this.enabledUnassigned = this.enabledAll
                for (var tagId in this.tags) {
                    this.tags[tagId].enabled = this.enabledAll
                }
                this.filter()
            },

            filter() {
                var enabled = {unassigned: this.enabledUnassigned}

                for (var tagId in this.tags) {
                    enabled[tagId] = this.tags[tagId].enabled
                }

                this.reloadEnabledAll()
                this.$emit("filtered", enabled)
            },

            reloadEnabledAll() {
                var all = this.enabledUnassigned
                for (var tagId in this.tags) {
                    all &= this.tags[tagId].enabled
                }
                this.enabledAll = all
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
    div.container {
        text-align: right;
    }

    div.clickRow {
        font-size: 15px;
        font-weight: normal;
        padding: 6px 0 6px 8px;
        margin-top: -6px;
        width: 200px;
        background-color: white;
        border-radius: 4px;
        border: 1px solid #cccccc;
        cursor: pointer;
        text-align: left;
        color: #777777;
    }

    div.content {
        border: 1px solid #cccccc;
        border-radius: 5px;
        min-height: 300px;
        width: 500px;
        background-color: white;
        position: absolute;
        margin-left: -314px;
        margin-top: 2px;
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.25) 3px 0px 26px -3px;
    }

    div.content > div {
        text-align: center;
    }

    table.tagTable {
        width: 100%;
        border-collapse: collapse;
    }

    table.tagTable th, table.tagTable td {
        border-bottom: 1px solid #ddd;
        padding: 7px 3px 7px 3px;
    }
    
    th {
        font-size: 14px;
    }

    td {
        font-size: 14px;
        color: #162b4f;
        font-weight: normal;
    }

    td.check, th.check {
        width: 20px;
        text-align: center
    }

    td.name, th.name {
        text-align: left;
        padding-left: 20px;
    }

    td.actions, th.actions, 
    td.color, th.color {
        text-align: center;
        width: 80px;
    }

    td.color > div {
        border-radius: 5px;
        height: 20px;
    }

    td.actions > i {
        font-size: 19px;
        margin: 0 5px 0 5px;
        cursor: pointer;
    }

    div.add {
        text-align: center;
    }

    div.add > div {
        font-size: 14px;
        display: inline-block;
        cursor: pointer;
        margin: 10px 0 0 0;
    }

    table.colorTable {
        margin: auto;
    }

    table.colorTable td {
        padding: 5px;
    }

    table.colorTable td > div {
        border-radius: 10px;
        height: 50px;
        width: 50px;
        cursor: pointer;
    }

    div.colorSquare {
        border: 2px solid transparent;
        text-align: center;
    }

    div.colorSquare > i {
        display: none;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.5);
        margin-top: 11px;
    }

    div.colorSquare.selected {
        border: 2px solid rgba(0, 0, 0, 0.3);
    }

    div.colorSquare.selected > i {
        display: inline-block;
    }

    div.tagForm {
        display: inline-block;
        margin-top: 20px;
    }

    div.tagForm > div.label {
        font-size: 13px;
        text-align: left;
        padding-left: 5px;
        color: #777777;
    }

    div.tagForm > div.formElement {
        text-align: left;
        margin-bottom: 20px;
    }

    div.formElement > input[type="text"] {
        margin-left: 5px;
        margin-top: 5px;
        padding: 5px 7px 5px 7px;
        border: 1px solid #777777;
        border-radius: 5px;
    }

    div.formButtons {
        text-align: right;
    }

    @media (hover: hover) and (pointer: fine) {
        tbody > tr:hover {
            background-color: #eeeeee;
            cursor: pointer;
        }
    }

    div.arrow-box {
        float: right;
        margin-right: 8px;
    }
</style>
