<template>
    <div>
        <div class="list-selection-container">
            <ul class="list-selection-wrapper">
                <li v-for="(list, index) in lists" class="list-selector" :class="{'active': list.active, 'disabled': list.disabled}" @click="activate(index)">
                    <div class="list-clicker">
                        <table class="list-head-table">
                            <tr>
                                <td>
                                    <div class="list-name" 
                                        @dblclick="list.renaming=true" 
                                        @keyup.enter="list.renaming=false; list.watchlist_data=undefined"
                                        @keyup.esc="disableRename(list); list.watchlist_data=undefined"
                                    >
                                        <div v-show="!list.renaming">
                                            {{list.name}}
                                        </div>
                                        <div v-show="list.renaming">
                                            <input type="text" v-model="list.name" @click.stop/>
                                        </div>
                                    </div>
                                </td>
                                <td class="remover">
                                    <div  v-show="lists.length > 1" class="list-remove" @click.stop="remove(index)">
                                        <i class="icons8-delete-2"></i>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </li>
                <li v-show="loggedIn" class="list-selector" @click="add">
                    <div class="list-clicker">
                        <table class="list-head-table">
                            <tr>
                                <td>
                                    <div class="list-add">
                                        <i class="icons8-plus-math-4"></i>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </li>
            </ul>
        </div>


        <!--
        <div class="lists-selector" ref="selector_container">
        </div>-->

        <div class="lists-content" ref="charts_container">
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    
    import Charts2 from "./charts2.vue"
    var Charts2Class = Vue.extend(Charts2)

    import ListsSelector from "./listsSelector.vue"
    var ListsSelectorClass = Vue.extend(ListsSelector)

    export default {
        name: 'Lists',
        props: {
            user: {
                type: Object,
                required: true
            },
            loaded: {
                type: Boolean,
                requeired: true
            }
        },
        components: {"Charts": Charts2, "ListsSelector": ListsSelector},
        data() {
            return {
                build: 0,
                lists: [],
                whisperData: [],

                // pro zapamatovani hodnoty scroll
                scroll: 200
            }
        },
        computed: {
            /**
              * Určení nejvyssiho automatickeho n k pojmenovani noveho listu
              */
            nMax() {
                var n=0
                for (var i in this.lists) {
                    n = (this.lists[i].n > n) ? this.lists[i].n : n 
                }
                return n
            },

            /**
              * Momentalne aktivni list
              */
            listActive() {
                for (var i in this.lists) {
                    if (this.lists[i].active) {
                        return this.lists[i]
                    }
                }
                return undefined
            },

            /**
              * Ukazatel stavu prihlaseni uzivatele
              */
            loggedIn() {
                return this.user.id > 0
            }

        }, 
        methods: {
            /**
              * Pridani prazdneho listu
              */
            add() {
                // nejdvir vsechny deaktivovat
                this.deactivateAll()

                // pridat
                this.addList("Tab "+(this.nMax+1), true, undefined, undefined)
            },
            addList(name, active, charts, watchlist_data) {

                //if (this.lists.length > 10) {return}
 
                // jmeno podle nazvu ve watchlistu ma prednost!!
                if (watchlist_data !== undefined && watchlist_data !== null) {
                    name = watchlist_data["name"]
                }

                var tmp = new Charts2Class({
                    propsData: {
                        user: this.user,
                        active: active,
                        name: name,
                        ux: charts
                    }        
                })

                tmp.$on("onPermalink", this.onPermalink)
                tmp.$on("onWatchlist", this.onWatchlist)
                tmp.$on("toSubscription", this.toSubscription)

                var disabled = !(this.user.sub || this.lists.length < 3)
                disabled ? tmp.disable() : tmp.enable()

                tmp.$mount()

                tmp.setWhisperData(this.whisperData)

                this.$refs.charts_container.appendChild(tmp.$el)

                // pridat novy
                this.lists.push({
                    disabled: disabled,
                    name: name,
                    n: this.nMax+1, 
                    active: active,
                    renaming: false,
                    charts: tmp,
                    watchlist_data: watchlist_data
                })

                //this.selector.add(name)
            },

            /**
              * Odebrani specifikovaneho listu
              */
            remove(i) {
                // je mazan aktivni?
                var wasActive = this.lists[i].active

                this.lists[i].charts.clear()
                this.lists[i].charts.$destroy()
                this.lists[i].charts.$el.parentNode.removeChild(this.lists[i].charts.$el)
                this.lists[i].charts = undefined

                // smazat
                this.lists.splice(i, 1)
                
                if (wasActive) {
                    // aktivovat posledni
                    this.activate(this.lists.length-1)
                }

                // vynutit prekresleni
                this.build += 1
            },

            /**
              * Aktivace zvoleneho listu
              */
            activate(i) {
                // zakazat prepnuti behem aktivniho nacitaci grafu
                //if (this.listActive !== undefined && this.listActive.charts.isLoading()) {
                //    return
                //}

                this.deactivateAll()
                this.lists[i].active = true
                this.lists[i].charts.active = true

                if (this.lists[i].charts.disabled) {
                    return
                }

                if (!this.lists[i].charts.isDone()) {
                    this.lists[i].charts.startAllCharts()
                }
            },

            /**
              * Deaktivace uplne vsech listu
              */
            deactivateAll() {
                for (var i in this.lists) {
                    this.lists[i].active = false
                    this.lists[i].renaming = false
                    this.lists[i].charts.active = false
                }
            },

            /**
              * Sestaveni UX pro listy
              */
            getUX() {
                var ux = []
                for (var i in this.lists) {
                    ux.push({
                        active: this.lists[i].active,
                        name: this.lists[i].name,
                        charts: this.lists[i].charts.getUX(),
                    })
                }
                return ux
            },

            /**
              * Nastaveni UX podle zadanych dat
              */
            setUX(lists) {
                var ac = 0
                for (var i in lists) {
                    ac += (lists[i].active) ? 1 : 0
                    this.addList(lists[i].name, lists[i].active, lists[i].charts, lists[i].watchlist_data)
                }

                if (ac != 1) {
                    this.activate(0)
                }
            },
            
            /**
              * Pridani u UX
              * watchlist, permalink 
              */
            addUX(lists) {
                for (var i in lists) {
                    lists[i].active = i == 0
                }

                this.deactivateAll()
                this.setUX(lists)

                //if (!this.listActive.charts.isDone()) {
                //    this.listActive.charts.startAllCharts()
                //}
            },

            /**
              * Reakce na klik permalinku
              */
            onPermalink(charts) {
                var ux = [{
                    active: true,
                    name: this.listActive.name,
                    charts: charts
                }]

                this.$emit("onPermalink", ux)
            },

            /**
              * Reakce na klik watchlistu
              */
            onWatchlist(charts) {
                var ux = [{
                    active: true,
                    name: this.listActive.name,
                    charts: charts,
                    watchlist_data: this.listActive.watchlist_data
                }]

                this.$emit("onWatchlistAdded", ux)
            },

            /**
              * Reakce na prechod z charts (placeny histogram) do subscription
              */
            toSubscription() {
                this.$emit("toSubscription")
            },

            fromSignals(signal) {
                this.add()
                this.listActive.charts.fromSignals(signal)
            },

            setWhisperData(data) {
                this.whisperData = JSON.parse(JSON.stringify(data))
                for (var i in this.lists) {
                    this.lists[i].charts.setWhisperData(data)
                }
            },

            getScroll() {
                return this.scroll
            },

            saveScroll(value) {
                this.scroll = value
            }
        },
        mounted() {
            //this.selector = new ListsSelectorClass()
            //this.selector.$mount()
            //this.$refs.selector_container.appendChild(this.selector.$el)

            // pocatecni pridani prazdneho listu
            if (this.lists.length == 0 && !this.loggedIn) {
                this.add()
            }
        },
    }
</script>

<style scoped>
    .list-selection-container {
    }

    ul.list-selection-wrapper {
        margin: 0;
        padding: 0;
    }

    li.list-selector {
        list-style: none;
        display: inline-block;
        margin: 0 10px 0 0;
        color: #555555;
        background-color: #e7e7e7;
        cursor: pointer;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    li.list-selector.active {
        color: #222222;
        background-color: #d9f2f9;
    }

    li.list-selector.active.disabled {
        color: #555555;
        background-color: #cccccc;
    }

    table.list-head-table {
        display: inline-block;
        margin: 0;
        padding: 0;
        border-collapse: collapse;
    }

    table.list-head-table td {
        padding: 0;
    }

    table.list-head-table td > div {
        height: 25px;
        line-height: 25px;
        margin-top: 5px;
    }

    div.list-name {
        margin-left: 8px;
        margin-right: 8px;
    }

    div.list-name input[type="text"] {
        width: 60px;
        border: 1px solid #888;
        border-radius: 3px;
    }

    div.list-remove {
        margin-left: 8px;
        margin-right: 8px;
        color: #aaaaaa;
    }

    div.list-remove:hover { 
        color: #222222; 
    }

    div.list-add {
        margin-left: 8px;
        margin-right: 8px;
    }

    div.lists-content {
    }
</style>
