<template>
    <div class="container watchlist_row" :id="id" ref="row"
        @mousedown="mouseStart($event)" 
        @mouseenter="move($event)" 
        @click.stop
    >
        <table class="row-table">
            <tr>
                <td class="check">
                    <input type="checkbox" v-on:mousedown.stop v-model="checked" @change="onChecked" />
                </td>
                <td class="name">
                    <div class="name-box" v-show="!edit">
                        <span v-on:mousedown.stop @click="chart">{{name}}</span>
                    </div>
                    <div class="name-box" v-show="edit">
                        <input v-on:mousedown.stop class="name-input" type="text" v-model="name" />
                    </div>
                </td>

                <td class="tags" :id="build">
                    <div ref="tagSelectorContainer">
                    </div>

                    <!--
                    <div v-show="!edit">
                        <div v-on:mousedown.stop class="tag-box" v-for="tag in tags" v-bind:style="{'background-color': tag.color}" >{{tag.name}}</div>
                    </div>
                    -->
                </td>

                <td class="actions" v-on:mousedown.stop>
                    <!-- prechod do charts -->
                    <i v-if="!edit" class="icons8-combo-chart" @click="chart"/>

                    <!-- editace -->
                    <i v-if="!edit" class="icons8-edit-row" @click="enableEdit"/>
                    <i v-else class="icons8-checkmark" @click="onEdited"/>

                    <!-- smazani -->
                    <i v-if="!edit" class="icons8-trash" @click="onDeleted"/>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    import Vue from 'vue'

    import TagSelector from './TagSelector.vue'
    var TagSelectorClass = Vue.extend(TagSelector)

    export default {
        name: 'Watchlist2Row',
        components: {},
        props: {
            id: {
                type: String,
                required: false
            },
            link: {
                type: String,
                required: true
            },
            name: {
                type: String, 
                required: true
            },
        },
        data() {
            return {
                touchStarted: null,
                touchMoved: false,
                holdLimit: 750,
                holdInterval: null,
                holding: false,

                edit: false,

                checked: false,

                build: 0,

                tagBase: {},
                tagIds: [],
                tagSelector: null,

                y: 0,
            }
        },
        methods: {
            /**
              * Nastaveni tagu 'sobe' a podrizenemu tagSelectoru
              */
            setTags(tagBase, tagIds) {
                this.tagBase = tagBase
                this.tagIds = tagIds
                this.tagSelector.setTags(tagBase, tagIds)
            },

            setY(value) {
                this.y = value
            },

            /**
              * Vytvoreni instance tagSelectoru
              */
            createTagSelector() {
                this.tagSelector = new TagSelectorClass()
                this.tagSelector.$mount()
                this.$refs.tagSelectorContainer.appendChild(this.tagSelector.$el)
            },

            /**
              * Eventy mysi
              */
            mouseStart(e) {
                if (this.edit) {return} // nelze presouvat prave editovany
                this.$emit("moveStartMouse", this.id)
            },
            move(e) {
                this.$emit("move", e)
            },
            mouseEnd(e) {
                if (this.edit) {return} // nelze presouvat prave editovany 
                this.$emit("moveEndMouse", e)
            },

            /** 
              * Dotykove eventy
              */
            touchStart(e) {
                if (e.touches.length > 1) {return} // ignorovat pinch zoom
                if (this.edit) {return} // nelze presouvat prave editovany

                var self = this
                this.touchStarted = new Date().getTime()
                this.holdInterval = setInterval(function() {
                    var t = new Date().getTime()
                    if (t - self.touchStarted < self.holdLimit || self.touchMoved) {return}
                    self.holding = true
                    clearInterval(self.holdInterval)
                    self.holdInterval = null
                    self.$emit("moveStartTouch", self.id)
                }, 25)
            },
            touchMove(e) {
                this.touchMoved = true
                if (this.holding) {
                    e.preventDefault()
                    this.$emit("move", e)
                    return false
                }
            },
            touchEnd(e) {
                if (this.edit) {return} // nelze presouvat prave editovany
                this.touchMoved = false
                this.holding = false
                clearInterval(this.holdInterval)
                this.holdInterval = null
                this.$emit("moveEndTouch", e)
            },

            /**
              * Prechod do charts
              */
            chart(e) {
                if (this.holdInterval !== null) {
                    clearInterval(this.holdInterval)
                    this.holdInterval = null
                }
                this.$emit("chart", [this.id])
            },

            /**
              * Zacatek editace
              */
            enableEdit() {
                this.edit = true
                this.tagSelector.setEdit(true)
            },

            /**
              * Akce po skonceni editace
              */
            onEdited() {
                this.edit = false
                this.tagSelector.setEdit(false)
                this.tagIds = this.tagSelector.getSelected()

                var data = {
                    id: this.id,
                    name: this.name,
                    tags: this.tagSelector.getSelected(),
                    link: this.link
                }

                this.$emit("edited", data)
            },

            /**
              * Akce smazani radky
              */
            onDeleted() {
                this.$emit("deleted", [this.id])
            },

            /**
              * Reakce na zmenu stavu checklistu
              */
            onChecked() {
                this.$emit("checked")
            },

            /**
              * Externi nastaveni stavu checklistu
              */
            setCheck(value) {
                this.checked = value
            },

            removeTag(tagId) {
                var tagBase = {}
                for (var key in this.tagBase) {
                    if (key == tagId) {continue}
                    tagBase[key] = this.tagBase[key]
                }
                this.tagBase = tagBase

                if (this.tagIds.includes(tagId)) {
                    var tagIds = []
                    for (var i in this.tagIds) {
                        if (this.tagIds[i] == tagId) {continue}
                        tagIds.push(this.tagIds[i])
                    }
                    this.tagIds = tagIds
                }

                this.tagSelector.setTags(this.tagBase, this.tagIds)
                this.build += 1
            }
        },
        mounted() {
            var el = this.$refs.row
            el.addEventListener("touchstart", this.touchStart, false)
            el.addEventListener("touchmove", this.touchMove, false)
            el.addEventListener("touchend", this.touchEnd, false)

            this.createTagSelector()
        }
    }
</script>

<style scoped>
    div.container {
        border-bottom: 1px solid #cdcdcd;
        padding: 1px 0 1px 5px;
        cursor: pointer;
    }

    table.row-table {
        width: 100%;
        border-collapse: collapse;
    }

    table.row-table > tr > td { 
        padding: 5px;
    }

    td.check {
        width: 20px;
    }

    td.name {
        width: 25%;
    }

    td.name > div.name-box {
    }

    div.name-box > input[type="text"].name-input {
        font-family: "Open Sans" !important;
        font-size: 15px;
        padding: 8px 0 8px 3px;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        margin: 0 0 0 -4px;
        width: 100%;
        
    }

    td.tags {
        text-align: center;
    }

    td.tags > div {
        cursor: alias;
    }

    td.actions {
        text-align: center;
        width: 120px;
        cursor: alias;
    }

    td.actions > i {
        font-size: 22px;
        color: #555;
        display: inline-block;
        height: 30px;
        width: 30px;
        cursor: pointer;
        margin: 0 2px 0 2px;
    }

    
    @media (hover: hover) and (pointer: fine) {
        div.container:hover {
            background-color: #f2f2f2;
        }

        td.actions > i:hover {
            color: #1976D2;
        }

        div.name-box:hover {
            color: #1976D2;
        }
    }
</style>
