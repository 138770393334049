<template>
    <div>
        <div class="block-header" style="margin-bottom: 0px;">
            <span>Trading Signals</span>
        </div>

        <div class="block-list-wrapper">            
            <table class="signals-table">
                <thead>
                    <tr>
                        <th>
                            STRONGEST FUTURES
                        </th>
                        <th>
                            STRONGEST INTERDELIVERY
                        </th>
                        <th>
                            BECAME VALID
                        </th>
                        <th>
                            BECAME INVALID
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in signalsData">
                        <td v-for="key in ['futures', 'interdelivery', 'valid', 'invalid']" @click="onSignalClicked(row, key)">
                            <div class="cell" v-if="key in row" :style="{'background-color': row[key].bcolor}">

                                <div class="cell-group">
                                    Change: {{formatNumber(row[key].change)}}
                                </div>
                                <!--
                                <div class="cell-group">
                                    Prediction: {{formatNumber(row[key].prediction)}}
                                </div>
                                -->
                                <div class="cell-commodity" :class="{'interdelivery': true}">
                                    {{row[key].desc_0}}
                                </div>
                                <div class="cell-desc">
                                    <div>
                                        {{row[key].desc_1}}
                                    </div>
                                    <div>
                                        {{row[key].desc_2}}
                                    </div>
                                    <div>
                                        {{row[key].desc_3}}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Colorbar defaultne skryty -->
        <div class="cmap-box" v-if="false">
            <div>
                <div>
                    -0.5
                </div>
                <div class="cmap-label">
                    CHANGE
                </div>
            </div>
            <div>
                <div>
                    0.5
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {dSource} from './vars.js';
    
    export default {
        name: 'WidgetSignals',
        components: {
        },
        props: {
            user: {
                type: Object,
                required: true
            },
            signalsData: {
                type: Array,
                required: true
            }
        }, 
        data() {
            return {
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                dSource: dSource,
            }
        },
        methods: {
            formatNumber(n) {
                return n.toFixed(3)
            },

            onSignalClicked(row, key) {
                if (row[key] == undefined) {return}
                this.$emit("signalClicked", row[key].id)
            }
        },
        computed: {
            loggedIn() {
                return this.user.id > 0
            }          
        }
    }
</script>

<style scoped>
    div.block-list-wrapper {
        margin-top: 0px;
    }

    table.signals-table {
        width: calc(100% - 2px);
        border-collapse: collapse;
        border-radius: 10px;
        margin-left: 1px;
    }

    table.signals-table > thead > tr {
    }

    table.signals-table > thead > tr > th {
        padding: 5px;
        color: #373737;
    }

    table.signals-table td {
        padding: 0;
        width: calc(25%) !important;
        border-left: 1px solid white;
        border-right: 1px solid white;
        border-bottom: 1px solid white;
        color: white;
    }

    table.signals-table td:hover {
        color: gold;
    }

    div.cell {
        cursor: pointer;
        padding-bottom: 5px;
        color: inherit;
        min-height: 110px;
    }

    div.cell:hover {
        background-color: #eee;
    }

    div.cell-group {
        text-align: right;
        padding-top: 2px;
        padding-right: 4px;
        font-size: 11px;
    }

    div.cell-commodity {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-top: 15px;
    }

    div.cell-desc {
        font-size: 11px;
        font-weight: bold;
        text-align: center;
        padding: 0px 4px 0px 4px;
    }

    div.cmap-box {
        border: 2px solid white;
        border-top: none;
    }

    div.cmap-box > div {
        display: inline-block;
        width: calc(50%);
        color: white;
    }

    div.cmap-box > div:first-child {
        background-image: linear-gradient(to right, rgb(255, 0, 0), rgb(0, 0, 0));
    }

    div.cmap-box > div:nth-child(2) {
        background-image: linear-gradient(to right, rgb(0, 0, 0), rgb(0, 170, 0));
        text-align: right;
    }

    div.cmap-box > div > div {
        display: inline-block;
        padding: 5px 8px 5px 8px;
    }
</style>
