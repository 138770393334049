// import AmCharts from 'amcharts3';
// import AmStock from 'amstock3/amcharts/amstock';
// import AmSerial from 'amcharts3/amcharts/serial';

export default {
    methods: {
        /**
         * Variables for X axis settings
         */
        defaultCategoryAxesSettings(functionId) {
            var tmp =  {
                maxSeries: 800, // aby fungovala synchronizace mezi vsemi druhy musi byt jedna honota!!
                //maxSeries: (functionId=='priceCandlestickHistorical' || functionId=='priceCandlestickFutures') ? 370 : 0, // dost mozna bude potrebovat uprvit pro dalsi grafy
                groupToPeriods: ["DD", "WW", "MM"],
                dashLength: 5,
                fontSize: 13,
                equalSpacing: true,
                parseDates: true,
                dateFormats: [
                    {period:"DD", format:"MMM DD, YYYY"},
                    {period:"WW", format:"MMM DD, YYYY"},
                    {period:"MM", format:"MMM YYYY"},
                    {period:"YYYY", format:"YYYY"}
                ],
            }

            //if (functionId == "seasonalityAverages") {
            //    tmp["groupToPeriods"] = []
            //    tmp["maxSeries"] = 0
            //}
            
            //if (functionId == "PriceContinuous") {
            //    tmp.maxSeries = 100000
            //}

            return tmp
        },
        defaultPanelSettings(functionId){
            let tmp = {
                fontFamily: "'Open Sans', sans-serif",
                autoMargins : false,
                panEventsEnabled: false,
                marginLeft: 40, 
                marginRight: 82,
                marginBottom: 5
            }
            if (functionId == "termStructure"){
                tmp['recalculateToPercents'] = "never"
            }
            if (functionId == "histogram" || functionId == "histogram2"){
                tmp.marginBottom = 35
            }
            if (functionId == "priceLine"){
                tmp.marginLeft = 40
            }
            if (functionId == "signal") {
                tmp.marginLeft = 2
            }

            return tmp
        },
        defaultBalloon(functionId){
            return{
                fontSize: 13,
                cornerRadius: 5,
                textAlign: "right"
            }
        },
        /**
         * Custom category label formating
         */
        myCategoryLabel(valueText, date, categoryAxis) {
            return valueText + ((valueText == "Jan") ? " " + date.getFullYear() : "")
        },
        /**
         * Copyright
         */
        addCopyright(ch) {
            if (ch == undefined) {return}

            if (ch.type == "serial") {
                ch.allLabels = (ch.allLabels == undefined ? [] : ch.allLabels)
                ch.allLabels.push({
                    x: "30", y: "!72",
                    text: "© SpreadCharts.com",
                    align: "left",
                    size: 15,
                    color: "#888",
                    alpha: 0.5,
                    bold: true,
                    id: "copyright"
                })
            } else {
                ch.panels[0].allLabels = (ch.panels[0].allLabels == undefined ? [] : ch.panels[0].allLabels)
                ch.panels[0].allLabels.push({
                    x: "30", 
                    y: (ch.panels.length > 1) ? "!40" : "!62",
                    text: "© SpreadCharts.com",
                    align: "left",
                    size: 15,
                    color: "#888",
                    alpha: 0.5,
                    bold: true,
                    id: "copyright"
                })
            }

            ch.validateData()
        },

        /**
         * Disabled days list
         */
        getDisabledDays() {
            var days = []

            var d = new Date("2010-01-02T00:00:00Z")
            var tz = new Date().getTimezoneOffset()
            if (tz < 0) {
                var d = new Date(d.getTime()-tz*60*1000)
            } else {
                var d = new Date(d.getTime()+tz*60*1000)
            }
            var t_end = new Date()

            while (d.getTime() <= t_end) {

                if (d.getUTCDay() == 6) {
                    days.push(new Date(d))
                    d.setUTCDate(d.getUTCDate()+1)
                } else if (d.getUTCDay() == 0) {
                    days.push(new Date(d))
                    d.setUTCDate(d.getUTCDate()+6)
                }
            }

            return days
        },

        formatThousands(n) {
            return n.toLocaleString("en")
        },

        asTimeUTC(date) {
            return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
        },
        asDateLocal(t) {
            var d = new Date(t)
            return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate() ,0, 0, 0, 0)
        },

        getDateString(date) {
            date = new Date(date)

            var y = date.getFullYear()

            var m = date.getMonth()+1
            if (m < 10) {
                m = "0"+m
            }

            var d = date.getDate()
            if (d < 10) {
                d = "0"+d
            }

            return y+"-"+m+"-"+d
        },

        isTouch() {
            return matchMedia('(hover: none)').matches
        }
    }
}
