/**
 * Soubor k definifi "globalnich" promennych a nastaveni
 */

// |---------------|
// |API data source|
// |---------------|

var force_dSource = false // umoznuje "nanutit" jakykoliv vlastni dSource (true)
var ds = "" 
if (force_dSource) {
    // tady si muzeme zadat cokoliv lokalne potrebujeme treba i jednorazove
    //ds = "https://api.spreadcharts.com/testing";
    ds = "http://api.spreadcharts.local";
} else {
    // tady to automaticky rozezna localhost vs. ostry/testing apod.
    if (window.location.href.indexOf("local") > 0) {
        //document.cookie="authToken=S9Bm5KFZqYlZheGF7B11aHZFyCi"
        ds = "http://api.spreadcharts.local";
    } else if (window.location.href.indexOf("testing") > 0) {
        //ds = "https://api.spreadcharts.com/testing";
        ds = "https://testing.spreadcharts.com/api";
    } else {
        ds = "https://app.spreadcharts.com/api";
    }
}

export const dSource = ds;

// zapne debud mod
export const DEBUG = false

// regular expresions for commodities
export const historicalRegExp = new RegExp('([a-z]{1}[0-9]{1}|[6]{1}[a-z]{1}[0-9]{1}|[a-z]{2,3}[0-9]{1})','ig')
export const futuresRegExp    = new RegExp('[a-z0-9]{1}[a-z]{0,2}[fghjkmnquvxz]{1}[0-9]{2}','ig');


