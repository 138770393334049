// import AmCharts from 'amcharts3';
// import AmStock from 'amstock3/amcharts/amstock';
// import AmSerial from 'amcharts3/amcharts/serial';

import defaultCategoryAxesSettings from './commonFunctions.js'
import defaultPanelSettings from './commonFunctions.js'
import myCategoryLabel from './commonFunctions.js'
import defaultBalloon from './commonFunctions.js'
import isTouch from './commonFunctions.js'

export default {
    mixins:[defaultCategoryAxesSettings, defaultPanelSettings, defaultBalloon, isTouch],

    methods: {
        volume(payload) {
            var indexes = []
            var mapping = payload.misc.mapping
            for (var i in mapping) {
                if (mapping[i].fromField.match(/^volume_[0-9]{1,}$/g)) {
                    indexes.push(parseInt(mapping[i].fromField.split("_")[1]))
                }
            }

            var labels = []
            for (var i in payload.labels) {
                if(payload.labels[i].match(/^[\-\+]?[A-Z0-9]{1,3}[FGHJKMNQUVXZ]{1}[0-9]{2}$/g)) {
                    if (payload.labels[i][0] == "-" || payload.labels[i][0] == "+") {
                        payload.labels[i] = payload.labels[i].substring(1, payload.labels[i].length)
                    }
                    labels.push(payload.labels[i])
                }
            }

            return window.AmCharts.makeChart(payload.targetId, {
                type: "stock",
                theme: "none",
                pathToImages: "../lib/amStock/images/",
                dataSets: [{
                    fieldMappings: mapping,
                    color: "#7f8da9",
                    dataProvider: payload.data,
                    categoryField: "date"
                }],
                chartScrollbarSettings: {
                    enabled: false
                },
                categoryAxesSettings: this.defaultCategoryAxesSettings(payload.targetId),
                valueAxesSettings:{
                    inside: false
                },
                balloon: {
                    fontSize: 13,
                    cornerRadius: 5,
                    textAlign : "left"
                },
                chartCursorSettings: {
                    valueBalloonsEnabled: true,
                    // pan: is_touch_device(), !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                    pan: this.isTouch(),
                    fontSize: 13,
                    valueLineEnabled: true,
                    valueLineBalloonEnabled: true
                },
                panelsSettings: this.defaultPanelSettings(payload.functionId),
                legendSettings: {
                    position: "top",
                    equalWidths: false
                } ,
                periodSelector: {
                    inputFieldsEnabled: false,
                },
                panels:[{
                    stockGraphs: function(){
                        var ret = [];
                        var colors = ["LimeGreen","#db4c3c","#ffcc00","#999999"];
                        for (var i = 0; i < indexes.length; i++) {
                            ret.push({
                                //type: "column",
                                type: "line",
                                title: labels[i],
                                useDataSetColors: false,
                                fontSize: 14,
                                valueField: "volume_" + indexes[i],
                                lineColor: colors[i],
                                fillAlphas: 0.5,
                                lineAlpha: 0.5,
                                clustered: false
                            })
                        }
                        return ret;
                    }(),
                    valueAxes : [{
                        position: "right",
                        //labelFunction: valueAxisFormat,
                        fontSize: 13
                    }],
                    legend: {
                        title: "Volume: ",
                        fontSize: 14,
                        position: "top"
                    },
                    percentHeight: 50,
                    marginTop: 1,
                    usePrefixes: true,
                    //panEventsEnabled: false
                    showCategoryAxis: true
                },{
                    stockGraphs: function(){
                        var ret = [];
                        var colors = ["LimeGreen","#db4c3c","#ffcc00","#999999"];
                        for (var i = 0; i < indexes.length; i++) {
                            ret.push({
                                valueField: "oi_" + indexes[i],
                                type: "line",
                                title: labels[i],
                                lineColor: colors[i],
                                lineThickness: 2, 
                                lineAlpha: 1,
                                useDataSetColors: false,
                            })
                        }
                        return ret;
                    }(),
                    valueAxes : [{
                        position: "right",
                        // labelFunction: valueAxisFormat,
                        fontSize: 13
                    }],
                    legend: {
                        title: "Prior day OI:",
                        fontSize: 14,
                        position: "top"
                    },
                    percentHeight: 50,
                    marginTop: 1,
                    showCategoryAxis: true,
                    parseDates: true,
                    usePrefixes: true,
                    categoryAxis: {
                        labelFunction: this.myCategoryLabel
                    }
                }]
            })
            // ------------
        } 
    }
}
