
import defaultCategoryAxesSettings from './commonFunctions.js'
import defaultPanelSettings from './commonFunctions.js'
import defaultBalloon from './commonFunctions.js'
import isTouch from './commonFunctions.js'

export default {
    mixins:[defaultCategoryAxesSettings, defaultPanelSettings, defaultBalloon, isTouch],
    methods: { 
        priceCandlestickFutures(payload) {
            return window.AmCharts.makeChart(payload.targetId, {
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                dataSets: [{
                    fieldMappings: [
                        {fromField:"date", toField:"date"},
                        {fromField:"open", toField:"open"},
                        {fromField:"high", toField:"high"},
                        {fromField:"low", toField:"low"},
                        {fromField:"close", toField:"close"},
                        {fromField:"volume", toField:"volume"},
                        {fromField:"oi", toField:"oi"},
                        /*
                        {fromField:"month_open", toField:"month_open"},
                        {fromField:"month_high", toField:"month_high"},
                        {fromField:"month_low", toField:"month_low"},
                        {fromField:"month_close", toField:"month_close"},
                        {fromField:"week_open", toField:"week_open"},
                        {fromField:"week_high", toField:"week_high"},
                        {fromField:"week_low", toField:"week_low"},
                        {fromField:"week_close", toField:"week_close"}*/
                    ],
                    dataProvider: payload.data,
                    // dataDateFormat: "YYYY-MM-DD",
                    categoryField: "date"
                }],
                categoryAxesSettings: this.defaultCategoryAxesSettings(payload.functionId),
                categoryAxis: {
                    gridPosition: "start",
                    showFirstLabel: false,
                },
                panels: [{ // price
                    title: "Price:",
                    showCategoryAxis: false,
                    percentHeight: 75,
                    valueAxes: [{
                        position: "right",
                        glc: payload.misc.glc, // vlastni parametr pro kalkulator
                        fontSize: 13,
                        logarithmic: false
                    }],
                    stockGraphs: [{
                        id: "g_price",
                        openField: "open", highField: "high", lowField: "low", closeField: "close", valueField: "close",
                        type: "candlestick",
                        fillAlphas: true,
                        lineColor: "#32cd32",
                        fillColors: "#32cd32",
                        negativeLineColor: "#db4c3c",
                        negativeFillColors: "#db4c3c",
                        useDataSetColors: false,
                        balloonText: "O: [[open]]\nH: [[high]]\nL: [[low]]\nC: [[close]]"
                    }],
                    stockLegend: {
                        labelText: payload.labels[0],
                        valueText: "Close: [[close]]",
                        fontSize: 13,
                    },
                },{ // volume
                    title: "Volume:",
                    showCategoryAxis: true,
                    percentHeight: 25,
                    valueAxes: [{
                        position: "right",
                        glc: payload.misc.glc, // vlastni parametr pro kalkulator
                        fontSize: 13,
                        usePrefixes: true,
                    }],
                    stockGraphs: [{
                        id: "g_volume",
                        valueField: "volume",
                        type: "column",
                        title: "Volume: ",
                        fillAlphas: 1,
                        lineColor: "#7f8da9",
                        fillColors: "#7f8da9",
                        useDataSetColors: false,
                        periodValue: "Sum"
                    },{
                        id: "g_open_interes",
                        title: "Prior day OI: ",
                        valueField: "oi",
                        lineColor: "#0000ff",
                        lineThickness: 2,
                        useDataSetColors: false,
                        balloonText: "[[oi]]"
                    }],
                    stockLegend: {
                        labelText: "[[title]]",
                        fontSize: 13
                    }
                }],
                chartCursorSettings: {
                    categoryBalloonEnabled: true,
                    pan: this.isTouch(),
                    valueLineEnabled : true,
                    valueLineBalloonEnabled : true
                },
                chartScrollbarSettings: {
                    enabled: false
                },
                panelsSettings: this.defaultPanelSettings(payload.functionId),
                valueAxesSettings: {
                    inside: false,
                    dashLength: 0,
                    showFirstLabel: false
                },
                balloon: this.defaultBalloon(payload.functionId),
                listeners: [{
                    event: "zoomed",
                    method: function(event) {
                        return
                        var g = event.chart.panels[0].stockGraphs[0]
                        if (event.period == "DD") {
                            g.valueField = "close"
                            g.openField = "open"
                            g.highField = "high"
                            g.lowField = "low"
                            g.closeField = "close"
                        } else if (event.period == "WW") {
                            g.valueField = "week_close"
                            g.openField = "week_open"
                            g.highField = "week_high"
                            g.lowField = "week_low"
                            g.closeField = "week_close"
                        } else if (event.period == "MM") {
                            g.valueField = "month_close"
                            g.openField = "month_open"
                            g.highField = "month_high"
                            g.lowField = "month_low"
                            g.closeField = "month_close"
                        }

                        event.chart.validateData()
                    }
                    
                }]
            })
        },

        priceCandlestickHistorical(payload) {
            return window.AmCharts.makeChart(payload.targetId, {
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                chartCategory: payload.category, // rozlisuje hlavne historical vs historicalSpread
                dataSets: [{
                    fieldMappings: [
                        {fromField:"date", toField:"date"},
                        {fromField:"open", toField:"open"},
                        {fromField:"high", toField:"high"},
                        {fromField:"low", toField:"low"},
                        {fromField:"close", toField:"close"},
                        {fromField:"volume", toField:"volume"},
                        {fromField:"oi", toField:"oi"},
                        {fromField:"month_open", toField:"month_open"},
                        {fromField:"month_high", toField:"month_high"},
                        {fromField:"month_low", toField:"month_low"},
                        {fromField:"month_close", toField:"month_close"},
                        {fromField:"week_open", toField:"week_open"},
                        {fromField:"week_high", toField:"week_high"},
                        {fromField:"week_low", toField:"week_low"},
                        {fromField:"week_close", toField:"week_close"}
                    ],
                    dataProvider: payload.data,
                    // dataDateFormat: "YYYY-MM-DD",
                    categoryField: "date"
                }],
                categoryAxesSettings: this.defaultCategoryAxesSettings(payload.functionId),
                categoryAxis: {
                    gridPosition: "start",
                    showFirstLabel: false,
                },
                panels: [{ // price
                    title: "Price:",
                    showCategoryAxis: true,
                    valueAxes: [{
                        position: "right",
                        glc: payload.misc.glc, // vlastni parametr pro kalkulator
                        fontSize: 13,
                        logarithmic: false
                    }],
                    stockGraphs: [{
                        id: "g_price",
                        openField: "open", 
                        highField: "high", 
                        lowField: "low", 
                        closeField: "close", 
                        valueField: "close",
                        gapField: "gap",
                        type: "candlestick",
                        fillAlphas: false,
                        lineColor: "#32cd32",
                        fillColors: "#32cd32",
                        negativeLineColor: "#db4c3c",
                        negativeFillColors: "#db4c3c",
                        useDataSetColors: false,
                        lineThickness: 1,
                        balloonFunction: function(item) {
                            var txt =   "O: " + parseFloat(item.values.open).toFixed(payload.misc.round_prec) + "<br>"
                            txt +=      "H: " + parseFloat(item.values.high).toFixed(payload.misc.round_prec) + "<br>"
                            txt +=      "L: " + parseFloat(item.values.low).toFixed(payload.misc.round_prec) + "<br>"
                            txt +=      "C: " + parseFloat(item.values.close).toFixed(payload.misc.round_prec)

                            return txt
                        }
                    }],
                    stockLegend: {
                        labelText: payload.labels[0],
                        valueText: "Close: [[close]]",
                        fontSize: 13,
                        valueFunction: function(item) {
                            if (item.values === undefined) {return ""}
                            var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                            return (isNaN(value)) ? "" : value
                        }
                    },
                }],
                chartCursorSettings: {
                    categoryBalloonEnabled: true,
                    //pan : is_touch_device(), !!!!!!!!!!!!!!!
                    pan: this.isTouch(),
                    valueLineEnabled : true,
                    valueLineBalloonEnabled : true
                },
                chartScrollbarSettings: {
                    enabled: false
                },
                panelsSettings: this.defaultPanelSettings(payload.functionId),
                valueAxesSettings: {
                    inside: false,
                    dashLength: 0,
                    showFirstLabel: false
                },
                balloon: {
                    fontSize: 13,
                    cornerRadius: 5,
                    textAlign: "right"
                },
                listeners: [{
                    event: "zoomed", 
                    method: function(event) {
                        var g = event.chart.panels[0].stockGraphs[0]

                        var isSpread = !payload.raw.match(/^[\-\+]?[A-Z0-9]{1,3}[0-9]{1,2}$/g)
                        if (!isSpread) {return}

                        if (event.period == "DD") {
                            g.type = "line"
                            g.lineThickness = 2
                            g.lineColor = "#0000ff"
                        } else {
                            g.type = "candlestick"
                            g.lineThickness = 1
                            g.lineColor = "#32cd32"
                        }

                        if (event.period == "DD") {
                            g.valueField = "close"
                            if (isSpread) {
                                g.openField = undefined
                                g.highField = undefined
                                g.lowField = undefined
                                g.closeField = undefined
                            } else {
                                g.openField = "open"
                                g.highField = "high"
                                g.lowField = "low"
                                g.closeField = "close"
                            }
                        } else if (event.period == "WW") {
                            g.valueField = "week_close"
                            g.openField = "week_open"
                            g.highField = "week_high"
                            g.lowField = "week_low"
                            g.closeField = "week_close"
                        } else if (event.period == "MM") {
                            g.valueField = "month_close"
                            g.openField = "month_open"
                            g.highField = "month_high"
                            g.lowField = "month_low"
                            g.closeField = "month_close"
                        }

                        event.chart.validateData()
                    }
                }]
            })
        },
    }
}
