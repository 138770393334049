
import defaultCategoryAxesSettings from './commonFunctions.js'
import defaultPanelSettings from './commonFunctions.js'
import defaultBalloon from './commonFunctions.js'
import isTouch from './commonFunctions.js'

export default {
    mixins:[defaultCategoryAxesSettings, defaultPanelSettings, defaultBalloon, isTouch],
    methods: { 
        round2(num) {
            return Math.round(100.0 * num) / 100.0
        },
        getMonth(i) {
            return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][i]
        },
        seasonalityMonth(payload) {

            var self = this

            return window.AmCharts.makeChart(payload.targetId, {
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                dataSets: [{
                    fieldMappings: [
                        {fromField:"date", toField:"date"},
                        {fromField: "up_5", toField: "up_5"},
                        {fromField: "up_15", toField: "up_15"},
                        {fromField: "up_30", toField: "up_30"},
                    ],
                    dataProvider: payload.data,
                    categoryField: "date"
                }],
                categoryAxesSettings: this.defaultCategoryAxesSettings(payload.functionId),
                categoryAxis: {
                    gridPosition: "start",
                    showFirstLabel: false,
                },
                panels: [{ // price
                    showCategoryAxis: true,
                    valueAxes: [{
                        position: "right",
                        fontSize: 13,
                        logarithmic: false,
                        labelFunction: function(value, valueText, valueAxis) {
                            return (value + 50.0) + "%"
                        },
                        balloonTextFunction: function(value) {
                            return self.round2(value + 50.0) + "%"
                        }
                    }],
                    categoryAxis: {
                        labelFunction: function(valueText, serialDataItem, categoryAxis) {
                            return valueText
                        }
                    },
                    stockGraphs: [{
                        id: "g_30",
                        title: "30y",
                        valueField: "up_30",
                        balloonFunction: function(item) {
                            return self.round2(item.values.value + 50.0) + "%"
                        },
                        type: "line",
                        lineColor: "#ffd600",
                        lineThickness: 2,
                        fillAlphas: 0.4,
                        //lineAlpha: 0.5,
                        fontSize: 14,
                        useDataSetColors: false,
                        clustered: false
                    },{
                        id: "g_15",
                        title: "15y",
                        valueField: "up_15",
                        balloonFunction: function(item) {
                            return self.round2(item.values.value + 50.0) + "%"
                        },
                        type: "line",
                        lineColor: "LimeGreen",
                        lineThickness: 2,
                        fillAlphas: 0.4,
                        //lineAlpha: 0.5,
                        fontSize: 14,
                        useDataSetColors: false,
                        clustered: false
                    },{
                        id: "g_5",
                        title: "5y",
                        valueField: "up_5",
                        balloonFunction: function(item) {
                            return self.round2(item.values.value + 50.0) + "%"
                        },
                        type: "line",
                        lineColor: "#db4c3c",
                        lineThickness: 2,
                        fillAlphas: 0.4,
                        //lineAlpha: 0.5,
                        fontSize: 14,
                        useDataSetColors: false,
                        clustered: false
                    }],
                    legend: {
                        title: payload.raw + " seasonality - % of the time positive - by month: ",
                        fontSize: 14,
                        position: "top",
                        reversedOrder: true,
                        valueFunction: function(item) {
                            if (item === undefined || item.values === undefined || isNaN(item.y)) {
                                return ""
                            }
                            return self.round2(item.values.value + 50.0) + "%"
                        }
                    },
                }],
                chartCursorSettings: {
                    categoryBalloonEnabled: true,
                    categoryBalloonFunction: function(date) {
                        return self.getMonth(date.getMonth()) + " " + date.getFullYear()
                    },
                    valueBalloonsEnabled: true,
                    //pan : is_touch_device(), !!!!!!!!!!!!!!!
                    pan: this.isTouch(),
                    valueLineEnabled : true,
                    valueLineBalloonEnabled : true,
                },
                chartScrollbarSettings: {
                    enabled: false
                },
                panelsSettings: this.defaultPanelSettings(payload.functionId),
                valueAxesSettings: {
                    inside: false,
                    dashLength: 0,
                    showFirstLabel: false
                },
                balloon: this.defaultBalloon(payload.functionId),
            })
        },
    }
}
