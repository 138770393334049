import {dSource} from "./vars.js"
import axios from 'axios';

export default {
    data() {
        return {
            dSource: dSource
        }
    },
    methods: {
        requestUX(robj, callback) {
            axios.post(dSource, {robj: robj}).then(payload => function(payload) {
                payload.data = payload.data.result
                callback(payload)
            }(payload.data))
        },

        loadMyUX(user, callback) {
            // robj
            var robj = {
                rkeys: ["result"],
                user: user,
                chain: [
                    {action: "ux", i:0, dkey:"result", request:{method: "loadUX", user:user, type:"my_ux"}}
                ]
            }

            this.requestUX(robj, callback)
        },

        saveMyUX(user, ux, callback) {
            this.saveUX(user, ux, "my_ux", callback)
        },

        loadLinkedUX(user, link, callback) {
            // robj
            var robj = {
                rkeys: ["result"],
                user: user,
                chain: [
                    {action: "ux", i:0, dkey:"result", request:{method: "loadUX", user:user, type:"", link:link}}
                ]
            }

            this.requestUX(robj, callback)
        },

        saveUX(user, ux, type, callback) {
            // robj
            var robj = {
                rkeys: ["result"],
                user: user,
                chain: [
                    {action: "ux", i:0, dkey:"result", request:{method: "addUX", data:ux, user:user, type:type}}
                ]
            }

            this.requestUX(robj, callback)
        },

        watchlistAdd(user, data, callback) {
            // robj
            var robj = {
                user: user,
                rkeys: ["result"],
                chain: [
                    {action: "ux", i:0, dkey:"result", request:{method: "watchlistAdd", user:user, data:data}}
                ]
            }
        
            this.requestUX(robj, callback)
        },

        watchlistLoad(user, callback) {
            // robj
            var robj = {
                rkeys: ["result"],
                user: user,
                chain: [
                    {action: "ux", i:0, dkey:"result", request:{method: "watchlistLoad", user:user}}
                ]
            }
        
            this.requestUX(robj, callback)
        },

        watchlistDelete(user, ux_ids, callback) {
            // robj
            var robj = {
                rkeys: ["result"],
                user: user,
                chain: [
                    {action: "ux", i:0, dkey:"result", request:{method: "watchlistDelete", user:user, ux_ids:ux_ids}}
                ]
            }
        
            this.requestUX(robj, callback)
        },

        watchlistEdit(user, data, callback) {
            // robj
            var robj = {
                rkeys: ["result"],
                user: user,
                chain: [
                    {action: "ux", i:0, dkey:"result", request:{method: "watchlistEdit", user:user, data:data}}
                ]
            }
        
            this.requestUX(robj, callback)
        },

        categoryAdd(user, data, callback) {
            // robj
            var robj = {
                rkeys: ["result"],
                user: user,
                chain: [
                    {action: "ux", i:0, dkey:"result", request:{method: "categoryAdd", user:user, data:data}}
                ]
            }
        
            this.requestUX(robj, callback)
        },

        categoryLoad(user, cgroup, callback) {
            // robj
            var robj = {
                rkeys: ["result"],
                user: user,
                chain: [
                    {action: "ux", i:0, dkey:"result", request:{method: "categoryLoad", user:user, cgroup:cgroup}}
                ]
            }
        
            this.requestUX(robj, callback)
        },

        categoryDelete(user, cgroup, category_id, callback) {
            // robj
            var robj = {
                rkeys: ["result"],
                user: user,
                chain: [
                    {action: "ux", i:0, dkey:"result", request:{method: "categoryDelete", user:user, cgroup:cgroup, category_id:category_id}}
                ]
            }
        
            this.requestUX(robj, callback)
        },

        categoryEdit(user, cgroup, data, callback) {
            // robj
            var robj = {
                rkeys: ["result"],
                user: user,
                chain: [
                    {action: "ux", i:0, dkey:"result", request:{method: "categoryEdit", user:user, data:data, cgroup:cgroup}}
                ]
            }
        
            this.requestUX(robj, callback)
        },
    }
}
