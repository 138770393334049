<template>
    <div clas s="loader-container">
        <div id="page-loading">
            <div class="three-balls">
                <div class="ball ball1"></div>
                <div class="ball ball2"></div>
                <div class="ball ball3"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "loader"
    }
</script>

<style scoped>
    :root {
        --ball-color: #1976d2; 
    }

    .loader-container {
        width: 100%;
    }

    #page-loading {
        position: relative;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
    }

    .three-balls {
        margin: 0 auto;
        width: 70px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 45%;
    }

    .three-balls .ball {
        position: relative;
        width: 10px;
        height: 20px;
        border-radius: 3px;
        margin-left: 5px;
        display: inline-block;
        -webkit-animation: bouncedelay 3.0s infinite cubic-bezier(.62, .28, .23, .99) both;
        animation: bouncedelay 3.0s infinite cubic-bezier(.62, .28, .23, .99) both;
        background-color: #d9f2f9;
    }

    .three-balls .ball1 {
        -webkit-animation-delay: -.16s;
        animation-delay: -.16s;
    }

    .three-balls .ball2 {
        -webkit-animation-delay: -.08s;
        animation-delay: -.08s;
    }

    @keyframes bouncedelay {
        0% {
            bottom: 0;
        }
        16.66% {
            bottom: 40px;
        }
        33.33% {
            bottom: 0px;
        }
        50% {
            bottom: 40px;
        }
        66.66% {
            bottom: 0px;
        }
        83.33% {
            bottom: 40px;
        }
        100% {
            bottom: 0;
        }
    }

    @-webkit-keyframes bouncedelay {
        0% {
            bottom: 0;
        }
        16.66% {
            bottom: 40px;
        }
        33.33% {
            bottom: 0px;
        }
        50% {
            bottom: 40px;
        }
        66.66% {
            bottom: 0px;
        }
        83.33% {
            bottom: 40px;
        }
        100% {
            bottom: 0;
        }
    }
</style>
