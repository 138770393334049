//import AmCharts from 'amcharts3';
//import AmStock from 'amstock3/amcharts/amstock';
//import AmSerial from 'amcharts3/amcharts/serial';

import defaultCategoryAxesSettings from './commonFunctions.js'
import myCategoryLabel from './commonFunctions.js'
import defaultPanelSettings from './commonFunctions.js'
import defaultBalloon from './commonFunctions.js'
import isTouch from './commonFunctions.js'

export default {
    mixins:[defaultCategoryAxesSettings, myCategoryLabel, defaultPanelSettings, defaultBalloon, isTouch],
    methods: { 
        priceLine(payload) {
            let colors = ["#9400d3","#ba55d3","#d77ebe","#ddb0de","#814b72"]
            return window.AmCharts.makeChart(payload.targetId, {
                // ------------
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                dataSets: [{
                    fieldMappings: function(){
                        var ret = [
                            {fromField: "close", toField: "close"},
                            {fromField: "date", toField: "date"},
                            {fromField: "hist_0", toField: "hist_0"},
                            {fromField: "hist_1", toField: "hist_1"},
                            {fromField: "hist_2", toField: "hist_2"},
                            {fromField: "hist_3", toField: "hist_3"},
                            {fromField: "hist_4", toField: "hist_4"},
                            {fromField: "hist_5", toField: "hist_5"},
                            {fromField: "hist_6", toField: "hist_6"},
                            {fromField: "hist_7", toField: "hist_7"}, // tolik potencialnich hist mappingu by melo stacit
                        ]
                        return ret;
                    }(),
                    dataProvider: payload.data,
                    categoryField: "date"
                }],
                categoryAxesSettings: this.defaultCategoryAxesSettings(payload.targetId),
                panels: [{
                    categoryAxis: {
                        equalSpacing: true,
                        parseDates: true,
                        labelFunction: this.myCategoryLabel
                    },
                    stockGraphs: function(){
                        let stockGr = []
                        stockGr.push({
                            id: "g_close",
                            type: "line",
                            valueField: "close",
                            lineColor: "#0000ff",
                            negativeLineColor: "#0000ff",
                            title: payload.labels[0],
                            lineThickness: 2,
                            useDataSetColors: false,
                            balloonFunction: function(item) {
                                var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                                return  item.graph.title + ": " + value
                            },
                            valueAxis: "va1"
                        })

                        var show = {}
                        for (var i in payload.misc.coms) {show[payload.misc.coms[i]] = {exp: new Date("2099-01-01"), index: -1, label: null}} // "prazdna" show sada
                        for (var key in show) { // otestovat a sestavit nejstarsi v kazde komodite
                            for (var i in payload.misc.exps) {
                                var label = payload.labels[parseInt(i)+1]
                                if (key == label.substring(0, label.length-3)) {
                                    var d = new Date(payload.misc.exps[i])
                                    if (d < show[key].exp) {
                                        show[key].exp = d
                                        show[key].index = parseInt(i)
                                        show[key].label = label
                                    }
                                }
                            }
                        }

                        // vlozit grafy vsech nejstarsich(nejblizsich) podkladu
                        var colorIndex = 0
                        for (key in show) {
                            var s = show[key]
                            stockGr.push({
                                id: "g_hist_" + s.index,
                                type: "line",
                                valueField: "hist_" + s.index,
                                lineColor: colors[colorIndex],
                                title: s.label,
                                lineThickness: 2,
                                useDataSetColors: false,
                                balloonFunction: function(item) {
                                    var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                                    return  item.graph.title + ": " + value
                                },
                                hidden: true,
                                valueAxis: "va2"
                            })
                            colorIndex += 1
                        }

                        return stockGr
                    }(),
                    valueAxes: [{
                        id: "va1",
                        position: "right",
                        fontSize: 13,
                        glc: payload.misc.glc
                    },{
                        id: "va2",
                        position: "left",
                        fontSize: 13,
                        gridAlpha: 0.0,
                        color: "#9400d3"
                    },{
                        id: "va3",
                        position: "left",
                        fontSize: 13,
                        gridAlpha: 0.0,
                        color: "#a0522d",
                        guides: [{
                            value: -500.0,
                            toValue: 50.0,
                            lineAlpha:0.0,
                            fillAlpha: 0.08,
                            fillColor: "#ffff00"
                        },{
                            value: 80.0,
                            toValue: 500.0,
                            lineAlpha:0.0,
                            fillAlpha: 0.08,
                            fillColor: "#ffff00"
                        }],
                        labelFunction: function(value, valueText, valueAxis) {
                            return valueText+"%"
                        }
                    }],
                    legend: {
                        position: "top",
                        maxColumns: 11,
                        fontSize: 14,
                        autoMargins: false,
                        marginRight: 0, 
                        marginLeft: this.defaultPanelSettings(payload.functionId).marginLeft,
                        reversedOrder: false,
                        equalWidths: false,
                        valueFunction: function(item) {
                            if (item.values === undefined) {return ""}
                            var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                            return (isNaN(value)) ? "" : value
                        },
                        /*
                        valueFunction: function(item) {
                            if (item.values === undefined) {
                                return ""
                            }

                            return parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                        }*/
                    },
                }],
                panelsSettings:  this.defaultPanelSettings(payload.functionId),
                valueAxesSettings: {inside: false},
                chartCursorSettings: {
                    valueBalloonsEnabled: true,
                    pan: this.isTouch(),
                    fontSize: 11,
                    valueLineEnabled: true,
                    valueLineBalloonEnabled: true
                },
                balloon: this.defaultBalloon(payload.functionId),
                chartScrollbarSettings: {enabled: false},
                periodSelector: {
                    inputFieldsEnabled: false,
                },
                listeners: [{
                    event: "zoomed",
                    method: function(event) {
                        return
                        var g = event.chart.panels[0].stockGraphs[0]
                        if (event.period == "DD") {
                            g.valueField = "close"
                        } else if (event.period == "WW") {
                            g.valueField = "week_close"
                        } else if (event.period == "MM") {
                            g.valueField = "month_close"
                        }

                        event.chart.validateData()
                    }
                    
                }]
            })
        } 
    }
}

