<template>
    <div class="subs-container">

        <div v-show="hasSubscription && !fsLoading">
            <div class="subs-plans">
                <div class="plans-box state-box">
                    <div class="plans-wrapper">
                        <table>
                            <tr>
                                <td class="plans-desc">
                                    <div v-if="renew">Renewal date</div>
                                    <div v-else>Current susbcription expiration date</div>
                                    <div>{{renewalDate}}</div>
                                </td>
                                <td class="plans-desc">
                                    <div v-if="renew">Next payment</div>
                                    <div v-if="renew">{{formatedPayment}}</div>
                                </td>
                                <td class="plans-actions" v-if="fsEnabled">
                                    <div v-if="subscription.upgradable && renew">
                                        <input type="button" class="button-green" @click="fsUpgradeSubscription" value="UPGRADE TO 1-YEAR SUBSCRIPTION" />
                                    </div>
                                    <div v-if="!subscription.upgradable && renew">
                                        <input type="button" class="button-lightred" @click="fsDowngradeSubscription" value="DOWNGRADE TO 3-MONTH SUBSCRIPTION" />
                                    </div>
                                    <div v-if=renew>
                                        <input type="button" class="button-red" style="margin-left: 20px;" @click="fsCancelSubscription" value="CANCEL SUBSCRIPTION" />
                                    </div>
                                    <div v-if="!renew">
                                        <input type="button" class="button-green" @click="fsRenewSubscription" value="RENEW SUBSCRIPTION" />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div v-if="renew" class="plans-label">
                You can update your credit card details or download an invoice at our <a href="https://spreadchartssro.onfastspring.com/account" target="blank">payment processor (FastSpring)</a>.
            </div>
        </div>

        <div v-show="hasSubscription && fsLoading">
            <div class="subs-plans">
                <div class="plans-box state-box">
                    <div class="plans-wrapper">
                        <div class="loader-wrapper">
                            <loader />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="!hasSubscription">
            <div class="subs-plans">
                <div class="plans-label">Get your Spreadcharts Premium plan today!</div>
                <div class="plans-box">
                    <div class="plans-wrapper">
                        <table>
                            <tr>
                                <td class="plans-desc">
                                    <div>SpreadCharts premium: 3-month subscription</div>
                                    <div>
                                        <ul>
                                            <li>unlimited access to the app</li>
                                            <li>trading signals powered by Artificial Intelligence</li>
                                            <li>research videos of the best opportunities in the markets</li>
                                            <li>quarterly Global Macro outlook</li>
                                            <li>advanced statistical studies like the Continuous histogram</li>
                                            <li>max 1000 items in the watchlist, instead of just 10 in the free version</li>
                                            <li>max 100 tabs in Charts (limited by your PC's performance), instead of just 3 in the free version</li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="plans-price">$119</td>
                                <td class="plans-btn">
                                    <input type="button" class="button-green" value="CHOOSE" @click="buy('spread-report-3-months')" />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="plans-box">
                    <div class="plans-wrapper">
                        <table>
                            <tr>
                                <td class="plans-desc">
                                    <div>SpreadCharts premium: 1-year subscription</div>
                                    <div>
                                        <ul>
                                            <li>all premium features as in the 3-month plan</li>
                                            <li>You'd save more than $100 per year with the yearly subscription</li>
                                        </ul>

                                    </div>
                                </td>
                                <td class="plans-price">$369</td>
                                <td class="plans-btn">
                                    <input type="button" class="button-green" value="CHOOSE" @click="buy('spread-report-1-year')" />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="plans-label">
                    By selecting the plan you agree to 
                    <a href="https://spreadcharts.com/terms-of-service/" target="blank">Terms of service</a> and 
                    <a href="https://spreadcharts.com/privacy-policy/" target="blank">Privacy policy</a>. 
                    <br> Additional VAT or currency conversion charges may apply.
                </div>
                <div class="plans-label">
                    The email you enter during checkout must be the same as your account on SpreadCharts.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {dSource} from './vars.js';
    import loader from "./loader.vue";

    import axios from 'axios';

    export default {
        name: 'Subscriptions',
        props: {
            user: {
                type: Object,
                required: true
            },
        },
        components: {
            loader
        },
        data() {
            return {
                dSource: dSource,
                plans: [
                    {"fsc-path":"spread-report-3-months"},
                    {"fsc-path":"spread-report-1-year"}
                ],
                subscription: {
                    valid: false,
                    upgradable: false
                },

                fsLoading: false,

                months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            }
        },
        methods: {
            /**
              * Nakup predplatneho
              * > reset (vyprazdni kosik pro jistotu)
              * > pridani do 'kosiku'
              * > checkout (aktivace popupu)
              */
            buy(productId) {
                fastspring.builder.reset()
                fastspring.builder.add(productId)
                fastspring.builder.checkout()
            },

            toSignals() {
                this.$emit("toSignals")
            },

            toResearch() {
                this.$emit("toResearch")
            },

            isSub() {
                return this.subscription.valid
            },

            /**
             * FastSpring eventy
             */
            // po zavreni popupu
            fsPopupClosed(e) {},
            // obecny event
            fsEventReceived(e) {},

            fsValidateOrder(e) {
                var robj = {
                    user: this.user,
                    rkeys: ["subscription"],
                    chain: [
                        {action: "Subscriptions", i:0, dkey:"subscription", request:{user:this.user, method:"fsValidateOrder", data:e.detail}}
                    ]
                }
                axios.post(this.dSource, {robj: robj}).then(payload => this.subscriptionCallback(payload.data.data))
            },
            fsUpgradeSubscription() {
                this.fsLoading = true
                var robj = {
                    user: this.user,
                    rkeys: ["subscription"],
                    chain: [
                        {action: "Subscriptions", i:0, dkey:"subscription", request:{user:this.user, method:"fsUpgradeSubscription"}}
                    ]
                }
                axios.post(this.dSource, {robj: robj}).then(payload => this.subscriptionCallback(payload.data.data))
            },
            fsCancelSubscription() {
                this.fsLoading = true
                var robj = {
                    user: this.user,
                    rkeys: ["subscription"],
                    chain: [
                        {action: "Subscriptions", i:0, dkey:"subscription", request:{user:this.user, method:"fsCancelSubscription"}}
                    ]
                }
                axios.post(this.dSource, {robj: robj}).then(payload => this.subscriptionCallback(payload.data.data))
            },
            fsRenewSubscription() {
                this.fsLoading = true
                var robj = {
                    user: this.user,
                    rkeys: ["subscription"],
                    chain: [
                        {action: "Subscriptions", i:0, dkey:"subscription", request:{user:this.user, method:"fsRenewSubscription"}}
                    ]
                }
                axios.post(this.dSource, {robj: robj}).then(payload => this.subscriptionCallback(payload.data.data))
            },
            fsDowngradeSubscription() {
                this.fsLoading = true
                var robj = {
                    user: this.user,
                    rkeys: ["subscription"],
                    chain: [
                        {action: "Subscriptions", i:0, dkey:"subscription", request:{user:this.user, method:"fsDowngradeSubscription"}}
                    ]
                }
                axios.post(this.dSource, {robj: robj}).then(payload => this.subscriptionCallback(payload.data.data))
            },
            subscriptionCallback(data) {
                this.subscription = data.subscription
                this.fsLoading = false
                this.$forceUpdate()
                this.$emit("subChanged")
            },
            setData(data) {
                this.subscription = data
                this.fsLoading = false
            }
        },
        mounted() {
            // predani eventu od FS storefrontu
            window.addEventListener("fsPopupClosed", this.fsPopupClosed)
            window.addEventListener("fsEventReceived", this.fsEventReceived)
            window.addEventListener("fsValidateOrder", this.fsValidateOrder)
        },
        computed: {
            loggedIn() {
                return this.user.id > 0
            },

            hasSubscription() {
                return this.subscription.valid                 
            },

            renewalDate() {
                if (this.subscription.next === undefined) {
                    return "Invalid date value"
                }

                var d = new Date(this.subscription.next)
                return this.months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear()
            },

            renew() {
                return this.subscription.renew
            },

            formatedPayment() {
                if (this.subscription.currency == "CZK") {
                    return this.subscription.payment + ",- Kč"
                } else if (this.subscription.currency == "USD") {
                    return "$" + this.subscription.payment
                } else if (this.subscription.currency == "EUR") {
                    return "€" + this.subscription.payment
                } else {
                    return this.subscription.currency + " " + this.subscription.payment
                }
            },

            fsEnabled() {
                return this.subscription.fs
            }
        }
    }
</script>

<style scoped>
    div.subs-container {
        margin: auto;
        color: #35323d;
    }

    div.subs-msg {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin: 20px 0px 80px 0px;
    }

    div.plans-label {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size :18px;
        text-align: center;
    }

    div.plans-label > a {
        font-weight: bold;
        color: #f8a60d;
        text-decoration: none;
    }

    div.plans-label > a:hover {
        text-decoration: underline;
    }

    div.plans-box {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 30px;
        margin-top: 15px; 
        box-shadow: rgba(35, 31, 32, 0.16) 0px 24px 27px 0px;
        width: calc(100% - 60px);
    }

    div.plans-wrapper {
    }

    div.plans-wrapper > table {
        width: 100%;
        border-collapse: collapse;
    }

    div.plans-wrapper > table td {
        text-align: right;
    }

    div.plans-wrapper > table td.plans-desc {
        text-align: left;
    }

    div.plans-wrapper > table td{
        vertical-align: top;
    }

    div.plans-wrapper > table td.plans-btn,  div.plans-wrapper > table td.plans-price {
        vertical-align: middle !important;
    }

    td.plans-desc > div:first-child {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    td.plans-btn {
        width: 140px;
    }
 
    td.plans-price {
        width: 150px;
        font-size: 36px;
        font-weight: bold;
    }

    td.plans-actions > div {
        text-align: right;
        margin-bottom: 5px;
    }
    
    td.plans-actions > button {
        display: inline-block;
    }

    button.choose-btn {
        background-color: #3c9d3a;
        color: #ffffff;
        font-size: 11px;
        padding: 14px 22px 14px 22px;
        display: inline-block;
        font-weight: 700;
        border-radius: 18px;
        cursor: pointer;
        border: none;
    }

    div.state-box {
        height: 100px;
    }

    div.loader-wrapper {
        height: 10px;
        margin: auto;
        margin-top: -40px;
        margin-bottom: 20px;
    }
</style>
