<template>
    <div>
        <div class="block-header" style="margin-bottom: 0px;">
            <span>Research</span>
        </div>

        <div class="block-list-wrapper">            
            <ul>
                <li v-for="(video, index) in vids" :key="index" @click="onVideoClicked(video.id)">
                        <div class="block-list-item-wrapper">

                            <div class="block-list-item-published" >
                                <span>{{formatDate(video.date)}}</span>
                            </div>
                            <div class="block-list-item-title" >
                                <span>{{video.name}}</span>
                            </div>
                            <div class="block-list-item-excerpt" style="margin-top: 5px">
                                <div class="tag-box" v-for="tag in video.tags" :style="{'background-color': tags[tag].bcolor, 'color':tags[tag].color}">
                                    {{tags[tag].name}}
                                </div>
                            </div> 
                        </div>
                        <hr class="block-list-separator" v-if="index < 3 ">
                </li>  
            </ul>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {dSource} from './vars.js';
    
    export default {
        name: 'WidgetResearch',
        components: {
        },
        props: {
            user: {
                type: Object,
                required: true
            },
            widgetResearchData: {
                required: true
            }
        }, 
        data() {
            return {
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                dSource: dSource,
                videos: null,
                tags: null,
                lang: "en",

                vids: []
            }
        },
        methods: {
            setData(data) {
                this.videos = data.data
                this.tags = data.tags
            },

            listVideos() {
                var tmp = []
                for (var i in this.videos) {
                    if (this.videos[i].lang == this.lang) {
                        tmp.push(this.videos[i])
                    }

                    if (tmp.length >= 4) {break}
                }

                this.vids = tmp
            },

            formatDate(dstring) {
                var d = new Date(dstring)
                return this.months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear()
            }, 

            onVideoClicked(video_id) {
                this.$emit("videoClicked", video_id)
            },

            setLang(lang) {
                this.lang = lang
                this.listVideos()
            }
        },
        watch: {
            widgetResearchData(data) {
                this.videos = data.data
                this.tags = data.tags
                this.listVideos()
            }
        },
        computed: {
            loggedIn() {
                return this.user.id > 0
            }          
        }
    }
</script>

<style scoped>
    div.block-list-wrapper > ul {
        margin-top: -5px;
        margin-bottom: 10px;
    }

    div.block-list-wrapper > ul > li > div:hover {
        cursor: pointer;
        background-color: #eeeeee;
    }


    div.block-list-wrapper div.block-list-item-title {
        color: rgba(55, 55, 55, 0.9);
    }

    div.block-list-wrapper div.block-list-item-excerpt,
    div.block-list-wrapper div.block-list-item-published {
        color: rgba(55, 55, 55, 0.6);
    }

    div.tag-box {
        display: inline-block;
        margin-right: 5px;
        border-radius: 3px;
        padding: 3px 6px 3px 6px;
        font-size: 13px;
    }
</style>
