<template>
    <div class="dashboard-container">
        <div class="left-container">
            <div class="widget-wrapper" ref="watchlist_container">
            </div>
        </div>

        <div class="right-container">
            <div class="widget-wrapper" v-if="loggedIn">
                <WidgetSignals 
                    :user="user" 
                    :signalsData="widgetSignalsData"
                    ref="WidgetSignals" 
                    @signalClicked="onSignalClicked"/>
            </div>

            <div class="widget-wrapper" v-if="loggedIn">
                <WidgetResearch 
                    :user="user" 
                    :widgetResearchData="widgetResearchData"
                    ref="WidgetResearch" 
                    @videoClicked="onVideoClicked"
                />
            </div>

            <div class="widget-wrapper">
                <WidgetArticle :lang="lang" ref="WidgetArticle"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    import Watchlist2 from './Watchlist2.vue'
    var Watchlist2Class = Vue.extend(Watchlist2)

    import WidgetArticle from './WidgetArticle.vue';
    import WidgetResearch from './WidgetResearch.vue';
    import WidgetSignals from './WidgetSignals.vue';

    export default {
        name: 'Dashboard',
        components: {
            WidgetArticle, WidgetResearch, WidgetSignals
        },
        props: {
            user: {
                type: Object,
                required: true
            },
        }, 
        data() {
            return {
                watchlist: null,

                widgetSignalsData: [],

                widgetResearchData: [],

                lang: "en",

                scroll: 0
            }
        },
        methods: {
            createWatchlist() {
                this.watchlist = new Watchlist2Class({
                    propsData: {
                        user: this.user
                    }
                })

                this.watchlist.$on("chart", this.uxAdded)

                this.watchlist.$mount()
                this.$refs.watchlist_container.appendChild(this.watchlist.$el)
            },

            setWatchlistData(data) {
                this.watchlist.setData(data)
            },

            reloadWatchlist() {
                this.watchlist.reload()
            },

            setSignalsData(data) {
                this.widgetSignalsData = data
            },

            setResearchData(data) {
                this.widgetResearchData = data
            },

            uxAdded(ux) {
                this.$emit("uxAdded", ux)
            },

            onVideoClicked(video_id) {
                this.$emit("videoClicked", video_id)
            },

            onSignalClicked(signal_id) {
               this.$emit("signalClicked", signal_id) 
            },

            onDeleted() {
                this.$emit("watchlistDeleted")
            },

            onCategoryChanged() {
                this.$emit("categoryChanged")
            },

            getTags() {
                return this.watchlist.getTags()
            },

            setLang(lang) {
                this.$refs.WidgetResearch.setLang(lang)
                this.$refs.WidgetArticle.setLang(lang)
            },

            getScroll() {
                return this.scroll
            },

            saveScroll(value) {
                this.scroll = value
            }
        },
        mounted() {
            this.createWatchlist()
        },
        computed: {
            loggedIn() {
                return this.user.id > 0
            }          
        },
    }
</script>

<style scoped>
    div.dashboard-container > div {
        display: inline-block;
    }

    div.left-container > div {
        min-height: 678px; 
    }

    div.right-container {
    }
    
    @media screen and (min-width: 1300px) {
        div.left-container {
            width: calc(65% - 5px);
            margin-right: 10px;
            float: left;
        }

        div.right-container{
            width: calc(35% - 5px);
            float: right;
        }
    }

    @media screen and (max-width: 1299px) {
        div.dashboard-container > div {
            width: calc(100%);
        }
    }

    div.widget-wrapper {
        margin-bottom: 10px;
        background-color: rgba(255,255,255,1);
        box-shadow: 3px 0px 26px -3px rgba(0,0,0,0.25);
        border-radius: 5px;
    }
</style>
