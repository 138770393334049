<template>
    <div id="container">

        <div class="logo">
            <img src="/img/sc_logo_full.748b3815.svg" />
        </div>

        <div class="content" v-show="!done">
            <table class="ftable">
                <tr>
                    <td>
                        <div class="msg-wrapper">
                            You can reset your SpreadCharts.com user password below.
                        </div>
                    </td>
                </tr>

                <!-- heslo -->
                <tr>
                    <td class="error">
                        <div v-show="!valid.password">
                            {{msg.password}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td >
                        <input type="password" autocomplete="new-password" v-model="password" :placeholder="lang == 'cs' ? 'Heslo' : 'Password'" :class="{'error': !valid.password}">
                    </td>
                </tr>

                <!-- potvrzeni hesla -->
                <tr>
                    <td class="error">
                        <div v-show="!valid.password_test">
                            {{msg.password_test}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="password" autocomplete="new-password" v-model="password_test" :placeholder="lang == 'cs' ? 'Heslo znovu' : 'Confirm password'" :class="{'error': !valid.password_test}">
                    </td>
                </tr>

                <tr>
                    <td>
                        <div class="submit-button" @click="submit">
                            <div v-if="lang == 'cs'">
                                ZMĚNIT HESLO
                            </div>
                            <div v-else>
                                APPLY
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="content" v-show="done">
            <div class="done-wrapper">
                Password has been changed.
            </div>
        </div>
    </div>
</template>

<script>
    import {dSource} from './vars.js';
    import loader from './loader.vue';
    import axios from 'axios';

    export default {
        name: 'PasswordReset',
        components: {loader},
        props: {
            code: {
                required: true,
                type: String
            }
        },
        data() {
            return {
                lang: 'en',

                dSource: dSource,

                password: null,
                password_test: null,

                valid: {
                    password: true,
                    password_test: true
                },

                msg: {
                    password: null,
                    password_test: null
                },

                loading: false,
                done: false
            }
        },
        methods: {
            init() {
                this.password.value = null
                this.password.valid = true
                this.password_again.value = null
                this.password_again.valid = true
            },

            submit() {
                this.validateAll()

                if (!this.submitable) {return}

                // odeslat
                var robj = {
                    rkeys: ["result"],
                    chain: [
                        {action: "ForgotenPass", i:0, dkey: "result", request:{method: "reset", data: {password: this.password, code: this.code} }}
                    ]
                }
                this.done = true
                axios.post(dSource, {robj: robj}).then(payload => this.submitCallback(payload.data.data.result))
            },

            submitCallback(data) {},

            validateAll() {
                this.validatePassword(this.password)
                this.validatePasswordTest(this.password_test)
            },

            validatePassword(value) {
                // vyplneno?
                this.valid.password = value != null && value.length > 0
                if (!this.valid.password) {
                    this.msg.password = this.lang == 'cs' ? "Vyplňte heslo." : "Choose your password."
                    return
                }

                // minimalni delka
                this.valid.password = value.length >= 10
                if (!this.valid.password) {
                    this.msg.password = this.lang == 'cs' ? "Nejméně 10 znaků." : "At least 10 characters."
                    return
                }
            },
            validatePasswordTest(value) {
                // vyplneno?
                this.valid.password_test = value != null && value.length > 0
                if (!this.valid.password_test) {
                    this.msg.password_test = this.lang == 'cs' ? "Vyplňte heslo znovu." : "Confirm your password."
                    return
                }

                // minimalni delka
                this.valid.password_test = value.length >= 10
                if (!this.valid.password_test) {
                    this.msg.password_test = this.lang == 'cs' ? "Nejméně 10 znaků." : "At least 10 characters."
                    return
                }

                // stejne jako prvni?
                this.valid.password_test = value == this.password
                if (!this.valid.password_test) {
                    this.msg.password_test = this.lang == 'cs' ? "Hesla nejsou stejná." : "Passwords are different."
                    return
                }
            },
        },
        watch: {
            password(value) {
                this.validatePassword(value)
            },
            password_test(value) {
                this.validatePasswordTest(value)
            },
        },
        computed: {
            submitable() {
                for (var key in this.valid) {
                    if (!this.valid[key]) {
                        return false
                    }
                }
                return true
            }  
        },
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

    div#container {
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        height: 370px;
        width: 545px;
        margin: auto;
    }

    div.content {
        border: 1px solid #fafafa;
        display: inline-block;
        box-shadow: rgba(35, 31, 32, 0.16) 0px 24px 27px 0px;
        border-radius: 20px;
        margin: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }

    table.ftable {
        margin-top: 30px;
        font-size: 14px;
        font-family: inherit;
        width: 100%;
    }

    td {
        text-align: center;
    }

    td > input,
    td > select {
        font-size: inherit; 
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        margin: 0px 10px 2px 10px;
    }

    input::placeholder {
        color: #888;
    }

    td > input[type="text"],
    td > input[type="password"],
    td > select {
        padding: 8px 12px 8px 12px;
        font-family: inherit;
        width: 80%;
    }

    td.checkbox {
        text-align: left;
    }

    td.checkbox > input[type="checkbox"] {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        margin-left: 40px;
    }

    td.checkbox > label {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        top: -1px;
        font-weight: 700;
    }

    td.checkbox > label > a,
    td.checkbox > label > a:visited {
        text-decoration: none;
        color: #f76c22;
    }

    td.select {
        text-align: left;
        padding-left: 30px;
    }

    td.select > select {
        width: calc(100% - 50px);
        color: #888;
        font-weight: normal;
    }

    td.spacer {
        height: 10px;
    }

    input.error,
    select.error {
        border: 1px solid rgba(255, 0, 0, 0.25);
        -webkit-box-shadow: 2px 2px 5px 0px rgba(250, 0, 0, 0.25);
        -moz-box-shadow: 2px 2px 5px 0px rgba(250, 0, 0, 0.25);
        box-shadow: 2px 2px 5px 0px rgba(250, 0, 0, 0.25);
    }

    input.error[type="checkbox"] {
        box-shadow: none;
    }

    td.error {
        font-size: 11px;    
        height: 15px;
        padding-top: none;
        padding-bottom: none;
        color: red;
        text-align: left;
        padding-left: 40px;
    }

    div.submit-button {
        font-size: 15px;
        font-weight: 700;
        color: white;
        background-color: #3c9d3a;
        margin-top: 30px;
        padding: 20px 50px 20px 50px;
        border-radius: 31px;
        display: inline-block;
        cursor: pointer;
        letter-spacing: 0.75px;
    }

    div.loader-wrapper {
        text-align: center;
        width: 100%;
        margin-top: 150px;
    }

    div.done-wrapper {
        margin-top: 155px;
        font-size: 20px;
        font-weight: 700;
        color: rgba(100, 100, 100, 1.0);
    }

    div.msg-wrapper {
        font-size: inherit;
        font-weight: 700;
        padding: 20px 20px 10px 20px;

    }

    div.logo > img {
        width: 80%;
        margin-top: 30px;
        margin-bottom: 30px;

    }
</style>
