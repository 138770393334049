// import AmCharts from 'amcharts3';
// import AmStock from 'amstock3/amcharts/amstock';
// import AmSerial from 'amcharts3/amcharts/serial';

import defaultCategoryAxesSettings from './commonFunctions.js'
import myCategoryLabel from './commonFunctions.js'
import defaultPanelSettings from './commonFunctions.js'
import defaultBalloon from './commonFunctions.js'
import isTouch from './commonFunctions.js'

export default {
    mixins:[defaultCategoryAxesSettings, myCategoryLabel, defaultPanelSettings, defaultBalloon, isTouch],
    methods: { 
        seasonalityStacked(payload) {

            return window.AmCharts.makeChart(payload.targetId, {
                // ------------
                type: "stock",
                theme: "none",
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                dataSets: [{
                    fieldMappings: [
                        {fromField: "date", toField: "date"},
                        {fromField: "value0", toField: "value0"},
                        {fromField: "value1", toField: "value1"},
                        {fromField: "value2", toField: "value2"},
                        {fromField: "value3", toField: "value3"},
                        {fromField: "value4", toField: "value4"},
                        {fromField: "value5", toField: "value5"},
                        {fromField: "value6", toField: "value6"},
                        {fromField: "value7", toField: "value7"},
                        {fromField: "value8", toField: "value8"},
                        {fromField: "value9", toField: "value9"}
                    ],
                    dataProvider: payload.data,
                    categoryField: "date"
                }],
                panels: [{
                    categoryAxis: {
                        equalSpacing: true,
                        parseDates: true,
                        labelFunction: this.myCategoryLabel
                    },
                    stockGraphs: function() {
                        var tmp = []
                        for (var i=payload.labels.length -1 ; i>=0; i--) {
                            tmp.push({
                                type: "line",
                                valueField: "value"+i,
                                lineColor: payload.colors[i],
                                title: payload.labels[i],
                                lineThickness: 2,
                                useDataSetColors: false,
                                balloonFunction: function(item) {
                                    var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                                    return  item.graph.title + ": " + value
                                },
                            })
                        }
                        return tmp
                    }(),
                    valueAxes: [{
                        position: "right",
                        fontSize: 13,
                        labelFunction: function(value, valueText, valueAxis){
                            return valueText
                        },
                        glc: payload.misc.glc
                    }],
                    legend: {
                        position: "top",
                        maxColumns: 11,
                        fontSize: 14,
                        autoMargins: false,
                        marginRight: 0, 
                        marginLeft: this.defaultPanelSettings(payload.functionId).marginLeft,
                        reversedOrder: true,
                        valueFunction: function(item) {
                            if (item.values === undefined) {return ""}
                            var value = parseFloat(item.values.value).toFixed(payload.misc.round_prec);                
                            return (isNaN(value)) ? "" : " " + value
                        }
                    }
                }],
                panelsSettings: this.defaultPanelSettings(payload.functionId),
                chartCursorSettings: {
                    valueBalloonsEnabled: true,
                    //pan: is_touch_device(), !!!!!!!!!!!!!!!!!!!!!!!!!!'
                    pan: this.isTouch(),
                    fontSize: 11,
                    valueLineEnabled: true,
                    valueLineBalloonEnabled: true
                },
                valueAxesSettings: {
                    inside: false
                },
                categoryAxis: {
                    parseDates: true
                },
                categoryAxesSettings: this.defaultCategoryAxesSettings(payload.functionId),
                chartScrollbarSettings : {enabled: false},
                periodSelector: {inputFieldsEnabled: false},
                balloon : this.defaultBalloon(payload.functionId),
            })
        }
    }
}

