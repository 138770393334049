<template>
    <div class="container">

        <div v-if="step == 0">
            <table class="form-table">
                <tr>
                    <td class="td-label">
                        Your e-mail address
                    </td>
                    <td class="td-error">
                        <span v-show="!email.valid">{{email.error}}</span>
                    </td>
                </tr>

                <tr>
                    <td class="td-input" colspan=2 :class="{'error': !email.valid}">
                        <input type="text" v-model="email.value" style="width: calc(100% - 5px)"/>
                    </td>
                </tr>

                <tr>
                    <td class="td-label">
                        Your message or question
                    </td>
                    <td class="td-error">
                        <span v-show="!msg.valid">{{msg.error}}</span>
                    </td>
                </tr>
                <tr>
                    <td class="td-input" colspan=2 :class="{'error': !msg.valid}">
                        <textarea rows="10" cols="50" v-model="msg.value" style="resize: none; width: calc(100% - 5px);">
                        </textarea>
                    </td>
                </tr>


                <tr>
                    <td class="td-submit">
                        <input type="button" class="button-blue" value="SEND" @click="send" />
                    </td>
                </tr>

            </table>
        </div>

        <div v-if="step == 1">
            <div class="loader-wrapper">
                <loader />
            </div>
        </div>

        <div v-if="step == 2">
            <div class="thanks-wrapper">
                Your message has been sent.
            </div>
        </div>
    </div>
</template>

<script>
    import {dSource, DEBUG} from "./vars.js";
    import loader from './loader.vue';
    import axios from 'axios';

    export default {
        name: 'ContactForm',
        props: {
            user: {
                type: Object,
                required: true
            },
        },
        components: {loader},
        mixins: [],
        data() {
            return {
                dSource: dSource,
                DEBUG: DEBUG,

                email: {
                    value: null,
                    valid: true,
                    error: null
                },
                msg: {
                    value: null,
                    valid: true,
                    error: null
                },

                step: 0
            }
        },
        mounted() {
        }, 
        methods: {
            init() {
                // email
                this.email.value = this.loggedIn ? this.user.email : null
                this.email.valid = true
                this.email.error = null

                // msg
                this.msg.value = null
                this.msg.valid = true
                this.msg.error = null

                this.step = 0
            },

            validate() {
                // validace email
                this.email.valid = true
                this.email.valid &= /^.{1,1000}@.{1,1000}\.[a-zA-Z0-9]{1,20}$/.test(this.email.value)
                if (!this.email.valid) {
                    this.email.error = "Incorrect e-mail address"
                }

                // validace msg
                this.msg.valid = true
                this.msg.valid &= this.msg.value !== null
                if (!this.msg.valid) {
                    this.msg.error = "Fill in your message"
                }

                return this.email.valid && this.msg.valid
            },

            send() {
                // validace
                if (!this.validate()) {return}

                var robj = {
                    targetId: "contact",
                    raw: "None",
                    chain: [
                        {action: "contact", i:0, dkey:"result", request:{user: this.user, method:"send", data:{email:this.email.value, msg:this.msg.value}}}
                    ]
                } 

                this.step = 1
                axios.post(this.dSource, {robj: robj}).then(payload => this.sendCallback(payload.data.data))
            },

            sendCallback(data) {
                this.step = 2
                var self = this
                setTimeout(function() {
                    self.$emit("close")        
                }, 500)
            }
        },
        computed: {
            loggedIn() {
                return this.user.id > 0
            }          
        }
    }
</script>

<style scoped>
    div.container > div {
        width: 426px;
    }

    div.loader-wrapper {
        position: relative;
        margin: auto;
        top: 70px;
    }

    div.thanks-wrapper {
        position: relative;
        top: 130px;
        color: #555;
        font-size: 20px;
        text-align: center;
    }
</style>
