<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" @click="close" v-show="show">
            <div class="video-container" @click.stop>
                <div 
                    class="player-box"
                    :id="'player-' + videoId"
                    :style="{width: videoWidth+'px', height: height+'px', top: top+'px', left: videoLeft+'px'}"
                >

                </div>

                <div
                    class="bookmarks-box"
                    :style="{width: bookmarksWidth+'px', height: height+'px', top: top+'px', left: videoLeft+videoWidth+'px'}"
                >

                    <div class="bookmarks-wrapper" :class="{'bookmarks-out': out, 'bookmarks-in': !out}" :style="{height: height+'px'}">
                        <div class="bookmarks-clicker" @click="out = false; close()">
                            <i :class="'icons8-delete-2'" />
                        </div>

                        <div style="clear: both"></div>

                        <div v-show="bookmarks.length > 0" class="bookmarks-clicker" @click="out = !out">
                            <i :class="'icons8-news-feed-filled'" />
                        </div>

                        <div style="clear: both"></div>

                        <div class="bookmarks-items" :style="{height: height-101+'px'}" v-show="out">
                            <div v-for="group in bookmarks">
                                <div class="bookmark-name" v-if="group.name !== null">{{group.name}} <br></div>
                                <ul class="video-bookmarks">
                                    <li v-for="bookmark in group.bookmarks" >
                                        {{formatTime(bookmark.seek)}} <span @click="videoSeek(bookmark.seek)">{{bookmark.name}}</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'Video',
        components: {},
        props: {
            show: {
                type: Boolean,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            videoId: {
                type: String,
                required: true,
            },
            bookmarks: {
                type: Array,
                default: function() {
                    return []         
                }
            },
            margin: {
                type: Number,
                default: 50
            }
        },
        data() {
            return {
                top: 0,
                height: 0,
                videoWidth: 0,
                videoLeft: 0,
                bookmarksWidth: 50,

                out: false,

                player: null
            }
        },
        methods: { 
            close() {
                var self = this
                this.player.destroy().then(function() {
                    self.player = null        
                })
                this.$emit('close');
            },

            resize() {
                if (!this.show) {return}

                var doc = document.documentElement
                var w = doc.clientWidth
                var h = doc.clientHeight

                if (h / w < 0.5625) { // sirsi nez 16:9
                    this.height = h - 2 * this.margin
                    this.videoWidth = this.height / 0.5625 
                } else {
                    this.videoWidth = w - 2 * this.margin - this.bookmarksWidth
                    this.height = this.videoWidth * 0.5625
                }

                this.top = (h - this.height) / 2
                this.videoLeft = (w - this.videoWidth - this.bookmarksWidth) / 2

                this.createPlayer()

                return
            },

            createPlayer() {
                if (!this.show) {return}

                try {
                    if (this.player === null) {throw "Player does not exist."}

                    var self = this
                    var currentTime = 0
                    var paused = true

                    this.player.getPaused().then(function(p) {
                        paused = p
                    }).then(function() {
                        self.player.getCurrentTime().then(function(t) {
                            currentTime = t        
                        }).then(function() {
                            self.player.destroy().then(function() {
                                self.player = new Vimeo.Player("player-"+self.videoId, {
                                    id: self.videoId,
                                    width: self.videoWidth,
                                    height: self.height,
                                    byline: false,
                                    portrait: false,
                                    title: false
                                })
                            }).then(function() {
                                self.player.setCurrentTime(currentTime)
                                if (!paused) {
                                    self.player.play()
                                }
                            })
                        })
                    })
                } catch (error) {

                    this.player = new Vimeo.Player("player-"+this.videoId, {
                        id: this.videoId,
                        width: this.videoWidth,
                        height: this.height,
                        byline: false,
                        portrait: false,
                        title: false
                    })
                }
            },

            /**
              * Skok na cas ve videu
              */
            videoSeek(seek) {
                seek = this.seekToSeconds(seek)
                this.player.setCurrentTime(seek).then(function(seek) {
                }).catch(function(error) {
                    console.log(error)
                })
            },

            /**
              * bookmark time --> seconds
              */
            seekToSeconds(seek) {
                if (typeof seek == "int" || typeof seek == "float" || typeof seek == "number") {
                    return seek
                }

                var tmp = seek.split(":")

                var m = parseInt(tmp[0])
                var s = parseInt(tmp[1])

                return m * 60 + s
            },

            /**
              * formatovani casu na mm:ss
              */
            formatTime(t) {
                if (typeof t == "string") {
                    return t
                }

                // minutest & seconds
                var m = Math.floor(t / 60)
                var s = t - m * 60

                // leading zeros formated string
                return ((m < 10) ? "0"+m : m) + ":" + ((s < 10) ? "0"+s : s)
            },
            
        },

        watch: {
            show(value) {
                if (!value) {return}
                this.resize()
            }
        },

        mounted: function() {
            document.addEventListener("keydown", (e) => {
                if (e.keyCode == 27 && this.show) {
                    this.close()
                }
            });         

            // reakce na konec resize
            var self = this
            var resizeTimer
            window.addEventListener("resize", (e) => {
                clearTimeout(resizeTimer)
                resizeTimer = setTimeout(function() {
                    self.resize()
                }, 250)
            });
        }
    }
</script>

<style scoped>
    div.modal-backdrop {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 10000;
    }

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }
    
    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .25s ease;
    }

    .video-container {
    }

    .player-box, .bookmarks-box  {
        position: absolute;
    }

    .bookmarks-wrapper {
        position: relative;
        background-color: rgba(210, 210, 210, 1.0);
        border: none;
        border-left: 2px solid rgba(0, 0, 0, 0.75);
    }

    .bookmarks-out {
        width: 400px;
        right: 350px;
    }

    .bookmarks-in {
        width: 50px;
    }

    .bookmarks-clicker {
        display: inline-block;
        height: 30px;
        width: 30px;
        float: right;
        margin: 10px 10px 0px 10px;
        cursor: pointer
    }

    .bookmarks-clicker > i {
        color: rgba(0, 0, 0, 0.75);
        font-size: 30px;
        vertical-align: top;
    }

    div.bookmarks-items {
        padding: 10px 10px 0px 10px;
        margin-right: 0px;
        margin-top: 10px;
        display: inline-block;
        overflow-y: scroll;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
    }

    div.bookmark-name {
        border-bottom: 1px solid #888;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 2px;
        padding-bottom: 2px;
    }
        
    ul.video-bookmarks {
        padding-left: 0px;
        margin-top: 0px;
    }

    ul.video-bookmarks > li {
        list-style: none;
        font-size: 16px;
        margin-bottom: 3px;
    }

    ul.video-bookmarks > li > span {
        cursor: pointer;
        color: #1976d2;
    }

    ul.video-bookmarks > li > span:hover {
        text-decoration: underline;
    }
</style>
