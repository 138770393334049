<template>
     <div class="container">

        <div v-if="loggedIn">
            <div>
                <div v-for="line in getLines()" class="report-box">
                    <table class="report-table">
                        <tr>
                            <td class="report-date">
                                {{formatDate(line.date)}}
                            </td>

                            <td class="report-name">
                                {{formatName(line.name)}}
                            </td>

                            <td class="report-tags">
                                <div v-for="tag in getTags(line.tags)" class="tag-box" :style="{'background-color': tag.bcolor, 'color':tag.color}">
                                    {{tag.name}}
                                </div>
                            </td>

                            <td class="report-buttons">
                                <input v-if="line.enabled" type="button" value="Watch video" class="button-green" @click="startVideo(line)" />
                                <input v-else type="button" value="Get this trading opportunity" class="button-lightred" @click="toSubscription()" />
                            </td>
                        </tr>
                    </table>
                </div>
            </div>


            <div class="lang-filters">
                <div class="lang-selector">
                    <div class="lang-wrapper">
                        <div>
                        Select language / Vyberte jazyk:
                        </div>
                        <div style="margin-right: 10px;">
                        <span class="flag-icon flag-icon-us" @click="setLanguage('en')" :class="{'active': language == 'en'}"></span> 
                        <span class="flag-icon flag-icon-cz" @click="setLanguage('cz')" :class="{'active': language == 'cz'}"></span> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="report-box msg-nouser">
                Please sign in first to use this tool
            </div>
        </div>

        <!-- Okno s videem -->
        <Video v-if="video !== null" :videoId="video.id" :name="video.name" :show="showVideo" @close="showVideo = false"/>

        <!-- Okno pro nepredplacene u premium videi -->
        <modal3 v-if="video !== null" :show="showNonMembers" @close="showNonMembers = false">
            <div slot="header">{{video.name}}</div>
            <div slot="body">
                <div style="font-size: 16px; font-weight: bold; color: #373737; width: 650px; text-align: center;">
                This trading opportunity is available exclusively to the premium subscribers. Become a premium subscriber and don't miss this trading opportunity.
                </div>

                <div style="margin-top: 20px; text-align: center; margin-bottom: 10px">
                    <input type="button" class="button-lightred" value="Get premium" style="font-size: 16px" @click="video.active = false; toSubscription()"/>
                </div>
            </div>
        </modal3>
    </div>
</template>

<script>
    import {dSource} from './vars.js';

    import Video from './Video.vue';
    import modal3 from './modal3.vue';

    export default {
        name: 'Research',
        props: {
            user: {
                type: Object,
                required: true
            },
        },
        components: {Video, modal3},
        data() {
            return {
                dSource: dSource,
                vdata: [],
                tags: [],
                months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                language: "en",
                video: null,

                showVideo: false,
                showNonMembers: false,

                scroll: 0
            }
        },
        methods: {
            getLines() {
                var out = []
                for (var i in this.vdata) {
                    if (this.vdata[i].lang == this.language) {
                        out.push(this.vdata[i])
                    }
                }

                return out
            },

            formatDate(dstring) {
                var d = new Date(dstring)
                return this.months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear()
            },

            formatName(name) {
                return name
            },

            getTags(tags) {
                var out = []

                for (var i in tags) {
                    out.push(this.tags[tags[i]])
                }

                return out
            },

            startVideo(data) {

                this.video = {
                    id: data.videoId+"",
                    name: data.name,
                    enabled: data.enabled
                }

                var self = this
                setTimeout(function() {
                    if (self.video.enabled) {
                        self.showVideo = true
                        self.nowNonMembers = false
                    } else {
                        self.showVideo = false
                        self.showNonMembers = true
                    }
                }, 100)
            },

            startVideoById(videoId) {
                var tmp = null

                for (var i in this.vdata) {
                    if (this.vdata[i].id == videoId) {
                        tmp = this.vdata[i]
                        break
                    }
                }

                this.startVideo(tmp)
            },

            // prechod do sekce spravy predplatneho
            toSubscription() {
                this.$emit("toSubscription")
            },

            // kvuli ukladani jazyka
            getLanguage() {
                return this.language
            },

            setLanguage(language) {
                this.language = language 
                this.$emit("langChanged", language)
            },

            setData(data) {
                this.vdata = data.data
                this.tags = data.tags
            },

            getScroll() {
                return this.scroll
            },

            saveScroll(value) {
                this.scroll = value
            }
        },
        computed: {
            loggedIn() {
                return this.user.id > 0
            }
        }
    }
</script>

<style scoped>
    div.report-box { 
        border: 1px solid #eee;
        border-radius: 10px;
        background-color: #ffffff;
        margin: auto;
        margin-bottom: 10px;
        box-shadow: 3px 0px 26px -3px rgba(0,0,0,0.25);
        position: relative;
        width: calc(100%);
        font-size: 16px;
    }

    table.report-table {
        border-collapse: collapse;
        width: 100%;
        margin: 10px 0px 10px 0px;
    }

    td {
        padding: 3px 0px 3px 5px;
    }

    td.report-date {
        width: 100px;
        color: #373737;
        text-align: right;
        padding-right: 15px;
        padding-left: 10px;
    }

    td.report-name {
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 50px;
        border-left: 1px solid #aaaaaa;
        padding-left: 15px;
    }

    td.report-tags {
        width: calc(30%);
        text-align: center;
        font-size: 15px;
    }

    div.tag-box {
        display: inline-block;
        padding: 5px 8px 7px 8px;
        border-radius: 8px;
        margin-right: 5px;
    }

    td.report-buttons {
        text-align: right;
        width: 230px;
        padding-right: 3px;
    }

    td.report-buttons > input[type="button"] {
        font-size: 15px;
    }


    div.video-wrapper {
        width: 800px;
        height: 450px;
    }

    div.video-wrapper > iframe {
        width: 100%;
        height: calc(100%);
    }

    div.lang-filters {
        border: 1px solid #dddddd;
        border-bottom: none;
        position: fixed;
        bottom: 0px;
        right: 40px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #fff;
        padding: 5px;
        box-shadow: 3px 0px 26px -3px rgba(0,0,0,0.25);
    }

    div.lang-wrapper {
        color: #373737;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
    }

    div.lang-wrapper > div {
        display: inline-block;
    }

    div.lang-selector  span {
        cursor: pointer;
        font-size: 20px;
        margin: 0px 0px 0px 10px;
        opacity: 0.3;
    }

    div.lang-selector  span:hover, div.lang-selector span.active {
        opacity: 1;
    }

    div.msg-nouser {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #aaaaaa;
        padding: 20px 0px 20px 0px;
        width: 500px;
        margin-top: calc(100vh / 2 - 90px);
    }
</style>
