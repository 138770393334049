<template>
    <div class="form-container">
        <table class="form-table">
            <tr>
                <td class="td-label">
                    E-mail
                </td>
                <td class="td-error">
                    <span v-show="!email.valid">{{email.error}}</span>
                </td>
            </tr>
            
            <tr>
                <td class="td-input" colspan=2 :class="{'error': !email.valid}">
                    <input type="text" autocomplete="email" v-model="email.value" @change="isValid" v-on:keyup.enter="submit"/>
                </td>
            </tr>

            <tr>
                <td class="td-label">
                    Password
                </td>
                <td class="td-error">
                    <span v-show="!password.valid">{{password.error}}</span>
                </td>
            </tr>

            <tr>
                <td class="td-input" colspan=2 :class="{'error': !password.valid}">
                    <input type="password" autocomplete="current-password" v-model="password.value" v-on:keyup.enter="submit"/>
                </td>
            </tr>

            <tr>
                <td class="td-input" colspan="2">
                    <span class="forgoten-clicker" @click="forgoten">Lost password?</span>
                </td>
            </tr>

            <tr>
                <td class="td-submit" colspan=2>
                    <input type="button" class="button-blue" value="Sign in" @click="submit" />
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    import {dSource} from './vars.js';

    export default {
        name: 'Login',
        data() {
            return {
                dSource: dSource,

                email: {
                    value: null,
                    valid: true,
                    error: null
                },
                password: {
                    value: null,
                    valid: true,
                    error: null
                },

                loading: false
            }
        },
        methods: {

            isValid() {
                // validace email
                this.email.valid = true
                this.email.valid &= /^.{1,1000}@.{1,1000}\.[a-zA-Z0-9]{1,20}$/.test(this.email.value)
                if (!this.email.valid) {
                    this.email.error = "Incorrect e-mail address"
                }

                // validace heslo
                this.password.valid = true
                //this.password.valid &= this.password.length >= 1
                //if (!this.password.valid) {
                //    this.password.error = "Type in your password";
                //}

                return this.email.valid && this.password.valid
            },

            submit() {
                if (!this.isValid()) {
                    return false
                }
                var credentials = {email: this.email.value, password: this.password.value}

                this.$emit("loggedIn", credentials)
            },

            clear() {
                this.email.value = null
                this.email.error = null
                this.password.value = null
                this.password.error = null
            },

            generalError(udata) {
                this.email.valid = false
                this.password.valid = false

                this.email.error = "Wrong e-mail"
                this.password.error = "or password"

                this.email.value = udata["email"]
                this.password.value = udata["password"]
            },

            forgoten() {
                this.$emit("forgoten")
            }
        },
        computed: {
        }
    }
</script>

<style scoped>
    div.form-container {
    }

    td.td-label {
        color: #888;
        font-size: 13px;
        padding: 0px;
    }

    td.td-input {
        padding: 0px 0px 10px 0px;
    }

    span.forgoten-clicker {
        color: #555555;
        text-decoration: none;
        margin-left: 2px;
        cursor: pointer;
    }

    span.forgoten-clicker:hover {
        text-decoration: underline;
    }

    td.td-input > a:hover {
        text-decoration: underline;
    }


    td.td-input > input[type="text"],
    td.td-input > input[type="password"] {
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 5px 0px 5px 3px;
        font-size: 15px;
        width: 250px;

        height: 20px;
    }

    td.error > input[type="text"],
    td.error > input[type="password"]{
        border: 1px solid red;
    }

    td.td-error {
        color: red;
        font-size: 13px;
        text-align: right;
        padding: 0px;
    }

    td.td-submit {
        text-align: right;
        padding: 0px;
    }

    td.td-submit > input[type="button"] {
        border: 0px;
        font-size: 15px;
        padding: 5px 10px 5px 10px;
        margin: 0px 0px 0px 0px;
    }
</style>
