<template>
    <div>
        <div class="block-header" style="margin-bottom: 0px;">
            <span>Articles</span>
        </div>

        <div class="block-list-wrapper">            
            <ul>
                <li v-for="(item, index) in articles" :key="item.id">
                    <a class="link" v-bind:href=item.link target="_blank">
                        <div class="block-list-item-wrapper">

                            <div class="block-list-item-published" >
                                <span>{{item.date}}</span>
                            </div>
                            <div class="block-list-item-title" >
                                
                                <span v-if="item.isNew">
                                    <div class="tag-box" v-if="lang == 'en'">New</div>
                                    <div class="tag-box" v-else>Novinka</div>
                                </span>


                                <span>{{item.title}}</span>
                            </div>
                            <div class="block-list-item-excerpt" >
                                <span>{{item.excerpt}}</span>
                            </div> 
                        </div>
                        <hr class="block-list-separator" v-if="index<3">
                    </a>   
                </li>  
            </ul>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    
    export default {
        name: 'WidgetArticle',
        props: {
        },
        components: {
        },
        data() {
            return {
                articles: [],
                months: ['January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'],

                en_posts_url: "https://spreadcharts.com/wp-json/wp/v2/posts",
                cs_posts_url: "https://spreadcharts.com/cs/wp-json/wp/v2/posts",

                t: 0,

                lang: "en"
            }
        },
        methods: {
            loadPosts(lang) {
                var url = (lang == "en") ? this.en_posts_url : this.cs_posts_url
                axios.get(url).then(response => this.processPosts(response.data))
            },

            processPosts(payload) {
                this.t = new Date().getTime() / 1000

                this.articles = []

                for (var i = 0; i < 4; i++) {
                    var d = new Date(payload[i].modified_gmt)

                    // interpretace uvozovek 
                    let exp = payload[i].excerpt.rendered.replace(/&#8217;|&#39;/g, "'")
                    let tit = payload[i].title.rendered.replace(/&#8217;|&#39;/g, "'")

                    exp = exp.replace(/&#038;/g, "&")
                    tit = tit.replace(/&#038;/g, "&")

                    exp = exp.replace(/&#8211;/g, "–")
                    tit = tit.replace(/&#8211;/g, "–")

                    exp = exp.replace(/&#8220;/g, "“")
                    tit = tit.replace(/&#8220;/g, "“")

                    exp = exp.replace(/&#8221;/g, "”")
                    tit = tit.replace(/&#8221;/g, "”")

                    exp = exp.replace(/&hellip;|&#8230;/g, "... ")
                    tit = tit.replace(/&hellip;|&#8230;/g, "... ")

                    exp = exp.replace(/<p>|<\/p>/g, "")

                    this.articles.push({
                        link:    payload[i].link,
                        title:   tit,
                        excerpt: exp,
                        date:    "" + this.months[d.getMonth()]  + " " + d.getDate() + ", " + d.getFullYear(),
                        isNew: (this.t - d.getTime() / 1000) < 86400 * 10

                    })
                }
            },

            setLang(lang) {
                this.lang = lang
                this.loadPosts(lang)
            }
        },
        created() {
            this.loadPosts(this.lang)
        },
        watch: {
            lang() {
                this.loadPosts(this.lang)
            }
        }
    }
</script>

<style scoped>
    div.block-list-wrapper > ul {
        margin-top: -5px;
        margin-bottom: 10px;
    }

    div.block-list-wrapper div.block-list-item-title {
        color: rgba(55, 55, 55, 0.9);
    }

    div.block-list-wrapper div.block-list-item-excerpt,
    div.block-list-wrapper div.block-list-item-published {
        color: rgba(55, 55, 55, 0.6);
    }

    div.tag-box, div.tag-box:hover {
        display: inline-block;
        margin-right: 5px;
        border-radius: 3px;
        padding: 3px 6px 3px 6px;
        font-size: 13px;
        color: #000000;
        background-color: #f37e7c;
    }
</style>
