<template>
    <div>
        <div v-show="loaded">
            <!-- Main left nemu area -->
            <div id="column-left" v-bind:class="{'col-left-full': enlarged, 'col-left-small': !enlarged}" :style="{'min-height': '700px', 'height': windowHeight+'px'}">
                
                <div class="logo-box">
                    <img v-show="enlarged" class="logo-full" src="../assets/sc_logo_full.svg"> 
                    <img v-show="!enlarged" class="logo-small" src="../assets/logo_small.png"> 
                </div>
                
                <div class='app-menu-container'>
                    <ul class="app-menu-main">
                        <li v-show="item.enabled" class="app-menu-main-item" v-for="item in menu_items" v-bind:class="{'menu-active': item.target == targetActive}" :key="item.id" v-on:click="menuHandler(item.target)">
                            <div class="icon-wrapper">
                                <i v-bind:class="item.icon" ></i>
                            </div>
                            <span v-bind:class="{'text-out': enlarged, 'text-in': !enlarged}" v-html="item.name"></span>
                        </li>
                    </ul>
                </div>

                <div class="info-wrapper">
                    <a v-bind:href="tutorialHref" target="blank">
                        <div class="tutorial-wrapper" 
                            @click="showVideo = false"
                            :style="[!infoOut || enlarged ? {bottom: '220px'} : {bottom: '40px'}]"
                        >
                            <div :style="[enlarged ? {width: '170px'} : {width: '20px'}]">
                                <div class="icon-wrapper">
                                    <i class="icons8-help" style="font-size: 22px; margin-left: 2px;"></i>
                                </div>
                                <div v-show="enlarged" class="tutorial-text" v-html="'Tutorial'"></div>
                            </div>
                        </div>
                    </a>

                    <div v-show="infoOut" v-bind:class="{'info-out': infoOut, 'info-in': !infoOut}" class="info-content">
                        All information on this website is for educational purposes only and is not intended to provide financial advice. There is a risk of loss in futures trading.
                        <br><br>

                        <a v-show="infoOut" href="https://spreadcharts.com/terms-of-service/" target="_blank">
                            <div>Terms&nbsp;of&nbsp;service</div>
                        </a>

                        <a v-show="infoOut" href="https://spreadcharts.com/privacy-policy/" target="_blank">
                            <div>Privacy&nbsp;policy</div>
                        </a>

                        <a v-show="infoOut" style="cursor: pointer" @click="$refs['contactForm'].init(); showContactModal = true">
                            <div>Contact us</div>
                        </a>

                        <a v-show="infoOut" href="https://spreadcharts.com" target="_blank">
                            <div>©&nbsp;{{new Date().getFullYear()}}&nbsp;SpreadCharts.com</div>
                        </a>
                    </div>
                    <div @click="infoOut = !infoOut || enlarged" style="margin-bottom: 50px">
                        <i 
                            class="icons8-info-filled" 
                            :style="[enlarged ? {opacity: '0.0'} : {opacity: '1.0'}, {'font-size': '21px'}]" 
                        />
                    </div>
                </div>
            </div>

            <!-- Graph & others area -->
            <div id="column-right" v-bind:class="{'col-right-small': enlarged, 'col-right-full': !enlarged}" :style="{'height': windowHeight+'px'}">
                <div class="top-bar">
                    <span class="state-holder" v-html="nameActive"></span>

                    <!-- Pro piripad prihlaseneho uzivatele -->
                    <div v-if="loggedIn" class="user-wrapper">
                        {{user.email}}

                        <!--Sprava subscription -->
                        <span class="tooltip-wrapper">
                            <span class="tooltiptext tooltiptext-left">Your subscription</span>
                            <i 
                                @click="menuHandler('subscriptions')" 
                                class="icons8-settings-filled"
                                :style="[targetActive == 'subscriptions' ? {color: '#1976D2'} : {}]"
                            ></i>
                        </span>

                        <!-- Ulozeni UX -->
                        <span class="tooltip-wrapper">
                            <span class="tooltiptext tooltiptext-left">Save app state</span>
                            <i @click="saveAppUX(getAppUX())" class="icons8-upload-to-cloud-2"></i>
                        </span>

                        <span class="tooltip-wrapper">
                            <span class="tooltiptext tooltiptext-left">Sign out</span>
                            <i @click="logOut" class="icons8-export"></i>
                        </span>
                    </div>

                    <!-- Pro pripad neprihlaseneho / neregistrovaneho uzivatele -->
                    <div v-else class="user-wrapper">
                        <div class="user-login-warn">
                            <span class="optional">Registered user? </span>
                            <span class="user-clicker" @click="showLogin = true">Log in</span> | 
                            <span class="optional">New to SpreadCharts? </span> 
                            <span class="user-clicker"><a href="https://spreadcharts.com/start-for-free/">Create an account</a></span>
                        </div>
                    </div>

                </div>

                <!-- hlavni obsahova oblast -->
                <div class="category-container" id="category-container">
                    <div class="content-container" ref="contentContainer"></div>
                </div>


            </div>

            <!-- Tutorial Video -->
            <Video v-if="lang == 'en'" :show="showVideo" :name="'Tutorial video'" :videoId="'375693692'" :bookmarks="tutorialBookmarks.en" @close="showVideo = false" style="z-index: 200000"/>
            <Video v-if="lang == 'cz'" :show="showVideo" :name="'Tutorial video'" :videoId="'375693692'" :bookmarks="tutorialBookmarks.cz" @close="showVideo = false" style="z-index: 200000"/>

            <!-- Hlaska ulozeni stavu app -->
            <modal3 :show="showStateModal" @close="closeStateModal">
                <div slot="header">
                    Save app state
                </div>
                <div slot="body">
                    <div style="color: #555; font-size: 20px; width: 500px; padding: 30px 0px 30px 0px; text-align: center;">
                    The state of the app has been saved
                    </div>
                </div>
            </modal3>

            <!-- okno prihlaseni (odhlaseni) -->
            <modal3 :show="showLogin" @close="showLogin = false">
                <div slot=header>
                    Log in
                </div>
                <div slot="body">
                    <div v-show="!loading_login" class="login-content">
                        <Login
                            @loggedIn="logIn" 
                            @forgoten="function() {
                                showLogin = false;
                                $refs['ForgotenPass'].init()
                                showForgotenPass = true}" 
                            ref="loginForm"
                        /> 
                    </div>
                    
                    <div v-show="loading_login" class="login-content">
                        <loader class="loader"/>
                    </div>
                </div>
            </modal3>

            <!-- zapomenute heslo -->
            <modal3 :show="showForgotenPass" @close="showForgotenPass = false">
                <div slot=header>
                    Forgoten password?
                </div>
                <div slot=body>
                    <ForgotenPass 
                        @toLogin="showForgotenPass = false; showLogin = true;" 
                        ref="ForgotenPass"
                    />
                </div>
            </modal3>

            <!-- Kontaktni formular -->
            <modal3 :show="showContactModal" @close="showContactModal = false">
                <div slot="header">Contact us</div>
                <div slot="body">
                    <ContactForm :user="user" ref="contactForm" @close="showContactModal = false" />
                </div>

            </modal3>

            <!-- Permalink -->
            <modal3 :show="showPermalink" @close="showPermalink = false">
                <div slot="header">Link to currently active list</div>
                <div slot="body">
                    <div ref="plink_content" class="plink_content">
                    </div>
                </div>
            </modal3>

            <!-- Watchlist -->
            <modal3 :show="showWatchlistModal" @close="showWatchlistModal = false">
                <div slot="header">Save / Edit the tab in watchlist</div>
                <div slot="body">
                    <div  style="min-height: 120px"ref="watchlist2Adder_content" class="watchlist2Adder_content">
                    </div>
                </div>
            </modal3>



        </div>
        <div v-show="!loaded">
            <div class="load-wrapper">
                <div>
                    <loader/>
                </div>
                <div style="font-size: 20px; color: #ddd;">
                Loading your SpreadCharts user environment
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    
    import Watchlist2Adder from './Watchlist2Adder.vue'
    var Watchlist2AdderClass = Vue.extend(Watchlist2Adder)

    import Dashboard from './dashboard.vue'
    var DashboardClass = Vue.extend(Dashboard)

    import Lists from './lists.vue'
    var ChartsClass = Vue.extend(Lists)

    import Signals from './Signals.vue';
    var SignalsClass = Vue.extend(Signals)

    import Research from "./Research.vue";
    var ResearchClass = Vue.extend(Research)
    
    import Subscriptions from "./Subscriptions.vue";
    var SubscriptionsClass = Vue.extend(Subscriptions)

    // obecne modalni okno
    import modal3 from './modal3.vue';

    // import vimeo 
    import { vueVimeoPlayer } from 'vue-vimeo-player'
    import Player from '@vimeo/player'

    import {dSource} from './vars.js';

    // funkce UX
    import loadMyUX from "./ux.js";
    import loadLinkedUX from "./ux.js";

    import saveUX from "./ux.js";

    // loader
    import loader from './loader.vue';

    // login etc.
    import Login from './login.vue';
    import ForgotenPass from './forgotenPass.vue';

    // contact form
    import ContactForm from './contactForm.vue';

    import Video from './Video.vue';

    import axios from 'axios';

    export default {
        name: 'Home',
        components: {
            modal3, "vimeo-player": vueVimeoPlayer, loader, Login, ForgotenPass, ContactForm, Video
        },
        mixins: [loadMyUX, loadLinkedUX, saveUX],

        properties: {},

        data() {
            return {
                /**
                  * Dulezite promenne
                  */
                user: {id: -1},
                lang: "en",
                enlarged: true,
                infoOut: true,
                windowWidth: 0, // kvuli reakcim na resize
                windowHeight: 0, // kvuli reakciim na resize

                /**
                  * Polozky hlavniho menu
                  */
                menu_items: [
                    {"name": "Menu", "icon":"icons8-news-feed-filled", "on":"enlarge", "target": "menu-enlarger", "enabled":true},
                    {"name": "Dashboard", "icon": "icons8-view-quilt-filled", "target": "dashboard", "enabled":true},
                    {"name": "Charts", "icon": "icons8-candlestick-chart-filled", "target": "charts", "enabled":true},
                    {"name": "Backtester", "icon": "", "target": "menu-backtester", "enabled":false},
                    {"name": "My&nbsp;trades(beta)", "icon": "icons8-increase", "target": "mytrades", "enabled":false},
                    {"name": "Trading&nbsp;signals", "icon":"icons8-sine-filled", "target": "signals", "enabled":true},
                    {"name": "Education", "icon": "", "target": "menu-education", "enabled":false},
                    {"name": "Tutorial", "icon": "icons8-help", "target": "menu-tutorial", "enabled":false},
                    {"name": "Research", "icon":"icons8-mind-map-filled", "target": "research", "enabled":true}
                ],
                //targetActive: "menu-dashboard", // aktualne aktivni polozka
                targetActive: "charts", // aktualne aktivni polozka

                /**
                  * Stavy modalnich oken
                  */
                showLogin: false,
                showRegistration: false,
                showForgotenPass: false,
                showStateModal: false,
                showContactModal: false,
                showVideo: false,
                showPermalink: false,
                
                showWatchlistModal: false,

                /**
                  * Stavy loadovani
                  */
                loaded: false,
                loading_login: false,

                /**
                  * Definice zalozek tutorialu
                  */
                tutorialBookmarks: {
                    en: [{
                        name: null,
                        bookmarks: [
                            {name: "Layout and main navigation", seek: "00:10"},
                        ]
                    },{
                        name: "Charts",
                        bookmarks: [
                            {name: "Specifying the input", seek: "00:53"},
                            {name: "Manipulating charts", seek: "04:07"},
                            {name: "Exploring different chart types", seek: "05:32"},
                            {name: "Comparing various types of data", seek: "07:06"},
                            {name: "Organizing charts into tabs", seek: "08:48"},
                            {name: "Complex expressions (e.g. continuous contango)", seek: "09:39"},
                            {name: "Roll methodology for continuous contacts", seek: "11:37"},
                            {name: "Drawing, P/L calculator, exporting chart", seek: "14:25"},
                        ]
                    },{
                        name: "Watchlist",
                        bookmarks: [
                            {name: "Saving the chart to watchlist", seek: "15:15"},
                            {name: "Editing/deleting records in the watchlist", seek: "16:11"},
                            {name: "Saving the state of the app", seek: "17:08"},
                            {name: "Managign the tabs", seek: "17:35"},
                        ]
                    }, {
                        name: "Premium subscription",
                        bookmarks: [
                            {name: "Where to check your subscription", seek: "18:57"},
                            {name: "What's included in the premium subscription?'", seek: "19:51"},
                            {name: "Buying the premium plan", seek: "21:05"},
                            {name: "Managing your existing subscription", seek: "24:18"},
                        ]
                    },{
                        name: "Trading signals",
                        bookmarks: [
                            {name: "Introduction to the Trading signals", seek: "27:26"},
                            {name: "Thumbnail na description of a single signal", seek: "29:35"},
                            {name: "Expanding the thumbnail to a full-scale chart", seek: "30:50"},
                            {name: "How are the signals calculated & what's the input data", seek: "33:01"},
                            {name: "Explaining the signal strength (purple curve)", seek: "35:36"},
                            {name: "Caveats", seek: "37:36"},
                            {name: "Filtering signals", seek: "39:50"},
                            {name: "When are the new signals generated", seek: "40:50"},
                        ]
                    },{
                        name: "Research",
                        bookmarks: [
                            {name: "Introduction to the premium research", seek: "43:24"},
                            {name: "What are the research videos about", seek: "45:26"}
                        ]
                    }],
                cz: [{
                        name: null,
                        bookmarks: [
                            {name: "Layout and main navigation", seek: "00:10"},
                        ]
                    },{
                        name: "Charts",
                        bookmarks: [
                            {name: "Specifying the input", seek: "00:53"},
                            {name: "Manipulating charts", seek: "04:07"},
                            {name: "Exploring different chart types", seek: "05:32"},
                            {name: "Comparing various types of data", seek: "07:06"},
                            {name: "Organizing charts into tabs", seek: "08:48"},
                            {name: "Complex expressions (e.g. continuous contango)", seek: "09:39"},
                            {name: "Roll methodology for continuous contacts", seek: "11:37"},
                            {name: "Drawing, P/L calculator, exporting chart", seek: "14:25"},
                        ]
                    },{
                        name: "Watchlist",
                        bookmarks: [
                            {name: "Saving the chart to watchlist", seek: "15:15"},
                            {name: "Editing/deleting records in the watchlist", seek: "16:11"},
                            {name: "Saving the state of the app", seek: "17:08"},
                            {name: "Managign the tabs", seek: "17:35"},
                        ]
                    }, {
                        name: "Premium subscription",
                        bookmarks: [
                            {name: "Where to check your subscription", seek: "18:57"},
                            {name: "What's included in the premium subscription?'", seek: "19:51"},
                            {name: "Buying the premium plan", seek: "21:05"},
                            {name: "Managing your existing subscription", seek: "24:18"},
                        ]
                    },{
                        name: "Trading signals",
                        bookmarks: [
                            {name: "Introduction to the Trading signals", seek: "27:26"},
                            {name: "Thumbnail na description of a single signal", seek: "29:35"},
                            {name: "Expanding the thumbnail to a full-scale chart", seek: "30:50"},
                            {name: "How are the signals calculated & what's the input data", seek: "33:01"},
                            {name: "Explaining the signal strength (purple curve)", seek: "35:36"},
                            {name: "Caveats", seek: "37:36"},
                            {name: "Filtering signals", seek: "39:50"},
                            {name: "When are the new signals generated", seek: "40:50"},
                        ]
                    },{
                        name: "Research",
                        bookmarks: [
                            {name: "Introduction to the premium research", seek: "43:24"},
                            {name: "What are the research videos about", seek: "45:26"}
                        ]
                    }
                ]},

                /**
                  * Datove kontejnery
                  */
                whisperData: [],
                watchlistData: [],
                categoryData: [],
                widgetSignalsData: [],
                researchData: [],
                signalsData: [],
                subscriptionData: []

            }
        },
        methods: {
            /**
              * Ovladani nekterych modalnich oken
              */
            closeStateModal() {
                var self = this
                setTimeout(function() {
                    self.showStateModal = false
                }, 2000)
            },

            /**
              * 'Sbernice' pro zprostredkovani komunikace mezi komonentami
              * napr kvuli permalinkum apod.
              * - c = cilova komonenta
              * - f = funkce v cilove komponente
              * - data = predavana data (robj, apod.)
              */
            busHandler(c, f, data) {
                if (c == "charts") {
                    this.targetActive = "menu-charts"
                }

                this.$refs[c][0][f](data)
            },

            /**
              * Ovladani hlavniho leveho menu
              */
            menuHandler(target, reload) {
                // rozbalit / sbalit menu
                if (target == "menu-enlarger") {
                    this.enlarged = !this.enlarged;
                    this.infoOut = this.enlarged;
                    return;
                }

                this.showVideo = false
                if (target == "menu-tutorial") {
                    this.showVideo = true
                }

                // kvuli moznosti stareho pojmenovani
                if (target.startsWith("menu-")) {
                    target = target.replace("menu-", "")
                }

                this.setContent(target, reload)

                // posisek aktivniho
                this.targetActive = target;

                /**
                  * Prvotni vykresleni ulozenych grafu
                  */
                if (target == "charts") {
                    var self = this
                    var n = 0
                    var interval = setInterval(function() {
                        if (self.charts.listActive !== undefined) {
                            if (!self.charts.listActive.charts.isDone()) {
                                self.charts.listActive.charts.startAllCharts()
                            }
                            clearInterval(interval)
                            return
                        }
                        n += 1
                    }, 25)
                }
            },

            /**
              * Vytvoreni hlavnich komponent
              * Dashboard, Lists atd.
              */
            createContent() {
                // dashboard
                this.dashboard = this.createDashboard()

                // charts (komponenta lists)
                this.charts = this.createCharts()

                // signals
                this.signals = this.createSignals()

                // research
                this.research = this.createResearch()

                // subscriptions
                this.subscriptions = this.createSubscriptions()
            },

            /**
              * Vytvoreni contentu "Dashboard"
              */
            createDashboard() {
                var tmp = new DashboardClass({
                    propsData: {
                        user: this.user
                    }
                })
                var self = this
                tmp.$on("uxAdded", this.onWatchlistClicked)
                tmp.$on("categoryChanged", function() {self.loadData(["watchlistData", "categoryData"])})
                tmp.$on("watchlistDeleted", function() {self.loadData(["watchlistData"])})
                tmp.$on("videoClicked", function(video_id) {
                    self.menuHandler("research")
                    self.research.startVideoById(video_id)
                })
                tmp.$on("signalClicked", function(signal_id) {
                    self.menuHandler("signals")
                    self.signals.openSignal(signal_id)
                })
                tmp.$mount()
                return tmp
            },

            /**
              * Vytvoreni contentu "Charts"
              */
            createCharts() {
                var tmp = new ChartsClass({
                    propsData: {
                        whisperData: this.whisperData,
                        user: this.user,
                        loaded: this.loaded
                    }
                })
                var self = this
                tmp.$on("onPermalink", this.onPermalink)
                tmp.$on("onWatchlistAdded", this.onWatchlistAdded)
                tmp.$on("toSubscription", function() {self.menuHandler('subscriptions')})
                tmp.$mount()
                return tmp
            },

            /**
              * Vytvoreni contentu "Signals"
              */
            createSignals() {
                var tmp = new SignalsClass({
                    propsData: {
                        user: this.user
                    }
                })
                var self = this
                tmp.$on("toCharts", this.signalToCharts)
                tmp.$on("toSubscription", function() {self.menuHandler('subscriptions')})
                tmp.$mount()
                return tmp
            },

            /**
              * Vytvoreni contentu "Research"
              */
            createResearch() {
                var tmp = new ResearchClass({
                    propsData: {
                        user: this.user
                    }
                })
                var self = this
                tmp.$on("langChanged", this.onLangChanged)
                tmp.$on("toSubscription", function() {self.menuHandler('subscriptions')})
                tmp.$mount()
                return tmp
            },

            /**
              * Vytvoreni contentu "Subscriptions"
              */
            createSubscriptions() {
                var tmp = new SubscriptionsClass({
                    propsData: {
                        user: this.user
                    } 
                })
                tmp.$on("toSignals", function() {self.menuHandler('signals')})
                tmp.$on("toResearch", function() {self.menuHandler('research')})
                tmp.$on("subChanged", this.subChanged)
                tmp.$mount()
                return tmp
            },

            /**
              * Nastaveni elementu do obsahove oblasti (dashboard, charts atd.)
              */
            setContent(target, reload) {
                // kvuli moznosti stareho pojmenovani
                if (target.startsWith("menu-")) {
                    target = target.replace("menu-", "")
                }
                
                var targetActive = this.targetActive
                if (targetActive.startsWith("menu-")) {
                    targetActive = targetActive.replace("menu-", "")
                }

                var containerElement = document.getElementById("category-container")

                // ulozit scroll vypinaneho
                if (this[targetActive].saveScroll !== undefined) {
                    this[targetActive].saveScroll(containerElement.scrollTop)
                }

                // odebrat pokud je pripojeny nejaky element
                if (this.$refs.contentContainer.firstChild !==  null) {
                    this.$refs.contentContainer.removeChild(this.$refs.contentContainer.firstChild)
                }

                // pridat element ciloveho contentu (prislusne komponenty)
                this.$refs.contentContainer.appendChild(this[target].$el)

                // ulozit scroll vypinaneho
                if (this[target].getScroll !== undefined) {
                    containerElement.scrollTop = this[target].getScroll()
                } else {
                    containerElement.scrollTop = 0
                }

                // reaload watchlistu
                if (target == "dashboard") {
                    try {
                        if (this.dashboard.watchlist.changed) {
                            this.loadData(["watchlistData"])
                            this.dashboard.watchlist.changed = false
                        }
                    } catch (e) {
                    }
                }

                // reaload signalu
                if (target == "signals") {
                    try {
                        this.signals.reload()
                    } catch (e) {
                        console.log(e)
                    }
                }

                // pri odchodu ze signalu zrusit jakekoliv oznaceni (blikani)
                if (targetActive == "signals") {
                    this.signals.openSignal(-1)
                }
            },

            /**
              * Prace s cookies
              */
            // nastavit cookie
            setCookie(name, value) {
                var expires = "";
                var date = new Date();
                date.setTime(date.getTime() + (10000*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
                var domain = window.location.hostname.replace(/^[a-z0-9]{1,}\./, ".") // na "sdilenou" domenu
                document.cookie = name + "=" + (value || "")  + expires + ";domain="+domain+";path=/";
            },
            // ziskat hodnotu cookie podle jmena
            getCookie(name) {
                var parts = document.cookie.split(";")
                for (var i in parts) {
                    var tmp = parts[i].split("=")
                    tmp[0] = tmp[0].replace(" ", "")
                    if (tmp[0] == name) {return tmp[1]}
                }
                return undefined
            },
            // rekonstrukce uzivatele pouze z cookies (kvuli rychlosti)
            getUserFromCookies() {
                var user = {}
                user.email = this.getCookie("sc_user_email")
                user.email = (user.email != undefined && user.email.length > 0) ? user.email : undefined 

                user.id = parseInt(this.getCookie("sc_user_id"))
                user.id = (user.id != undefined && user.id > 0) ? user.id : -1 
                
                user.token = this.getCookie("authToken")
                user.token = (user.token != undefined && user.token.length > 0) ? user.token : undefined



                return user
            },

            /**
              * Prihlaseni uzivatele
              */
            logIn(user) {
                var robj  = {
                    user: {id: -1}, 
                    rkeys: ["user"],
                    chain: [
                        {action: "Login", i:0, dkey:"user", request:{method:"login", user:user}}
                    ]
                }

                this.loading_login = true

                var self = this
                axios.post(this.dSource, {robj: robj}).then(payload => function(data) {
                    self.loaded = true
                    self.user = data
                    self.enlarged = !self.loggedIn 
                    self.infoOut = !self.loggedIn     

                    if (data.id > 0) {
                        self.setCookie("authToken", data.token)
                        self.setCookie("sc_user_id", data.id)
                        self.setCookie("sc_user_email", data.email)

                        location.reload()
                        return
                        /*
                        self.loading_login = false
                        self.showLogin = false

                        // vymazat obsah loginFormu
                        self.$refs.loginForm.clear()

                        //self.loadUX()
                        return*/
                    }

                    if (self.$refs["loginForm"] !== undefined) {
                        self.$refs["loginForm"].generalError(data)
                        self.loading_login = false
                    }
                }(payload.data.data.user))
            },

            /**
              * Kontrola loginu podle tokenu ("login na prazdn")
              */
            clogIn(token) {
                var robj  = {
                    user: {id: -1}, 
                    rkeys: ["user"],
                    chain: [
                        {action: "Login", i:0, dkey:"user", request:{method:"login", user:{token: token}}}
                    ]
                }

                var self = this
                axios.post(this.dSource, {robj: robj}).then(payload => function(user) {
                    // token neexistuje -> odhlasit
                    if (user.id < 0) {
                        self.logOut()
                    }

                    // pro jistotu oprava ostatnich hodnot v cookies a user
                    self.setCookie("sc_user_id", user.id)
                    self.user.id = user.id
                    self.setCookie("sc_user_email", user.email)
                    self.user.email = user.email
                }(payload.data.data.user))
            },

            /**
              * Odhlaseni uzivatele
              */
            logOut() {
                var robj = {
                    user: {id: -1},
                    chain: [
                        {action: "Login", i:0, dkey:"result", request:{user: this.user, method:"logout"}}
                    ]
                }


                var self = this
                axios.post(this.dSource, {robj: robj}).then(payload => function(user) {
                    self.setCookie("authToken", undefined)
                    self.setCookie("sc_user_id", undefined)
                    self.setCookie("sc_user_email", undefined)
                    location.reload()
                    //self.user = user
                    //self.enlarged = true
                    //self.infoOut = true
                }(payload.data.data))
            },

            /**
              *
              */ 
            getAppUX() {
                return  {
                    // stav app
                    app:  {
                        //enlarged: false,
                        enlarged: this.enlarged,
                        infoOut: this.infoOut,
                        targetActive: this.targetActive,
                        research: {
                            language: this.research.getLanguage() 
                        }
                    },
                    // stav listu
                    lists: this.charts.getUX()
                }
            },

            /**
              * Ulozeni UX
              */ 
            saveAppUX(ux) {
                try {
                    var self = this
                    this.saveUX(this.user, ux, "my_ux", function(payload) {
                        self.showStateModal = true
                        self.closeStateModal()
                    })
                } catch(err) {
                    console.log(err)
                }
            },

            /**
              * Nastaveni ux podle nactenych dat
              */
            setAppUX(ux) {

                // nema ulozene UX
                if (ux === undefined || ux === null || Object.keys(ux).length == 0) {return}

                // nema app
                if (ux.app === undefined) {return}

                // celkove nastaveni app
                var appUX = ux.app;

                var keys = Object.keys(appUX)
                if (keys.includes("enlarged")) {this.enlarged = appUX.enlarged}
                if (keys.includes("infoOut")) {this.infoOut = appUX.infoOut}
                
                // force dashbordu jako defaultniho zobrazeni
                // vyjimka pro permalink
                if (!this.has_plink) {
                    //this.menuHandler("dashboard") 
                } else {
                    if (keys.includes("targetActive")) {this.menuHandler(appUX.targetActive)}
                }

                // research (kvuli jazyku)
                if (ux.app.research != undefined && ux.app.research.language != undefined) {
                    this.research.setLanguage(ux.app.research.language)
                    this.research.$forceUpdate()
                }

                // listy
                if (ux.lists.length > 0) {
                    var self = this
                    var count = 0
                    var interval = setInterval(function() {
                        if (self.charts !== undefined) {
                            self.charts.setUX(ux.lists)
                            clearInterval(interval)
                            return
                        }

                        count += 1
                        if (count > 100) {
                            clearInterval(interval)
                        }
                    }, 10)
                }

                this.$forceUpdate()
            },

            // ziskani parametru z URL
            // odnekud vykradena funkce
            getURLParameter(sParam) {
                var sPageURL = window.location.search.substring(1);
                var sURLVariables = sPageURL.split('&');

                for (var i = 0; i < sURLVariables.length; i++) {
                    var sParameterName = sURLVariables[i].split('=');
                    
                    if (sParameterName[0] == sParam) {
                        return sParameterName[1];
                    }
                }
            },

            /**
              * Nacteni celkoveho ux podle uzivatele
              */
            loadUX() {
                if (!this.loggedIn) {
                    return
                }

                var self = this
                if (this.has_plink) {
                    var myUX
                    var linkedUX

                    var link = this.getURLParameter("plink")

                    this.loadMyUX(this.user, function(payload) {myUX = payload.data})
                    this.loadLinkedUX(this.user, link, function(payload) {linkedUX = payload.data})

                    var interval = setInterval(function() {
                        if (myUX != undefined && linkedUX != undefined) {
                            clearInterval(interval)
                            self.setAppUX(self.appendUX(myUX, linkedUX))
                            window.history.replaceState({}, document.title, "/");
                        } 
                    }, 25)
                } else {
                    this.loadMyUX(this.user, function(payload) {
                        try {
                            self.setAppUX(payload.data)        
                        } catch (err) {
                            console.log(err)
                            self.charts.lists = []
                            self.charts.addList()
                            
                        }
                    })
                }
            },

            /**
              * Slozeni dvou ux za sebe
              */
            appendUX(a, b) {
                // nastaveni aktivniho
                // posledni list
                for (var i in a.lists) {
                    a.lists[i].active = false
                }
                b.lists[0].active = true

                a.lists.push(b.lists[0])

                return a
            },

            onWatchlistClicked(linkedUX) {
                var self = this
                setTimeout(function() {
                    self.charts.addUX(linkedUX.lists)
                    self.menuHandler("charts")
                }, 50)
            },

            // opetovne nacteni watchlistu
            watchlistReload() {
                this.loadData(["watchlistData"])
            },

            /**
              * Reakce na emit watchlistu
              * ulozeni
              */
            onWatchlistAdded(lists) {
                // ukladane ux
                var ux = {
                    app:  {
                        enlarged: this.enlarged,
                        infoOut: this.infoOut,
                        targetActive: this.targetActive,
                        research: {
                            language: this.research.getLanguage() 
                        }
                    },
                    lists: lists
                }

                // zobrazit modalni okno
                this.showWatchlistModal = true

                var tmp = new Watchlist2AdderClass()
                tmp.$mount()

                tmp.setUX(ux)
                tmp.setY(this.dashboard.watchlist.getLastY()+1000)
                tmp.setName(lists[0].name)

                if (lists[0].watchlist_data !== undefined && lists[0].watchlist_data !== null) {
                    tmp.setTags(this.dashboard.getTags(), lists[0].watchlist_data.tags)
                    //tmp.setName(lists[0].watchlist_data.name)
                    tmp.setId(lists[0].watchlist_data.id)
                    tmp.setLink(lists[0].watchlist_data.link)
                } else {
                    tmp.setTags(this.dashboard.getTags(), [])
                }

                tmp.$on("edited", this.watchlistEdited)
                tmp.$on("added", this.watchlistAdded)
                tmp.$on("toSubscriptions", function() {
                    self.showWatchlistModal = false
                    self.menuHandler('subscriptions')

                })

                tmp.state = this.user.sub || this.dashboard.watchlist.countRows()+this.dashboard.watchlist.offset < 10 ? 0 : 1

                // pripojit element
                this.$refs.watchlist2Adder_content.appendChild(tmp.$el)

                var self = this
                var interval = setInterval(function() {
                    // jeste neni hotove a okno stale otevrene
                    if (!tmp.done && self.showWatchlistModal) {return}

                    // zrusit docasnout komponentu
                    tmp.$destroy()
                    tmp.$el.parentNode.removeChild(tmp.$el)
                    tmp = undefined

                    // zavrit okno a reload watchlistu
                    self.showWatchlistModal = false
                    clearInterval(interval)
                }, 100)
            },
            watchlistAdded(data) {
                if (this.user.sub || this.dashboard.watchlist.countRows()+this.dashboard.watchlist.offset < 10) {
                    this.dashboard.watchlist.chartsAdded(data)
                    this.dashboard.watchlist.changed = true
                    this.dashboard.watchlist.offset += 1
                }
            },
            watchlistEdited(data) {
                //if (this.user.sub || this.dashboard.watchlist.countRows() <= 10) {
                    this.dashboard.watchlist.chartsEdited(data)
                    this.dashboard.watchlist.changed = true
                //}
            },

            /**
              * Reakce na emit permalinku
              * ulozeni a zobrazeni
              */
            onPermalink(lists) {
                var ux = {
                    app:  {
                        //enlarged: false,
                        enlarged: this.enlarged,
                        infoOut: this.infoOut,
                        targetActive: this.targetActive,
                        research: {
                            language: this.research.getLanguage() 
                        }
                    },
                    lists: lists
                }

                var self = this
                this.saveUX(this.user, ux, "permalink", function(payload) {
                    self.$refs.plink_content.innerHTML = window.location.href + "?plink=" + payload.data.link
                    self.showPermalink = true
                })
            },

            /**
              * Prechod ze signals do charts (zobrazeni grafu)
              */
            signalToCharts(signal) {
                // prepnout na charts
                this.menuHandler("menu-charts")

                // predat na charts
                this.charts.fromSignals(signal)
            },

            /**
              * Práce s nastavením jazyka
              */
            onLangChanged(lang) {
                this.lang = lang
                this.dashboard.setLang(lang)
            },

            /**
              * Reakce na velikost okna a souvisejici
              */
            setAppDimensions(e) {
                this.windowHeight = document.documentElement.clientHeight
                this.windowWidth = document.documentElement.clientWidth
            },

            loadData(chainAdds) {
                chainAdds = (chainAdds === undefined) ? ["all"] : chainAdds
                var chain = []
                var rkeys = []

                // chain pro watchlist
                if (chainAdds.includes("all") || chainAdds.includes("watchlistData")) {
                    chain.push({action: "watchlist2", i:0, dkey:"watchlistData", request:{method: "load", user:this.user}})
                    rkeys.push("watchlistData")
                }

                // widgetSignals chain
                if (chainAdds.includes("all") || chainAdds.includes("widgetSignalsData")) {
                    chain.push({action: "signal", i:2, dkey:"widgetSignalsData", request: {method: "getSignalsWidget", user:this.user}})
                    rkeys.push("widgetSignalsData")
                }

                // research chain
                if (chainAdds.includes("all") || chainAdds.includes("researchData")) {
                    chain.push({action: "research", i:0, dkey:"researchData", request: {method: "getVData", user:this.user}})
                    rkeys.push("researchData")
                }

                // signals chain
                if (chainAdds.includes("all") || chainAdds.includes("signalsData")) {
                    chain.push({action: "signal", i:0, dkey:"signalsData", request: {method: "getSignals", user:this.user}})
                    rkeys.push("signalsData")
                }

                // whisperData chain
                if (chainAdds.includes("all") || chainAdds.includes("whisperData")) {
                    chain.push({action: "Whisper2", i:3, dkey:"whisperData", request: {}})
                    rkeys.push("whisperData")
                }

                // ux data chain
                if (chainAdds.includes("all") || chainAdds.includes("myUXData")) {
                    chain.push({action: "ux", i:0, dkey:"myUXData", request:{method: "loadUX", user:this.user, type:"my_ux"}})
                    rkeys.push("myUXData")
                }

                // linked ux data chain a souvisejici operace
                if (this.has_plink) {
                    var link = this.getURLParameter("plink") 
                    chain.push({action: "ux", i:0, dkey:"linkedUXData", request:{method: "loadUX", user:this.user, type:"", link:link}})
                    rkeys.push("linkedUXData")
                    window.history.replaceState({}, document.title, "/");
                }

                // subscription data
                if (chainAdds.includes("all") || chainAdds.includes("subscriptionData")) {
                    chain.push({action: "Subscriptions", i:0, dkey:"subscriptionData", request:{user: this.user, method:"checkSubscription"}})
                    rkeys.push("subscriptionData")
                }

                var self = this
                var robj = {user: this.user, rkeys: rkeys, chain: chain}
                axios.post(this.dSource, {
                    robj: robj
                })
                .then(payload => this.loadDataCallback(payload.data.data))
                //.catch(error => {this.logOut()})
                .catch(error => function(error) {
                    self.clogIn(self.user.token)
                }(error))
            },

            loadDataCallback(data) {
                if (data.watchlistData != undefined) {
                    this.dashboard.watchlist.setData(data.watchlistData)
                }

                if (data.widgetSignalsData != undefined) {
                    this.dashboard.setSignalsData(data.widgetSignalsData)
                }

                if (data.whisperData != undefined) {
                    this.charts.setWhisperData(data.whisperData)
                }

                if (data.researchData != undefined) {
                    this.research.setData(data.researchData)
                    this.dashboard.setResearchData(data.researchData)
                }

                if (data.signalsData != undefined) {
                    this.signals.setSignals(data.signalsData)
                }

                if (data.myUXData != undefined && data.linkedUXData != undefined) {
                    this.setAppUX(this.appendUX(data.myUXData, data.linkedUXData))
                }

                if (data.myUXData != undefined && data.linkedUXData === undefined) {
                    this.setAppUX(data.myUXData)
                }

                if (data.subscriptionData != undefined) {
                    this.subscriptions.setData(data.subscriptionData)
                }

                this.checkSubscription()
                this.loaded = true
            },

            reloadData() {
                this.loadData(["researchData", "signalsData", "widgetSignalsData", "subscriptionData"])
            },

            subChanged() {
                this.loadData(["widgetSignalsData", "researchData", "signalsData"])
            },

            checkSubscription() {
                this.user.sub = this.subscriptions.isSub()
            }
        }, 
        created() {
            // nejdriv zkusit dostat uzivatele z cookies
            this.user = this.getUserFromCookies()

            // pokud je treba prihlasit a je to mozne tak prihlasit
            if (this.user.token != undefined && !this.loggedIn) {
                this.logIn({token: this.user.token})
            }

            // nastaveni leveho menu podle prihlaseni
            this.enlarged = !this.loggedIn 
            this.infoOut = !this.loggedIn
        },
        mounted() {
            // komponenty obsahu jednotlivych bloku
            this.createContent()

            // reakce na resize (otoceni)
            this.setAppDimensions({})
            window.addEventListener('resize', this.setAppDimensions)

            // potencialni autootevreni loginu
            this.showLogin = window.location.pathname == "/login"
            if (this.showLogin) {
                window.history.replaceState({}, document.title, "/");
            }
            
            window.addEventListener("contextmenu", function(e) { e.preventDefault();})


            if (this.loggedIn) {
                this.loadData()
                this.loaded = true
            } else {
                this.loaded = true
            }

            // defaultne je aktivana dashboard
            this.menuHandler("dashboard")
            //this.menuHandler("charts")
            
            // kontrola validity loginu podle tokenu
            if (this.loggedIn) {
                this.clogIn(this.user.token)
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.setAppDimensions)
        },
        computed: {
            /** Zobrazeny nazev aktivni karty **/
            nameActive() {
                for (var i in this.menu_items) {
                    if (this.menu_items[i]["target"] == this.targetActive) {
                        return this.menu_items[i]["name"]
                    }
                }
                return "Charts" // defaultne
            }, 

            has_plink() {
                return window.location.href.indexOf("plink=") > 0
            },

            // indikace prihlaseneho uzivatele
            loggedIn() {
                return this.user.id > 0
            },

            tutorialHref() {
                if (this.lang == "cz") {
                    return "https://spreadcharts.com/cs/pruvodce-aplikaci"
                }
                return "https://spreadcharts.com/tutorial"
            }
        },
        watch: {
            targetActive(value, old) {
                if (old == "menu-signals") {
                    this.$refs["signals"][0].openSignal(-1)
                }
            },

            // reakce na prihlaseni / odhlaseni
            //user(value) {
            //    if (!this.loggedIn) {
            //        this.loaded = true
            //        return
            //    }
            //    this.loadData()
            //}
        }
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&subset=latin-ext');

    @import url('https://cdn.jsdelivr.net/npm/pretty-checkbox@3.0/dist/pretty-checkbox.min.css');

    body {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    table {
        -webkit-user-select: none;
        -ms-user-select:     none;
        user-select:         none;
    }

    div.load-wrapper {
        height: calc(100vh);
        text-align: center;
    }

    div.load-wrapper > div {
        padding: 20px; 
        position: relative;
        top: calc(100vh / 2 - 50px);
    }

    div.tutorial-wrapper {
        padding: 0px 12px 0px 12px;
        z-index: 10;
        color: #555;
        cursor: pointer;
    }

    div.user-wrapper {
        float: right;
        padding: 0px 10px 0px 10px;
        cursor: pointer;
    }

    div.user-wrapper > span > i {
        font-size: 22px;
        vertical-align: middle;
        color: #555;
        display:inline-block;
        margin-top: -3px;
        margin-left: 10px;
    }
    
    div.user-wrapper > i:hover {
        color: #1976d2;
    }

    div.info-content {
        width: 140px;
        cursor: default;
        text-align: left;
        margin: 0px 0px 0px 0px;
        font-size: 0.7em;
        padding: 10px;
        z-index: 20;
        background-color: #ffffff;
    }

    div.info-content a, div.info-content a:visited {
        display: block;
        margin-top: 3px;
        text-decoration: none;
        color: inherit;
    }

    .info-in {
        opacity: 0.0;
        z-index: 20;
        -webkit-transition: opacity 0.25s;
    }

    .info-out {
        opacity: 1.0;
        z-index: 20;
        -webkit-transition: opacity 1.5s;
    }

    body {
        font-family: 'Open Sans', sans-serif;
        /*font-family: 'Arial';*/
        font-size: 15px;
        padding: 0;
        margin: 0;
    }

    #column-left {
        position: fixed;
        background-color: #ffffff;
        box-shadow: 3px 0px 26px -3px rgba(0,0,0,0.25);
        z-index: 501;
        -webkit-transition: width 0.5s;
        transition: width 0.5s; 
        padding-bottom: 50px;
    }

    #column-right {
        float: right;
        background-color: #fafafa;
        -webkit-transition: width 0.5s;
        transition: width 0.5s; 
        height: calc(100vh - 70px);
    }

    #graph {
        height: calc(100vh - 70px);  
    }

    .col-left-full {
        width: 190px;
    }

    .col-left-small {
        width: 70px;
    }

    .col-right-full {
        width: calc(100% - 70px);
    }

    .col-right-small {
        width: calc(100% - 190px); 
    }

    ul.app-menu-main {
        margin: 5px 0px 0px 13px;
        padding: 0;
    }
    div.logo-box {
        text-align: center;
        margin: 10px 0 35px 0;
        
    }

    img.logo-full {
        width: 80%;
    }

    img.logo-full, img.logo-small{
        height: 28px;
        margin: 5px 0px 5px 0px;
    }

    div.enlarger {
        border: 1px solid red;
        margin: 0 0 20px 13px;
        padding: 0;
        cursor: pointer;
    }

    div.icon-wrapper {
        width: 40px;
        display: inline-block;
        margin: 0 0 25px 0;
        text-align: center;
        cursor: pointer;
        vertical-align: top;
    }

    div.icon-wrapper > i {
        font-size: 26px;
        vertical-align: middle;
    }

    li.app-menu-main-item, div.app-menu-main-item {
        list-style: none;
        color: #555;
        cursor: pointer;
        height: 35px;
        padding: 10px 0px 10px 0px;;
    }

    li.app-menu-main-item > span {
        display: inline-block;
        margin-top: 7px;
    }

    div.tutorial-wrapper > div {
        position: relative;
        width: 150px;
        text-align: left;
        margin-left: -13px;
    }

    div.tutorial-wrapper > div > div {
        display: inline-block;
    }

    div.tutorial-wrapper > div.icon-wrapper {
        padding: 0px;
    }

    div.tutorial-wrapper > div > div.tutorial-text {
        font-size: 17px;
        font-weight: bold;
        color: #555555;
        padding-top: 4px;
    }

    li.menu-active {
        color: #1976d2;
    }

    .text-in {
        display: inline-block;
        overflow: hidden;
        width: 0px;
        -webkit-transition: width 0.5s;
        transition: width 0.5s; 
        font-weight: bold;
    }

    .text-out {
        display: inline-block;
        overflow: hidden;
        width: 120px;
        -webkit-transition: width 0.5s;
        transition: width 0.5s; 
        font-weight: bold;
    }

    div.info-wrapper {
        position: absolute;
        bottom: 0px;
        color: #555;
        width: 40px;
        cursor: pointer;
        text-align: center;
        margin: 0px 0px 25px 13px;
    }

    div.top-bar {
        background-color: #ffffff;
        padding: 15px;
        box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.25);
        z-index: 500;
        position: relative;

    }

    div.top-bar > span.state-holder {
        color: #1976d2;
        font-size: 1.2em;
    }

    div.category-container {
        height: calc(100vh - 56px);
        overflow-y: scroll;
        position: relative;
    }

    div.content-container {
        padding: 20px;
        margin-bottom: 100px;
    }

    /**
      * Globalni definide tlacitek (barvy, velosti, atd.)
      */
    input[type="button"] {
        border-radius: 5px;
        cursor: pointer;
        /*opacity: 0.8; doesn't work with modal */
        font-weight: bold;
        box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.25);
        height: 36px !important;
        margin: 0px 10px 0px 0px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    input[type="button"]:focus {
        outline: none;
    }

    input[type="button"]:hover {
        opacity: 1.0;
    }

    input[type="button"].button-green {
        background-color: #00aa32 ;
        border: 2px solid #00aa32 ;
        color: #ffffff;
    }

    input[type="button"].button-blue {
        background-color: #1976d2 !important;
        border: 2px solid #1976d2 !important;
        color: #ffffff;
    }

    input[type="button"].button-red {
        background-color: #dc143c !important;
        border: 2px solid #dc143c !important;
        color: #ffffff;
    }

    input[type="button"].button-lightred {
        background-color: rgb(255, 112, 67) !important;
        border: 2px solid rgb(255, 112, 67) !important;
        color: #ffffff;
    }

    input[type="button"].button-grey {
        background-color: rgb(153, 153, 153) !important;
        border: 2px solid rgb(153, 153, 153) !important;
        color: #ffffff;
        cursor: default;
    }

    input[type=checkbox]{
        -ms-transform: scale(1.5); /* IE */
        -moz-transform: scale(1.5); /* FF */
        -webkit-transform: scale(1.5); /* Safari and Chrome */
        -o-transform: scale(1.5); /* Opera */
        transform: scale(1.5);
        padding: 10px;
    }

    /*
    input.mx-input {
        height: 36px;
        border: 1px solid #ddd; 
        border-radius: 5px;
    }

    input.mx-input::placeholder {
        color: #bbbbbb;
    }*/

    input[type="text"], 
    /*input.mx-input,*/ 
    input[type="button"],
    div.tree-container {
        font-size: 14px;
        font-family: 'Arial';
    }

    div.dropdown-toggle {
        font-size: 14px;
        font-family: "Arial";
    }


    /*
    .mx-datepicker-popup {
        left: 0;
        right: none !important;
    }*/

    select {
        border-radius: 5px;
        cursor: pointer;
        height: 36px;
        margin-right: 5px;
        background-color: white;
        font-weight: bold;
        color: #444;
    }



    /* Info icon */
    div.info-icon-wrapper {
        position: absolute;
        bottom: 0px;
        color: #555;
        width: 40px;
        text-align: center;
        margin: 0px 0px 25px 13px;
        cursor: pointer;
    }

    div.input-uplabel {
        font-size: 12px;
        margin-left: 5px;  
        margin-bottom: 1px;
        color: #888;
    }

    /* smazani krizku ze selectu */
    button.clear{
        display: none;
    }
    .v-select input[type="search"]{
        font-size: 1em !important;
        line-height: 1.545; 
    }

    input[type="text"].modal-input {
        margin: 0px 5px 5px 5px;
        font-size: 14px;
        height: 32px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 5px;
        border: 1px solid #ddd;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        
    }

    /* Prihlaseni / Registrace */
    div.user-login-warn {
        color: #1976d2;
        cursor: auto;
        font-size: 18px;
    }

    div.user-login-warn > span.user-clicker, 
    div.user-login-warn > span.user-clicker > a, div.user-login-warn > span > a:visited {
        color: red;
        cursor: pointer;
        text-decoration: none;
    }

    div.user-login-warn > span.user-clicker:hover {
        text-decoration: underline;
    }

    @media screen and (max-width: 900px) {
        div.user-login-warn > span.optional {
            display: none
        }
    }


    div.form-msg {
        font-size: 15px;
        margin-bottom: 10px;
        color: #555555;
        width: 250px;
    }

    td.td-label {
        color: #888;
        font-size: 13px;
        padding: 0px;
    }

    td.td-input {
        padding: 0px 0px 10px 0px;
    }

    span.forgoten-clicker {
        color: #555555;
        text-decoration: none;
        margin-left: 2px;
        cursor: pointer;
    }

    td.td-input > a:hover {
        text-decoration: underline;
    }


    td.td-input > input[type="text"],
    td.td-input > input[type="password"],
    td.td-input > textarea {
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 5px 0px 5px 3px;
        font-size: 15px;
        width: 250px;
    }

    td.error > input[type="text"],
    td.error > input[type="password"],
    td.error > textarea {
        border: 1px solid red;
    }

    td.td-error {
        color: red;
        font-size: 13px;
        text-align: right;
        padding: 0px;
    }

    td.td-submit {
        text-align: right;
        padding: 0px;
    }

    td.td-submit > input[type="button"] {
        border: 0px;
        font-size: 15px;
        padding: 5px 10px 5px 10px;
        margin: 0px 0px 0px 0px;
    }

    div.loader-wrapper {
        height: 160px;
        width: 250px;
        padding-top: 100px;
    }

    div.login-content {
        height: 200px;
        width: 260px;
    }

    div.login-content > div.loader {
        margin-top: 90px;
    }
    div.plink_content {
        margin: 20px;
        -webkit-touch-callout: text;
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }

    div.bottomStatusBar {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000000000;
    }

    div.bottomStatusWrapper {
        text-align: center;
    }

    div.bottomStatusContent {
        margin: auto;
        padding: 10px 0 10px 0;
        border: 1px solid #aaa;
        border-bottom: none;
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: rgba(255, 255, 255, 1.0);
        box-shadow: 3px 0px 26px -3px rgba(0,0,0,0.25);
        width: 60%;
        font-weight: 700;
    }
</style>
