// import AmCharts from 'amcharts3';
// import AmStock from 'amstock3/amcharts/amstock';
// import AmSerial from 'amcharts3/amcharts/serial';

import defaultPanelSettings from './commonFunctions.js'
import defaultCategoryAxesSettings from './commonFunctions.js'
import isTouch from './commonFunctions.js'

export default {
    mixins:[defaultPanelSettings, defaultCategoryAxesSettings, isTouch],
    methods: {
        histogram2(payload) {
            var ch =  window.AmCharts.makeChart(payload.targetId,  {
                type: "serial",
                theme: "light",
                addClassNames: true,
                pathToImages: "https://www.amcharts.com/lib/3/images/",
                dataProvider: payload.data["ALL"].data, // defaultne vsechno
                valueAxes: [{
                    gridAlpha: 0.2,
                    axisAlpha: 0.2,
                    position: "right",
                    dashLength: 0,
                    fontSize: 13
                }],
                categoryAxis : {
                    axisAlpha: 0.2,
                    gridAlpha: 0.2,
                    dashLength: 4,
                    equalSpacing: true,
                    labelFunction: function(number, label, axis) {
                        var numberFloat = parseFloat(number).toFixed(payload.misc.round_prec);                
                        label =  AmCharts.formatDataContextValue("[[value]]", {value: numberFloat})
                        return label;
                    },
                    fontSize: this.defaultCategoryAxesSettings(payload.functionId).fontSize
                },
                legend: {
                    position: "top",
                    fontSize: 14,
                    markerBorderThickness: 0,
                    equalWidths: false,
                    spacing: 30,
                    valueWidth: 0,
                    data: [{
                        id: "SD1",
                        title: "25th–75th percentile range",
                        color: "red",
                        alpha: 0.2,
                        hidden: false
                    },{
                        id: "SD2",
                        title: "5th–95th percentile range",
                        color: "green",
                        alpha: 0.2,
                        hidden: false
                    }],
                    combineLegend: true,
                    listeners: [{
                        event: "hideItem",
                        method: this.legendClickHandler
                    }, {
                        event: "showItem",
                        method: this.legendClickHandler
                    }]
                },
                allLabels: function(payload) {
                    var y = 20
                    var labels = []

                    // pocet pouzitych let
                    labels.push({
                        text: payload.data["ALL"].labels[0],
                        bold: true,
                        size: 15,
                        x: 40, y: y,
                        alpha: 0.7,
                    })

                    // odsazeni podle pritomnosti statistiky v labelu
                    if (/\n/.test(payload.data["ALL"].labels[0])) {
                        y += 34
                    } else {
                        y += 17
                    }
                    
                    if (payload.misc.offset < 0) {
                        labels.push({
                            text: "Contract window shifted "+parseInt(-1*payload.misc.offset)+" calendar days backward",
                            bold: true,
                            size: 15,
                            x: 40, y: y,
                            alpha: 0.7
                        })
                        y += 20
                    } else if (payload.misc.offset > 0) {
                        labels.push({
                            text: "Contract window shifted "+parseInt(payload.misc.offset)+" calendar days forward",
                            bold: true,
                            size: 15,
                            x: 40, y: y,
                            alpha: 0.7
                        })
                        y += 20
                    }

                    return labels
                }(payload),
                graphs: [{
                    id: "histogram",
                    fillAlphas: 0.7,
                    lineAlpha: 0.7,
                    type: "column",
                    valueField: "b_count",
                    lineColor: "#004471",
                    title: payload.misc.title,
                    balloonFunction: function(item, graph) {
                        var balloonText = "" + parseFloat(item.category).toFixed(payload.misc.round_prec) + ": <b>" + item.values.value + "</b>" ;
                        if (item.category == payload.data[graph.chart.zkey].data[payload.data[graph.chart.zkey].last.b_center_index].b_center) {
                            balloonText += " (Last value)"
                        }
                        return balloonText
                        
                    }
                }],
                chartCursor: {
                    categoryBalloonEnabled: false,
                    valueLineEnabled: true,
                    valueLineBalloonEnabled: true,
                    zoomable: false
                },
                chartCursorSettings: {
                    pan: this.isTouch()
                },
                categoryField: "b_center",
                guides: function(){
                    var gu = []
                    for (var key in payload.data) {
                        // last guide
                        gu.push({
                            id: "last_" + key,
                            category: payload.data[key].data[payload.data[key].last.b_center_index].b_center, 
                            lineColor: "#004471",
                            lineThickness: 2,
                            lineAlpha: (key == "ALL") ? 0.8 : 0.0,
                            above: true,
                            dashLength: 0,
                            baloonText: "bla"
                        })

                        // SD1
                        gu.push({
                            id: "SD1_"+key,
                            category: payload.data[key].data[payload.data[key].SD1.b_center_indexes[0]].b_center, 
                            toCategory: payload.data[key].data[payload.data[key].SD1.b_center_indexes[1]].b_center, 
                            lineColor: "red",
                            lineThickness: 0,
                            lineAlpha: 0.0,
                            fillAlpha: 0.2,
                            fillColor: "red",
                            above: false,
                            dashLength: 0,
                            inside: true
                        })

                        // SD2
                        gu.push({
                            id: "SD2_"+key,
                            category: payload.data[key].data[payload.data[key].SD2.b_center_indexes[0]].b_center, 
                            toCategory: payload.data[key].data[payload.data[key].SD2.b_center_indexes[1]].b_center, 
                            lineColor: "green",
                            lineThickness: 0,
                            lineAlpha: 0.0,
                            fillAlpha: 0.2,
                            fillColor: "green",
                            above: false,
                            dashLength: 0,
                            inside: true
                        })

                    }

                    return gu
                }(),
                autoMargins: false,
                marginLeft : this.defaultPanelSettings(payload.functionId).marginLeft,
                marginBottom : this.defaultPanelSettings(payload.functionId).marginBottom,
                marginRight : this.defaultPanelSettings(payload.functionId).marginRight,
                fontFamily: this.defaultPanelSettings(payload.functionId).fontFamily,
            });

            // vlastni zavolatelne funkce
            ch.setZoom = this.setZoom // 'zoom' ale vlastne zmena providera + prekresleni

            ch.validateSDGuides = this.validateSDGuides
            ch.validateLastGuides = this.validateLastGuides
            ch.validateLabels = this.validateLabels

            ch.zkey = "ALL"

            // puvodni data
            ch.data = payload.data

            return ch
        },
        setZoom(chart, zoom) {
            if (chart.data[zoom.name] === undefined) {return}

            var zn = zoom.name

            // ulozit zoom key
            chart.zkey = zn

            // zmenit dataset
            chart.dataProvider = chart.data[zn].data

            // viditelnost guides (last, dev)
            for (var i in chart.guides) {
                if (chart.guides[i].id == "last_"+zn) {
                    chart.guides[i].lineAlpha = 0.8
                } else {
                    chart.guides[i].lineAlpha = 0.0
                }
            }

            // prekresleni guides
            chart.validateSDGuides(chart)
            chart.validateLastGuides(chart)
            chart.validateLabels(chart)

            chart.validateData()
        },

        validateSDGuides(chart) {
            for (var j in chart.guides) {
                chart.guides[j].fillAlpha = 0.0
            }

            for (var i in chart.legend.data) {
                var d = chart.legend.data[i]
                for (var j in chart.guides) {
                    var g = chart.guides[j]

                    var tmp = g.id.split("_")
                    if (d.id == tmp[0] && chart.zkey == tmp[1] && !d.hidden) {
                        g.fillAlpha = 0.2
                    }
                }
            }
        },

        validateLastGuides(chart) {
            for (var i in chart.guides) {
                var tmp = chart.guides[i].id.split("_")

                if (tmp[0] != "last") {continue}

                chart.guides[i].lineAlpha = (!chart.graphs[0].hidden && tmp[1] == chart.zkey) ? 0.8 : 0.0
            }
        },

        validateLabels(chart) {
            chart.allLabels[0].text = chart.data[chart.zkey].labels[0]
        },

        legendClickHandler(e) {
            if (e.dataItem.id == "histogram") { // reakce last guides 
                // prekresleni guides
                e.chart.validateLastGuides(e.chart)
            } else { // reakce SD guides
                // stav v legende
                e.dataItem.hidden = !e.dataItem.hidden

                // prekresleni guides 
                e.chart.validateSDGuides(e.chart)
            }

            e.chart.validateData()
        },
    }
}
