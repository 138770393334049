<template>
    <div class="slider-container">
        <vue-slider 
            v-model="value"
            :piecewise=true
            :height=8
            :reverse=false
            :data="rangeData"
            :tooltip=false
            :dot-size=20
            ref="slider"
            @callback="changed"
            @drag-end="ended"
        />
    </div>
</template>

<script>
    import vueSlider from 'vue-slider-component';

    export default {
        name: 'Slider2',
        components: {vueSlider},
        props: {
            targetId: {
                type: String,
                required: true
            },
            dateStart: {
                type: Date,
                required: true
            }
        },
        data() {
            return {
                value: 0
            }
        },
        methods: {
            /**
              * 
              */
            changed() {
                this.$emit("changed", this.value, this.targetId)
            },

            ended() {
                this.$emit("ended", this.value, this.targetId)
            },

            refresh() {
                this.$refs.slider.refresh()
            },

            setValue(value) {
                this.value = value
            },

            getValue() {
                return this.$refs.slider.getValue()
            }
        },
        computed: {
            rangeData() {
                var tmp = []
                for (var i=31; i >= 0; i--) {
                    tmp.push(i)
                }
                return tmp
            },
            date() {
                var d = new Date(this.dateStart)
                d.setDate(d.getDate()-this.value)
                return d
            }
        }
    }
</script>

<style scoped>
    div.slider-container {
        border-bottom: 2px solid #eee;
        background-color: #ffffff;
        z-index: -10;
        cursor: pointer;
    }
</style>
