<template>
        <div class="modal-backdrop" @click="close" v-show="show_">
            <div 
                class="modal" 
                :style="{'top': top+'px', 'left': left+'px', 'height': height+'px'}"
                @click.stop
            >
                <div class="modal-close-wrapper">
                    <div class="modal-name">
                        <slot name="header"></slot>
                    </div>

                    <div class="modal-close-btn">
                        <i class="icons8-delete-2" @click="close"></i>
                    </div>

                    <div style="clear: both"></div>
                </div>


                <div class="modal-body" ref="modal-body">
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: 'modal3',
        components: {},
        props: {
            show: {
                type: Boolean,
                required: true
            },
            ratio: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                show_: false,
                height: 10000,
                top: 0,
                left: 0,
            }
        },
        methods: { 
            close() {
                this._show = false
                this.$emit('close');
            },

            resize() {
                if (!this.show_) {return}

                var w = this.$refs["modal-body"].clientWidth
                var h = this.$refs["modal-body"].clientHeight
                var el = document.documentElement

                //this.width = (w < window.innerWidth * 0.8) ? w + 4 : window.innerWidth * 0.8;
                this.height = (h < el.clientHeight * 0.8) ? h + (40 + 50) : el.clientHeight * 0.8;

                this.top = (el.clientHeight - this.height) / 2
                this.left = (el.clientWidth - w - 50) / 2
            }
        },

        watch: {
            show(value) {
                this.show_ = value
                var self = this
                setTimeout(function() {
                    self.resize()
                }, 25) 
            }
        },

        computed: {
        },

        mounted: function() {
            document.addEventListener("keydown", (e) => {
                if (e.keyCode == 27 && this.show) {
                    this.close()
                }
            });         

            var self = this
            window.addEventListener("resize", (e) => {
                setTimeout(function() {
                    self.resize()
                }, 250)
            });
        }
    }
</script>

<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.75);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100000;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: visible;
        overflow-y: visible;
        position: absolute;
        display: inline-block;

        margin: 0;
        padding: 0;
    }

    .modal-body {
        position: relative;
        display: inline-block;
        margin: 25px;
        padding: 0;
    }

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }
    
    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .25s ease;
    }

    .modal-close-wrapper {
        border-bottom: 1px solid #EEE;
        background-color: #d9f2f9; 
    }

    .modal-name {
        display: inline-block;
        float: left;
        margin: 10px;
        font-size: 15px;
        font-weight: bold;
        color: #555555;
    }

    .modal-close-btn {
        cursor: pointer;
        display: inline-block;
        opacity: 0.8;
        margin: 10px;
        float: right;
    }
</style>
